.dashboardDetailModal{
  position: fixed;
  top: 0;
  left: 0;
  z-index: 55;
  width: 100%;
  height: 100%;
  &__bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-color: rgba(0, 0, 0, 0.4);
  }
  &__content{
    position: absolute;
    margin: auto;
    overflow: hidden;
    width: 550px;
    height: fit-content;
    max-height: 660px;
    padding: 24px 32px 32px 32px;
    border-radius: 16px;
    background: #FFFF;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 999;
  }
  &__items{
    max-height: 450px;
    overflow: scroll;
    display: flex;
    flex-direction: column;
    gap:12px;
    border-radius: 8px;
    &__item{
      width: 100%;
      height: auto;
      padding: 16px ;
      display: flex;
      flex-direction: column;
      gap: 12px;
      border-radius: 8px;
      justify-content: space-between;
      background: #2A36500F;
      letter-spacing: 0.2px;
      h4{
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        color: #2A3650;
      }
      div {
        display: flex;
        justify-content: space-between;
      }
      span {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
      }
      button{
        pointer-events: all;
        display: flex;
        padding: 6px 12px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 5px;
        background: #FFF;
      }
    }
  }
}