.custom-modal {
  position: fixed;
  left: 0px;
  width: 100%;
  height: 100vh;
  z-index: 999;
  display: flex;
  justify-content: center;
  top: 100px;
  &__content {
    max-width: 550px;
    width: 100%;
    background-color: #fff;
    height: max-content;
    padding: 24px 32px;
    border-radius: 16px;
  }
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__title {
    color: var(--dark-100, #2a3650);
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 25.2px */
    letter-spacing: 0.2px;
  }
  &__hr {
    width: 100%;
    height: 1px;
    background: #ccdcf5;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  &__p {
    color: var(--dark-100, #2a3650);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
  }
  &__btns {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
  }
  &__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
    width: 45%;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 21px */
    letter-spacing: 0.2px;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s;
    &:hover {
      transform: translateY(-3px);
    }
    &:active {
      transform: translateY(0px);
    }
    &.close {
      color: var(--text-subtle, #767676);
      background: var(--background, #eee);
    }
    &.delete {
      background: #fee6e6;
      color: #fd6a6a;
    }
    &.fullWidth{
      width: 100%;
      color: #3375D6;
      background: #CCDCF580;
    }
    &.disabled{
      background: #4e5461;
      color: white;
    }
  }
  &__bg {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100vh;
    z-index: -1;
    background-color: rgba(0, 0, 0, 0.6);
  }
}
