@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;600;700;800&display=swap");

* {
  font-family: "Rubik", sans-serif !important;

  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background-color: #EAE8E8;
    border-radius: 16px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #EAE8E8;
    border-radius: 16px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #C1C1C1 !important;
    border-radius: 16px !important;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #bdbbbb;
  }

}
.react-datepicker-wrapper {
  width: 100%;
}
ol, {
  list-style: decimal;
}
ul {
  list-style: disc;
}

.c-border {
  border: 2px solid black;
  border-radius: 3px;
  padding: 10px;
}

.main {
  position: relative;
  //overflow: hidden;

}

.bg-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: -1;
}

input {
  outline: none !important;
  &:focus {
    outline: none !important;
  }
}

.loader__wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.loader {
  border: 10px solid #f3f3f3;
  border-radius: 50%;
  border-top: 10px solid #0052cc;
  width: 60px;
  height: 60px;
  -webkit-animation: spin 1s linear infinite; /* Safari */
  animation: spin 1s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

textarea {
  resize: none;
}

input {
  &::-webkit-calendar-picker-indicator {
    filter: invert(1);
  }
}

.react-datepicker__input-container {
  input {
    width: 100% !important;
    padding: 6px;
    border-radius: 4px;
    border-color: gray !important;
    background-color: rgba(75, 85, 99, 0.6);
    color: white;
  }
}

.auth {
  &__wrapper {
    min-height: 100vh;
    width: 100%;
    background-image: url("../../public/assets/images/auth__bg.svg");
    padding: 60px;
  }
  &__c {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 200px);
    width: 100%;
  }
  &__content {
    max-width: 520px;
    width: 100%;
    margin: 0 auto;
    background: #ffffff;
    border-radius: 16px;
    padding: 32px;
  }
  &__valid {
    display: flex;
    align-items: center;
    color: #0caf60;
    img {
      margin-right: 5px;
    }
  }
  &__invalid {
    display: flex;
    align-items: center;
    color: #8a909d;
    img {
      margin-right: 5px;
    }
  }
  &__form {
    &-minimum {
      font-weight: 400;
      font-size: 12px;
      line-height: 160%;
      letter-spacing: 0.4px;
      color: #a0aec0;
      margin-top: 8px;
    }
    &-codeField {
      width: 100% !important;
      .styles_react-code-input__CRulA {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 16px;
      }

      input {
        border: 1px solid #908e8e !important;
        box-sizing: border-box;
        border-radius: 8px !important;
        transition: all 0.3s;
        position: relative;

        &:focus {
          border: 1px solid #0052cc !important;
        }

        &:after {
          content: "";
          position: absolute;
          top: 50%;
          right: -5%;
          transform: translateY(-50%);
          width: 16px;
          height: 1px;
          color: #111827;
        }
      }
    }
    &-sendAgain {
      margin-top: 12px;
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
      color: #0066ff;
      cursor: pointer;
    }
  }
  &__title {
    font-weight: 700;
    font-size: 32px;
    line-height: 125%;
    text-align: center;
    color: #2a3650;
    &.verification {
      color: #4e5461;
      text-align: left;
    }
  }
  &__descr {
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    text-align: center;
    color: #2a3650;
    margin-top: 12px;

    &-verification {
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
      color: #718096;
    }
  }
}

.d-f {
  display: flex;
}

.sidebar {
  width: 64px;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  padding-left: 7px;
  justify-content: space-between;
  padding-top: 20px;
  padding-bottom: 20px;
  background: #fafafb;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  z-index: 40;
  transition: all 0.1s;
  .bottom {
    position: absolute;
    bottom: 0;
    padding-bottom: 20px;
    background: #fafafb;
  }
  &__languageContainer{
    margin-top: 36px;
  }
  &__activeLanguageContainer {
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    border: 1px solid #E5E5E5;
    img {
      width: 24px;
      height: 24px;
      border-radius: 50%;
    }
  }

  &__languageSelectContainer {
    display: none;
    height: 48px;
    margin: 0 24px 0 12px;
    align-items: center;
    position: relative;
    justify-content: space-between;

    &__header {
      width: 100%;
      display: flex;
      gap: 12px;
      align-items: center;
      padding: 12px;
      border-radius: 8px;
      border: 1px solid #E5E5E5;
      & img {
        width: 24px;
        height: 24px;
      }
      & h2 {
        font-size: 14px;
        font-weight: 600;
        line-height: 21px;
        letter-spacing: 0.24px;
        white-space: nowrap;
        text-align: left;
        box-sizing: border-box;
        text-wrap: nowrap;
        color: #8A909D;

      }
      &_active {
        border: 1px solid #2A3650
      }
    }

    &__selectBtn {
      width: 24px;
      height: 24px;
      border: none;
      background: none;
      transition: width 1s, height 1s, transform 1s;
      margin-left: auto;
    }

    &__modal {
      padding: 8px;
      border-radius: 12px;
      border: 1px solid #D4DAE7;
      box-shadow: 5px 5px 32px 0 #1419221A;
      max-height: 200px;
      width: 100%;
      overflow: hidden;
      position: absolute;
      display: none;
      gap: 12px;
      flex-direction: column;
      left: 0;
      right: 0;
      top: 60px;
      z-index: 1;
      background: #FFFFFF;

      &_visable {
        display: flex;

      }
    }

    &__languages {
      pointer-events: all;
      overflow-y: auto;
      scrollbar-width: none;
      &__item {
        pointer-events: all;
        display: flex;
        padding: 12px;
        gap: 12px;
        border-radius: 12px;
        scrollbar-width: none;
        cursor: pointer;

        & img {
          width: 24px;
          height: 24px;
          border-radius: 50%;
        }
        span {
          color: #2A3650;
          font-size: 14px;
          font-weight: 500;
          line-height: 21px;
          letter-spacing: 0.24px;
        }
        &:hover {
          background: #F4F4F4;
        }
      }
      &_bg {
        //background: #F4F4F4;
        //background: #dcf3f8;
      }
    }
  }

  &:hover {
    width: 260px;
    .sidebar__languageSelectContainer {
      display: flex;
    }
    .sidebar__activeLanguageContainer {
      display: none;
    }
    .sidebar__logout {
      width: 100%;
    }
    .sidebar__user {
      width: 200px;
    }
    .sidebar__item {
      span {
        display: block;
        opacity: 1;
      }
    }
    .t {
      display: block;
      opacity: 1;
    }
    .sidebar__user {
      .name {
        display: block;
        opacity: 1;
      }
    }
    .sidebar__help {
      .title {
        display: block;
        opacity: 1;
      }
    }
  }
  &__logo {
    max-width: 50px;
    width: 100%;
    cursor: pointer;
  }
  &__item {
    display: flex;
    width: 100%;
    height: 56px;
    position: relative;
    text-transform: capitalize;

    &.sf {
      a {
        &.active {
          svg {
            path {
              fill: #0052cc;
            }
          }
        }
      }
    }
    svg {
      path {
        stroke: #8a909d;
      }
    }

    a {
      display: flex;
      align-items: center;
      padding-left: 12px;
      gap: 5px;
      span {
        display: none;
        opacity: 0;
        padding-left: 5px;
        width: 150px;
        transition: all 0.5s;
        font-weight: 500;
        font-size: 16px;
        line-height: 150%;
        letter-spacing: 0.2px;
        color: #8a909d;
      }
      &.active {
        span {
          color: #0052cc;
        }
        svg {
          path {
            stroke: #0052cc;
          }
        }

        &::before {
          content: "";
          position: absolute;
          top: 11px;
          left: -7px;
          width: 6px;
          height: 32px;
          background: #0052cc;
          border-radius: 0 100px 100px 0;
        }
      }
    }
  }
  &__help {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .title {
      display: none;
      padding-left: 5px;
      width: 150px;
      transition: all 0.3s;
      font-weight: 500;
      font-size: 16px;
      line-height: 150%;
      letter-spacing: 0.2px;
      color: #8a909d;
      cursor: pointer;
    }
  }
  &__user {
    padding-left: 6px!important;
    .user__logo {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      background: #3375d6;
      border-radius: 40px;
      font-weight: 500;
      font-size: 15px;
      line-height: 150%;
      letter-spacing: 0.25px;
      color: #ffffff;
      cursor: pointer;
    }
    .name {
      display: none;
      padding-left: 5px;
      width: 150px;
      transition: all 0.3s;
      font-weight: 500;
      font-size: 16px;
      line-height: 150%;
      letter-spacing: 0.2px;
      color: #8a909d;
      cursor: pointer;
    }
  }

  &__logout {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 10px;
    padding-left: 12px;

    .t {
      display: none;
      padding-left: 5px;
      width: 150px;
      transition: all 0.3s;
      font-weight: 500;
      font-size: 16px;
      line-height: 150%;
      letter-spacing: 0.2px;
      color: #8a909d;
      cursor: pointer;
    }
    .logout__modal {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 55;
      display: flex;
      align-items: center;
      justify-content: center;

      &-content {
        background: #ffffff;
        border-radius: 16px;
        max-width: 550px;
        width: 100%;
        padding: 24px 32px;

        h5 {
          font-weight: 500;
          font-size: 18px;
          line-height: 140%;
          letter-spacing: 0.2px;
          color: #2a3650;
        }
      }
      &-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #ccdcf5;
        padding-bottom: 20px;
      }
      &-close {
        cursor: pointer;
      }
      &-text {
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: #2a3650;
        margin-top: 20px;
      }

      &-btns {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 32px;
      }
      &-cancel,
      &-sure {
        font-weight: 500;
        font-size: 14px;
        line-height: 150%;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        letter-spacing: 0.2px;
        color: #767676;
        max-width: 229px;
        width: 100%;
        padding-top: 11.5px;
        padding-bottom: 11.5px;
        background: #eeeeee;
        border-radius: 8px;
        cursor: pointer;
      }

      &-sure {
        color: #fd6a6a;
        background: #fee6e6;
      }

      &-bg {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        background-color: rgba(0, 0, 0, 0.4);
      }
    }
  }

  &__content {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 36px;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

@media (max-width: 960px) {
  .auth__wrapper {
    padding: 40px;
  }
}

@media (max-width: 758px) {
  .auth__wrapper {
    padding: 20px;
  }
}

.react-datepicker__navigation-icon--next,
.react-datepicker__navigation-icon--previous {
  display: block !important;
}

.page404 {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  img {
    width: 100%;
    height: 100%;
  }

  h1 {
    font-size: 102px;
  }
  h2 {
    font-size: 30px;
  }
}
.react-datepicker__month--disabled,
.react-datepicker__quarter--disabled {
  color: #ccc !important;
  pointer-events: none !important;
}

.larp__modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  &-content {
    max-width: 550px;
    width: 100%;
    min-height: 300px;
    border-radius: 16px;
    background: #fff;
    z-index: 10;
    padding: 24px 32px 32px 32px;
  }
  &-bg {
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }
  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #e5eefa;
  }
  &-title {
    color: #2a3650;
    font-size: 18px;
    font-weight: 500;
    line-height: 140%;
    letter-spacing: 0.2px;
  }
  &-descr {
    color: #767676;
    font-size: 14px;
    line-height: 20px;
    border-radius: 4px;
    background: #f3f3f5;
    padding: 10px;
    margin-bottom: 24px;
    display: flex;
    grid-gap: 8px;
    span {
      width: 18px;
      height: 18px;
      display: inline-block;
      img {
        width: 18px;
        height: 18px;
      }
    }
  }
  &-p {
    color: #2a3650;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 12px;
  }
  &-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 16px;
    border-radius: 4px;
    background: rgba(42, 54, 80, 0.06);
    color: var(--dark-100, #2a3650);
    font-size: 16px;
    font-weight: 500;
    line-height: 150%;
    letter-spacing: 0.2px;
    margin-bottom: 8px;
  }
  &-btns {
    display: flex;
    align-items: center;
    justify-content: space-between;
    grid-gap: 28px;
    margin-top: 32px;
  }
  &-btn {
    width: 50%;
    border-radius: 8px;
    background: #0052cc;
    padding-top: 8px;
    padding-bottom: 8px;
    color: #fff;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    line-height: 150%;
    letter-spacing: 0.2px;
    &_cancel {
      color: #767676;
      background: #eee;
    }
  }
}


.table__header {
  display: grid;
  align-items: flex-start;
  padding: 16px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  gap: 5px;
  .project-table__th {
    width: auto !important;
  }
}

.table__row {
  display: grid;
  align-items: flex-start;
  padding: 16px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  gap: 5px;
  position: relative;
  transition: all 0.1s;
  .payment_description span {
    width: auto !important;
  }
  .project__block-btns {
    z-index: 833;
  }
  .project__larp-btns {
    top: 10px;
  }

  .project-table__td {
    width: auto !important;
  }

  &.edit {
    // filter: drop-shadow(0px 4px 14px rgba(0, 0, 0, 0.15));
    box-shadow: -6px -3px 70px -30px rgba(0, 0, 0, 0.54);
    -webkit-box-shadow: -6px -3px 70px -30px rgba(0, 0, 0, 0.54);
    -moz-box-shadow: -6px -3px 70px -30px rgba(0, 0, 0, 0.54);
    filter: drop-shadow(0px 4px 14px rgba(0, 0, 0, 0.15));
    transform: scale(1.01);
    z-index: 55;
    .project__block-btns {
      display: flex;
    }
    .project-table__td {
      height: 100%;
      span {
        display: none;
      }
    }
    .project-table__field {
      display: block;
    }
    .project__actualDate-disabled {
      input {
        background: #f8f8f8 !important;
        color: #767676 !important;
      }
    }
  }

  &.n {
    box-shadow: none !important;
    transform: none !important;
    z-index: 1 !important;
    filter: none !important;
    .project__block-btns {
      display: none !important;
      &.s {
        display: flex !important;
      }
    }
  }
  &:hover {
    // filter: drop-shadow(0px 4px 14px rgba(0, 0, 0, 0.15));
    box-shadow: -6px -3px 70px -30px rgba(0, 0, 0, 0.54);
    -webkit-box-shadow: -6px -3px 70px -30px rgba(0, 0, 0, 0.54);
    -moz-box-shadow: -6px -3px 70px -30px rgba(0, 0, 0, 0.54);
    filter: drop-shadow(0px 4px 14px rgba(0, 0, 0, 0.15));
    transform: scale(1.01);
    z-index: 55;
    .project__block-btns {
      display: flex;
    }
  }

  &.submitted {
    background-color: #f8f8f8;
    .switch input:checked + label {
      background: grey;
      border-color: grey;
    }
    &:hover {
      box-shadow: unset;
      -webkit-box-shadow: inherit;
      -moz-box-shadow: inherit;
      filter: unset;
      transform: unset;
      z-index: unset;
      .project__block-btns {
        display: none;
      }
    }
  }
}

.fade-in {
  animation: fadeIn .5s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.table {
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
  padding-bottom: 40px;
  &-body{
    display: flex;
    flex-direction: column;
    justify-content: start;
  }
  &-header {
    display: flex;
    align-items: center;
    background: #FFFFFF;
    padding: 12.5px 8px;
    min-width: 1000px;
  }
  &-th {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: #8A909D;
    width: 100%;

    &.position {
      width: 70%;
    }
  }
  &-row {
    display: flex;
    align-items: center;
    background: #FFFFFF;
    border-top: 1px solid #EEEEEE;
    padding: 14.5px 8px;
    position: relative;
    transition: all 0.3s;
    min-width: 1000px;
    &:nth-child(2n) {
      background-color: #F8F8F8;
    }
    &:hover {
      z-index: 20;
      background: #E5EEFA;
      filter: drop-shadow(0px 4px 14px rgba(42, 54, 80, 0.14));

    }
    &:hover .table-actions {
      opacity: 1;
      z-index: 33;
      filter: drop-shadow(0px 4px 14px rgba(0, 0, 0, 0.15));
    }
  }
  &-actions {
    display: flex;
    align-items: center;
    position: absolute;
    top: 95%;
    right: 15px;
    width: 170px;
    transition: all 0.3s;
    height: 26px;
    opacity: 0;
    z-index: -22;
  }
  &-edit,
  &-delete {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 8px;
    width: 89px;
    border-width: 0 1px 1px 1px;
    border-style: solid;
    border-radius: 0 0 8px 8px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.2px;
    cursor: pointer;
    padding: 3px;

    img {
      width: 16px;
      margin-right: 3px;
      height: 16px;
    }
  }

  &-delete {
    background: #FFF2F2;
    border-color: #EEBDBD;
    color: #FD6A6A;
    margin-right: 4px;
  }
  &-edit {
    border-color: #B5CCEF;
    background: #F1F7FF;
    color: #3375D6;
  }
  &-td {
    font-size: 14px;
    line-height: 150%;
    letter-spacing: 0.2px;
    color: #2A3650;
    width: 100%;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    div {
      &.deactivated-true {
        background: rgba(12, 175, 96, 0.1);
        border-radius: 5px;
        color: #0CAF60;
        padding: 8px 0 8px 18px;
        cursor: pointer;
        width: 50%;
      }
      &.deactivated {
        background: rgba(253, 106, 106, 0.1);
        border-radius: 5px;
        color: #FD6A6A;
        cursor: pointer;
        padding: 8px 0 8px 18px;
        width: 50%;
      }
    }
    &:first-child {
      font-weight: 500;
    }

    &.position {
      width: 70%;
    }
  }
}

.dpFlexRow{
  display: flex;
   span{
     font-weight: 400;
     font-size: 14px;
     line-height: 20px;
     letter-spacing: 0.2px;
     color: #8A909D;
   }
}

.createButton {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #0052CC;
  padding: 8px 16px 8px 16px;
  gap: 4px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0.24px;
  color: #FFFFFF;

}

@media only screen and (min-height: 940px) {
  .sidebar__content{
    min-height: 60vh;
    overflow-y: auto;
    height: 55vh;
    &__higherItem {
      padding-bottom: 30px;
      .sidebar__item {
        min-height: 66px !important;
      }
    }
  }
  .sidebar__item{
    min-height: 56px;
  }

}
@media only screen and (max-height: 860px) {
  .sidebar__content{
    height: 50vh;
    overflow-y: auto;
    padding-bottom: 36px;
    margin-top: 25px;
  }
  .sidebar__item, .sidebar__logout {
    min-height: 56px !important;
    height: 50px;
  }
}