.document_wrapper{
    display: flex;
    flex-wrap: wrap;
}

.document_wrapper-item{
    cursor: pointer;
    margin: 20px 20px 0 0;
    border-radius: 10px;
    width: 30%;
    border: 1px solid #E5E5E5;
    position: relative;

    &__content{
        height: 200px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    &__title{
        border-top: 1px solid #E5E5E5;
        min-height: 85px;
        display: flex;
        justify-content: center;
        align-items: center;

        font-family: Rubik, serif;
        font-size: 18px;
        font-weight: 700;
        line-height: 25px;
        text-align: left;
        overflow-wrap: anywhere;
        padding: 15px;
    }

    &__imageWrap{
        width: 106px;
        height: 106px;

        img{
            width: 100%;
            height: 100%;
        }
    }

    &__buttons_wrap{
        width: 95%;
        position: absolute;
        display: flex;
        justify-content: end;

        .update{
            color: #3375D6;
            background: #F1F7FF;
            border: 1px solid #B5CCEF;
            margin-right: 5px;

            img{
                margin-right: 5px;
            }
        }

        .delete{
            color: #FD6A6A;
            background: #FFF2F2;
            border: 1px solid #EEBDBD;

            img{
                margin-right: 5px;
            }
        }

        div{
            display: flex;
            font-family: Rubik serif;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            padding: 5px;
            border-radius: 0 0 10px 10px;
            background: #3E77FF;
            box-shadow: 0 4px 14px 0 #0000001F;
            z-index: 10;
        }
    }

    &__uploadBtn{
        position: absolute;
        top: 20px;
        right: 20px;
    }
}

.update_modal{
    &__content{
        margin-top: 20px;
        height: 200px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: #F9F9FB;
        border-radius: 6px;

        input {
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0;
            z-index: -1;
        }
        label {
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            width: 100%;
            padding-top: 48px;
            padding-bottom: 48px;
            span {
                display: block;
                color: var(--primary-100);
                text-align: center;
                font-family: Rubik serif;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 150%;
                letter-spacing: 0.2px;
                margin-top: 16px;
                border-bottom: 1px solid black;

                &:last-child {
                    border: none;
                    color: var(--grey-100, #8d8d9d);
                    margin-top: 0;
                }
            }
        }
    }

    &__input_label{
        margin: 20px 0 10px 0;
        font-family: Rubik serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0;
        text-align: left;
    }

    &__input{
        border-radius: 8px;
        padding: 10px;
        width: 100%;
        border: 1px solid #0000001A
    }

    &__file {
        border-radius: 8px;
        background: #f9f9fb;
        padding: 12px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 20px;
        div {
            display: flex;
            align-items: center;
            div {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                span {
                    &:first-child {
                        color: var(--dark-100, #23262f);
                        font-family: Rubik;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 150%; /* 21px */
                        letter-spacing: 0.2px;
                    }
                    &:last-child {
                        color: var(--grey-100, #8d8d9d);
                        font-family: Rubik serif;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 20px;
                        letter-spacing: 0.2px;
                    }
                }
            }

            span {
                display: flex;
                flex-direction: row;
                margin-right: 16px;
            }
        }
    }

    &__file_name {
        width: 60%;
        overflow: hidden;
    }

}

.hovered{
    box-shadow: 0 4px 14px 0 #0000001F;
}