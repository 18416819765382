.elementStatistics{
  width: 24%;
  min-width: 24%;
  max-width: 450px;
  min-height: 436px;
  display: flex;
  gap: 20px;
  padding: 20px 0;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  border: 2px solid #EAEAEB;
  justify-content: flex-start;
  background: #FFF;
  &__header{
    width: 100%;
    min-height: 48px;
    padding: 0 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    text-transform: capitalize;
    gap: 20px;
    color: #2A3650;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    &__donwLoadBtn{
      min-width: 48px;
      width: 48px;
      height: 48px;
      padding: 4px 12px 4px 12px;
      gap: 8px;
      border-radius: 8px;
      .downloadBtn__tooltip {
        width: max-content;
        bottom: -34px;
        background: #2A3650;
        left: -90%;
        &:before {
          border-color: #2A3650 transparent transparent;
          transform: rotate(180deg);
          top: -10px;
          left: 46%;
        }
      }
    }
  }
  &__pieChartContainer{
    width: 100%;
    height: 228px;
    background: #F4F5F6;
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .recharts-tooltip-wrapper{
      z-index: 1;
    }
  }
  &__data{
    padding: 0 24px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 16px;
    &__item{
      display: flex;
      align-items: center;
      gap: 8px;
    }
    &__colorSpan{
      width: 14px;
      height: 14px;
      border-radius: 4px;
    }
  }


}
.itemDetails{
  display: flex;
  padding: 4px 8px;
  align-items: flex-start;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.2px;
  border-radius:  0 8px 8px 8px;
  background: var(--Dark-100, #2A3650);
  color: #FFF;
  &__triangle{
    position: absolute;
    top: -8px;
    left: -6px;
    border-top: 8px solid transparent;
    border-right: 16px solid #2A3650;
    border-bottom: 8px solid transparent;
    transform: rotate(45deg) ;
    margin-right: 5px;
    background: none;
    z-index: -1;
  }
}

@media screen and (max-width: 1440px) {
  .elementStatistics {
    width: 380px
  }
}