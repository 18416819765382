.s-page {
    padding-left: 150px;
    padding-bottom: 50px;
    &__sidebar {
        position: fixed;
        top: 0;
        left: 62px;
        width: 162px;
        height: 100vh;
        z-index: 22;
        background: #FAFAFB;
        border: 1px solid #EEEEEE;
        filter: drop-shadow(0px 1px 0px rgba(0, 0, 0, 0.1));
        padding-top: 20px;

        &-item {
            display: block;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.2px;
            color: #8A909D;
            padding: 8px 20px;
            box-sizing: border-box;
            cursor: pointer;
            border: 1px solid #EEEEEE;
            &:first-child {
                border-top: none;
            }
            &:last-child {
                border-bottom: none;
            }

            &.active {
                font-weight: 500;
                font-size: 14px;
                line-height: 150%;
                letter-spacing: 0.2px;
                color: #0052CC;
            }
        }
    }
    &__generateBtns {
        padding-top: 20px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        button {
            margin-left: 15px;
        }
    }

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 28.5px;
        h1 {
            font-weight: 700;
            font-size: 24px;
            line-height: 130%;
            color: #2A3650;
        }

        button {
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 500;
            font-size: 14px;
            line-height: 150%;
            letter-spacing: 0.2px;
            color: #FFFFFF;
            background: #0052CC;
            border-radius: 8px;
            padding: 8px 16px;
            transition: all 0.3s;

            img {
                margin-right: 6.5px;
                margin-top: -2px;
            }
            &:hover {
                transform: translateY(-3px);
            }
            &:active {
                transform: translateY(0px);
            }
        }
    }
    &__checkbox{
        margin-left: 30px;
    }
    .users__table {
        padding-bottom: 40px;
    }
    &__modal {
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        top:0;
        left:0;
        width: 100%;
        height: 100%;
        z-index: 555;

        &-content {
            max-width: 725px;
            width: 100%;
            padding: 24px 32px;
            background: #FFFFFF;
            border-radius: 16px;
        }
        &-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        &-title {
            font-weight: 500;
            font-size: 18px;
            line-height: 140%;
            letter-spacing: 0.2px;
            color: #2A3650;
        }
        &-close {
            cursor: pointer;
        }
        &-form {
            margin-top: 28px;
        }
        &-fields {
            background: #F9F9FB;
            border-radius: 16px;
            padding: 16px 20px;
        }


        &-field {
            display: flex;
            flex-direction: column;
            margin-top: 23px;
            &:first-child {
                margin-top:0;
            }
            label {
                font-weight: 400;
                font-size: 16px;
                line-height: 150%;
                color: #2A3650;
                margin-bottom: 10px;
            }
            input {
                background: #FFFFFF;
                border: 1px solid rgba(0, 0, 0, 0.1);
                border-radius: 8px;
                font-weight: 400;
                font-size: 16px;
                line-height: 150%;
                color: #2A3650;
                padding: 16px;

                &::placeholder {
                    color: #8A909D;
                }
            }
        }

        

        &-button {
            display: flex;
            justify-content: flex-end;
            margin-top: 28px;

            button {
                font-weight: 600;
                font-size: 16px;
                line-height: 150%;
                letter-spacing: 0.3px;
                color: #FFFFFF;
                background: #0052CC;
                border-radius: 8px;
                max-width: 200px;
                width: 100%;
                padding-top: 14px;
                padding-bottom: 14px;
                transition: all 0.3s;
                &:hover {
                    transform: translateY(-3px);
                }
                &:active {
                    transform: translateY(0px);
                }
            }
        }

        &-bg {
            position: fixed;
            top:0;
            left:0;
            width: 100%;
            height: 100%;
            z-index: -1;
            background-color: rgba(0,0,0, 0.5);

        }
    }
    &__selects {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    &__project {
        width: 50%;
        &:not(:last-child) {
            margin-right: 32px;
        }
        p {
            font-weight: 400;
            font-size: 16px;
            line-height: 150%;
            margin-bottom: 10px;
            color: #2A3650;
        }
    }

    &__document {
        padding-top: 20px;
        width: 100%;
        &:not(:last-child) {
            margin-right: 32px;
        }
        p {
            font-weight: 400;
            font-size: 16px;
            line-height: 150%;
            margin-bottom: 10px;
            color: #2A3650;
        }
    }

    &__details {
        padding: 20px 20px 40px 20px;
        background: #FFFFFF;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        height: 500px;
        overflow: scroll;
        &-title {
            font-weight: 400;
            font-size: 16px;
            line-height: 150%;
            color: #2A3650;
            margin: 20px 0 10px;
        }
    }
    &__generate {
        display: flex;
        justify-content: right;
    }
    &__generateBtn {
        background: #3375D6;
        border-radius: 8px;
        font-weight: 600;
        font-size: 16px;
        line-height: 150%;
        color: #FFFFFF;
        padding: 14px 48px;
        margin-top: 28px;
        &.disabled {
            background: #8A909D;
        }
    }
    &__viewBtn {
        background: white;
        cursor: pointer;
        border-radius: 8px;
        border: 1px solid grey;
        font-weight: 600;
        font-size: 16px;
        line-height: 150%;
        color: #2A3650;
        padding: 14px 48px;
        margin-top: 28px;
        &.disabled {
            background: #8A909D;
        }
    }
}

.search-block {
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    color: #2A3650;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    padding: 5px 12px;
    margin-right: 5px;
    margin-left: 16px;
}
.picker {
    position: relative;
}
//.react-datepicker-popper {
//    width: 100%;
//}
//.react-datepicker {
//    width: 100%;
//}
.reporting-select {
    .react-datepicker {
        border: unset;
    }
    .react-datepicker__month-container {
        background: #FFFFFF;
        border: 1px solid rgba(0, 0, 0, 0.1) !important;
        box-shadow: 5px 5px 32px rgba(20, 25, 34, 0.1);
        border-radius: 12px;
        width: 100%;
    }
    .react-datepicker__header {
        border: unset;
        background: unset;
    }
    .react-datepicker__month-text{
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: #2A3650;
        border-radius: 8px;
    }
    .react-datepicker__month-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-around;
    }
    .react-datepicker__month .react-datepicker__month-text, .react-datepicker__month .react-datepicker__quarter-text {
        width: 102px;
        height: 44px;
        margin: 2px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 24px;
    }
    input {
        width: 100%;
        border: 1px solid rgba(0, 0, 0, 0.1) !important;
        background: #FFFFFF;
        border-radius: 8px;
        height: 54px;
        color: black;
        padding-left: 20px;
    }
}

.select_input {
    input {
        border: none !important;
        border-radius: 0;
        height: auto !important;
    }
}