.downloadBtn {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 12px;
  border-radius: 8px;
  background: #0B89381A;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.2px;
  text-align: left;
  color: #0B8938;
  position: relative ;
  & img {
    width: 24px;
    height: 24px;
  }
  &__tooltip {
    display: none;
    position: absolute;
    padding: 4px 8px;
    background: #767676;
    border-radius: 8px;
    color: white;
    bottom: 101%;
    width: auto;
    text-align: center;
    &:before {
      content: "";
      position: absolute;
      top: 100%;
      left: 43%;
      border-width: 5px;
      border-style: solid;
      border-color: #767676 transparent transparent;
    }
  }
}
.downloadBtn:hover {
   .downloadBtn__tooltip {
     display: block;
   }
 }