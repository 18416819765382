.infoModal {
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 555;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &__content {
    max-width: 800px;
    width: 100%;
    background: #FFFFFF;
    border-radius: 16px;
    z-index: 6;
    padding: 24px 32px;
    &-top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 28px;

      & > img {
        cursor: pointer;
      }
    }
    &-title {
      font-weight: 500;
      font-size: 18px;
      line-height: 140%;
      letter-spacing: 0.2px;
      color: #2A3650;
    }
    &-text {
      padding: 16px 20px;
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
      color: #2A3650;
      background: #F9F9FB;
      border-radius: 16px;
    }
  }

  &__tabs {
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    margin-bottom: 16px;
    &-name {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      letter-spacing: 0.2px;
      color: #2A3650;
      padding: 14px 16px;
      cursor: pointer;
      &.active {
        color: #0052CC;
        border-bottom: 3px solid #0052CC;
      }
      &:last-child {
        border-left: 1px solid rgba(0, 0, 0, 0.1);
        &.active {
          color: #0052CC;
          border-bottom: 3px solid #0052CC;
          border-right: 1px solid rgba(0, 0, 0, 0.1);
        }
      }
    }
  }

  &__bg {
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 5;
  }
}