.erOverview {
  display: flex;
  flex-direction: row !important;
  gap: 32px;
  color: #2A3650;

  &__imgContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 380px;
    min-width: 380px;
    max-height: 340px;
    border-radius: 8px;
    overflow: hidden;
    background: #FFFFFF;
    border: 1px solid #0000001A;
    .project__image {
      height: 100%;
      background: none;
      border: none;
    }
  }
  &__infoContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  & button {
    position: relative ;
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: space-between;
    border-radius: 8px;
    border: 1px solid #0000001A;
    text-wrap: nowrap;
    color: #767676;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.24px;
    padding: 12px 16px 12px 16px;
    cursor: auto;

  }
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 20px;
    & h2 {
      font-size: 18px;
      font-weight: 600;
      line-height: 25.2px;
      letter-spacing: 0.24px;
      text-align: left;
      max-width: 80%;

    }
    & button {
      cursor: pointer;
      padding: 10px 12px;
      img {
        width: 16px;
        height: 16px
      }
    }
  }

  & ul {
    display: flex;
     & li {
       font-size: 14px;
       font-weight: 400;
       line-height: 20px;
       letter-spacing: 0.24px;
       text-align: left;
       color: #767676;
       list-style: disc;
       margin-right: 20px;
       text-transform: uppercase;
       &:first-child {
         list-style: none;
       }
     }
  }

  &__departmentInfo {
    display: flex;
    gap: 12px;
    align-items: center;
    & p {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    }
  }

  &__timeSchedular {
    display: flex;
    gap: 12px;
    .reporting-select input {
      height: 40px !important;
    }
    &__quarter{
      width: 200px;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.24px;
      text-align: left;
      color: #767676;
      padding: 12px 16px 12px 16px;
      span {
        display: block;
        width: 80%;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
      &__tooltip {
        display: none;
        position: absolute;
        left: 99%;
        padding: 4px 8px;
        background: #767676;
        border-radius: 8px;
        color: white;
        bottom: 112%;
        width: auto;
        text-align: center;
        &:before {
          content: "";
          position: absolute;
          top: 72%;
          left: -6px;
          border-width: 11px;
          border-style: solid;
          border-radius: 0 0 0 0;
          border-color: #767676 transparent transparent;
          transform: rotate(29deg);
        }
      }
    }
    img {
      cursor: auto;
    }
  }

  &__calendar {
    width: 194px;
    position: relative;
    input {
      padding-left: 16px !important;
      width: 194px;
      z-index: 59;
      &::placeholder {
        color: #767676;

      }
    }
    &-img {
      position: absolute;
      top: 6px;
      right: 16px;
      z-index: 50;
    }
  }

  &__buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    margin-top: auto;
    img {
      height: 20px;
    }

     button {
       display: flex;
       justify-content: center;
       width: 200px;
       flex: 1 1 0;
       max-width: 200px;
       padding: 12px 24px 12px 24px;
       cursor: pointer;
     }

  }


  &_flex{
    display: flex;
    gap: 8px;
    align-items: center;
  }
}

@media (max-width: 960px) {
  .erOverview {
    flex-direction: column !important;
    padding: 40px;
    &__buttons {
      flex-direction: column !important;
    }
    &__header {
      flex-direction: column !important;
    }
  }

}
.reportNumber:hover {
  .erOverview__timeSchedular__quarter__tooltip {
    display: block;
  }
}