.createQReportContainer{
  min-height: 100vh;
  width: 100%;
  background-image: url("../../../public/assets/images/auth__bg.svg");
  display: flex;
  flex-direction: column;
  gap: 24px;
  .create__title{
    margin-bottom: 0;
  }
}
.searchProject{
  margin-bottom: 24px;
  color: #767676;

}
.projectsContainer{
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.projectInfoWrapper{
  width: 100%;
  display: flex;
  gap: 6px;
  padding: 16px;
  border-radius: 8px;
  background: #F9F9FB;
  border: 1px solid #0000001A
}

.projectInfo {
  display: flex;
  flex-direction: column;
  gap: 4px;
  h3 {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.24px;
    text-align: left;
    color: #2A3650;
  }
  ul {
    display: flex;
    gap: 20px;
    li {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.24px;
      text-align: left;
      color: #767676;
      list-style: disc;
      text-transform: uppercase;
      &:first-child{
        list-style: none;
        text-transform: capitalize;
      }
    }
  }
}

.calendar {
  label{
    padding-left: 12px !important;
    top: 1px;
    font-size: 16px !important;
    line-height: 24px !important;
  }
  span {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: #8A909D;
  }

  .calendar-icon {
    height: 38px !important;
    width: 40px !important;
    right: 1px !important;
    left: auto !important;
    top: 3px !important;
  }
}
