.login {
    &__form {
        margin-top: calc(32px - 16px);

        input {
            margin-top: 16px;
        }
        button {
            margin-top: 32px;
        }
    }

    &__center {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 24px;
    }

    &__link {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: #0066FF;
    }
}

.privacy{
    display: block;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.24px;
    text-align: center;
    color: #767676;
    margin: 20px auto 10px;
    text-decoration: underline;
}