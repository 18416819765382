.generateReportContainer {
  max-width: 800px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border: 1px solid #0000001A;
  padding: 8px 8px 8px 16px;
  gap: 16px;
  border-radius: 8px;
  background: #F9F9FB;

  & button {
    position: relative ;
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: space-between;
    border-radius: 8px;
    border: 1px solid #0000001A;
    text-wrap: nowrap;
    color: #767676;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.24px;
    padding: 12px 16px 12px 16px;

  }
  &__info {
    color: #0052CC;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    margin-right: auto;
    cursor: pointer !important;
    pre {
      color: #0052CC;
    }
  }
  &__status {
    color: #767676;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.24px;
    text-align: right;

  }
  &__inactiveBtn {
    background: #AAAAAA1F;
    color: #AAAAAA !important;
    font-weight: 500 !important;
    border: none !important;
    & svg path {
      stroke: #AAAAAA
    }
  }
  &__activeBtn {
    background: #3375D61F;
    color: #3375D6 !important;
    font-weight: 500 !important;
    border: none !important;
    cursor: pointer !important;
    & svg path {
      stroke: #3375D6
    }
  }

  &__tooltip {
    display: none;
    position: absolute;
    padding: 4px 8px;
    background: #767676;
    border-radius: 8px;
    color: white;
    top: 120%;
    right: 0;
    width: auto;
    text-align: center;
    &:before {
      content: "";
      position: absolute;
      bottom: 100%;
      left: 43%;
      border-width: 5px;
      border-style: solid;
      border-color: #767676 transparent transparent;
      transform: rotate(-180deg);
    }
  }

  &__activeBtn, &__inactiveBtn {
    &:hover {
      .erOverview__generateReportContainer__tooltip {
        display: block;
      }
    }
  }

}