.statistics{
  max-height: 100vh;
  display: flex;
  flex-direction: column;
  gap: 24px;
  &__header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
    & h1{
      font-size: 24px;
      font-weight: 700;
      line-height: 31px;
      letter-spacing: 0;
      text-align: left;

    }
    &__rightSide{
      display: flex;
      align-items: center;
      gap: 24px;

    }
    &__datePickerContainer{
      width: 200px;
      display: flex;
      align-items: center;
      .react-datepicker {
        border: unset;
      }
      input{
        height: 48px !important;
        &::placeholder{
          font-size: 16px;
          font-weight: 400;
          line-height: 20px;
          text-align: left;
          color: #2A3650;

        }
      }

    }
    &__filterBtn{
      width: 200px;
      display: flex;
      align-items: center;
      padding: 12px;
      border-radius: 8px;
      gap: 12px;
      border: 1px solid #0000001A;
      & img {
        width: 20px;
        height: 20px;
      }
    }
  }
  &__toolBar{
    & button{
      width: auto;
      padding: 12px;
      gap: 10px;
      border-radius: 8px;
      border: 1px solid #0000001A

    }
  }
  &__content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;
    &__emptyContentContainer{
      height: 70vh;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
  }
}
@media screen and (max-width: 1440px) {
  .statistics__content {
    justify-content: center;
  }
}