.dashboard {
    &__title {
        font-weight: 700;
        font-size: 24px;
        line-height: 130%;
        color: #2A3650;
    }

    &__table {
        background: #FFFFFF;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 8px !important;
        border: 0px solid #000000;
    }

    &__tr {
        border: 1px solid rgba(0, 0, 0, 0.1);
        position: relative;
        &.edit {
            width: 100%;
            // filter: drop-shadow(0px 4px 14px rgba(0, 0, 0, 0.15));
            .dashboard__editBtns {
                display: flex;
            }
        }
    }

    &__thead {
        background-color: #fff !important;
        text-transform: capitalize;
        
    }

    &__th  {
        font-weight: 400;
        font-size: 14px ;
        line-height: 20px ;
        letter-spacing: 0.2px ;
        color: #8A909D ;
    }

    &__td {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.2px;
        color: #2A3650;
    }

    &__tbody {
        tr {


            td {
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                letter-spacing: 0.2px;
                color: #2A3650;
            }
        }
    }

    &__btn {
        display: flex;
        align-items: center;
        background: #0052CC;
        border-radius: 8px;
        padding: 8px 16px;
        transition: all 0.3s;
        span {
            font-weight: 500;
            font-size: 14px;
            line-height: 150%;
            text-align: center;
            letter-spacing: 0.2px;
            color: #FFFFFF;
            margin-left: 4px;
        }

        &:hover {
            transform: translateY(-3px);
        }
        &:active {
            transform: translateY(0px);
        }
    }
    &__editBtn {
        position: relative;
        cursor: pointer;
        &:hover {
            p {
                display: block;
            }
        }
        p {
            font-family: 'Rubik';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.2px;
            color: #FFFFFF;
            padding: 4px 0;
            position: absolute;
            top: 50px;
            display: none;
            right: -45px;
            background: #767676;
            border-radius: 8px;
            min-width: 123px;
            text-align: center;
            z-index: 22;

            &:before {
                content: "";
                position: absolute;
                top: -20px;
                right: 50px;
                border-left: 11px solid transparent;
                border-right: 11px solid transparent;
                border-bottom: 11px solid #767676;
                border-top: 11px solid transparent;
                border-radius: 2px;
                transform: matrix(-1, 0, 0, 1, 0, 0);
            }
        }
        &:not(:last-child) {
            margin-right: 10px;
        }
    }
    &__editBtns {
        cursor: pointer;
        display: flex;
        align-content: center;
        margin-right: 15px;
        z-index: 22;
        // position: relative;
        img {
            min-height: 32px;
            min-width: 32px;
        }

        .a-checkbox {
            label:before {
                top: 11px;
                left: 8px;
            }
            label:after {
                top: 6px;
                left: 6px;
            }
        }

        &-links {
            position: absolute;
            top: 99%;
            right: -60px;
            z-index: 22;
            border-radius: 12px;
            border: 1px solid #D4DAE7;
            background: var(--others-white, #FFF);
            width: max-content;
            a {
                display: block;
                color: var(--greyscale-900, #111827);
                font-family: Rubik;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 150%; /* 24px */
                padding: 16px 12px;
                cursor: pointer;
                &:hover {
                    background-color: #0052cc0b;
                }
            }
        }
    }
    &__deleteBtn{
        width: 32px;
        height: 32px;
    }
}
@supports (-webkit-backdrop-filter: blur(1px)) {
    .dashboard__editBtn {
        top: 27%;
    }
}