.statisticFilterModal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 55;
  width: 100%;
  height: 100%;
  display: flex;
  //justify-content: right;
  &__bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-color: rgba(0, 0, 0, 0.4);
  }
}
  .statisticFilter {
    position: relative;
    height: 100vh;
    margin-left: auto;
    overflow-y: scroll;
    background: #FFFFFF;
    width: 500px;
    padding: 24px  32px 0 32px;
    z-index: 999;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #E5E5E5;
    padding-bottom: 20px;
    margin-bottom: 20px;

    & h3 {
      font-size: 18px;
      font-weight: 600;
      line-height: 25px;
      letter-spacing: 0.2px;
      margin-right: 40px;
    }
    &__closeIcon{
      width: 24px;
      height:24px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &__form {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    & input::placeholder{
      color: #868686;
    }
    p{
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0;
      text-align: left;
      color: #2A3650;
      margin-bottom: 8px;
    }
    &__multiSelect{
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
      align-self: stretch;
      padding: 16px;
      border-radius: 8px;
      border: 1px solid #E5E5E5;
      &__checkingItem{
        display: flex;
        padding: 12px;
        align-items: center;
        gap: 10px;
        align-self: stretch;
        border-radius: 8px;
        background: #F9F9FB;
        label{
          color:  #2A3650;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 150%;
          letter-spacing: 0.2px;
        }
        span{
          color: #2A3650;
          text-align: right;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: 0.2px;
          margin-left: auto;
        }
      }
    }
    & .search-block{
      display: flex;
      padding: 16px;
      align-items: center;
      gap: 8px;
      flex-shrink: 0;
      align-self: stretch;
      margin: 0;
    }
    &__notFoundDat{
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 14px;
      & img {
        width: 90px;
        height: auto;
        margin-bottom: 15px;
      }
      p {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.2px;
        text-align: center;
      }
    }
    & button{
      display: flex;
      height: 48px;
      justify-content: center;
      align-items: center;
      gap: 12px;
      border-radius: 8px;
      border: 1px solid #E5E5E5;
      color: #2A3650;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%;
      letter-spacing: 0.2px;
    }
    &__btnSaveFilter{
      background: #F9F9FB;
      padding: 16px;
    }
    &__btnReset, &__btnSubmit {
      width: 100px;
      padding: 12px 16px;
    }
    &__btnSubmit {
      border-radius: 8px;
      border: none;
      background:  #3375D6 !important;
      color: #FFFFFF !important;
    }
    &__footer{
      display: flex;
      justify-content: space-between;
      position: sticky;
      gap: 16px;
      bottom: 0;
      border-top: 1px solid  #E5E5E5;
      background: #FFF;
      padding: 24px 32px;
      margin: auto -32px 0 -32px;
      z-index: 99;
    }
  }
    &_df{
      display: flex;
      justify-content: space-between;
      gap: 16px;
    }
}

  .saveFilterModal{
    position: absolute;
    height: fit-content;
    margin: auto;
    width: 550px;
    left: 0;
    right: calc(100% - (500px + 400px));
    bottom: 0;
    top: 0;
    padding: 24px 32px 32px 32px;
    border-radius: 16px;
    background: #FFFFFF;
    z-index: 999;
    display: flex;
    flex-direction: column;
    gap: 20px;
    & .statisticFilter__header{
      margin-bottom: 0;
    }
    & p {
      color: #2A3650;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      margin-bottom: 8px ;
    }
    label{
      color: #2A3650;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%;
      letter-spacing: 0.2px;
    }
    & input {
      width:100%;
      padding: 16px;
      gap: 12px;
      border-radius: 8px;
      border: 1px solid #0000001A
    }
    &__SBtn {
      width: 100%;
      background:  #0052CC;
    }
  }