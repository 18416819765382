.reporting {
  background: #F9F9FB;
  &-tabs {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background: #fff;
    padding: 6px 5px;
    margin-bottom: 16px;
  }
  &-tab {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    width: 50%;
    padding: 12px;
    cursor: pointer;
    transition: all 0.3s;
    &.active {
      border-radius: 6px;
      background: #0052cc;
      box-shadow: 0 2px 4px 0 rgba(17, 24, 39, 0.04);
      color: #fff;
    }
  }
  &__contentContainer {
    padding: 24px;
    gap: 20px;
    border-radius: 12px;
    border: 1px solid #0000001A;
    background: #FFFFFF;

  }
}