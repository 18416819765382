.elementDetailsCotainer{
  width: 470px;
  pointer-events: all;
  padding: 20px 0;
  border-radius: 12px;
  background: #FFF;
  box-shadow: 0 16px 48px 0 rgba(0, 0, 0, 0.32);
  position: relative;
  z-index: 99;
}
.elementDetails {
  overflow-y: scroll;
  padding: 0 20px;
  max-height: 60vh;
  display: flex;
  flex-direction: column;
  gap: 16px;
  font-weight: 400;
  font-style: normal;
  color:  #2A3650;


  &__triangle{
    display: none;
    position: absolute;
    top: -20px;
    left: 50%;
    border-top: 15px solid transparent;
    border-right: 30px solid #FFFFFF;
    border-bottom: 15px solid transparent;
    transform: rotate(90deg) ;
    margin-right: 5px;
    background: none;
    z-index: -1;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    padding-bottom: 16px;
    border-bottom: 1px solid #E5E5E5;
    &__statistic {
      display: flex;
      align-items: center;
      gap: 10px;
      &__colorEl {
        width: 14px;
        height: 14px;
        border-radius: 4px;
        background: #6CD152;
      }
      &__values {
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0.2px;
      }
    }
    &__projectsCount {
      color: #8A909D;
      font-size: 16px;
      line-height: 150%;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    padding: 16px;
    gap: 20px;
    align-self: stretch;
    border-radius: 8px;
    background: #F4F5F6;
    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      align-self: stretch;
      gap: 20px;
      h6 {
        font-size: 16px;
        font-weight: 500;
        line-height: 150%;
        letter-spacing: 0.2px;
        margin-bottom: 4px;
      }
      p {
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.2px;
      }
      &__viewReportBtn {
        pointer-events: all;
        display: flex;
        padding: 6px 12px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 5px;
        background: #FFF;
      }
    }
    &__wrapper {
      display: flex;
      gap: 12px;
      flex-wrap: wrap;
      &__item {
        display: flex;
        width: 48%;
        padding: 8px 12px;
        flex-direction: column;
        justify-content: center;
        gap: 4px;
        border-radius: 5px;
        background: #FFF;
        &__title {
          color:  #8A909D;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.2px;
        }
        &__descriptioin {
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.2px;
        }
      }
    }
    &__contractDetailsContainer {
      display: flex;
      flex-direction: column;
      gap: 13px;
    }
    &__contractDetails {
      display: flex;
      justify-content: space-between;
      align-self: stretch;
      gap: 20px;
      &___description {
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.2px;
      }
      &__value {
        font-size: 14px;
        font-weight: 500;
        line-height: 150%;
        letter-spacing: 0.2px;
      }
    }
  }
}