.languages {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  &__table{
    &__img {
      width: 24px;
      height: 24px;
      border-radius:50%
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    h1 {
      font-size: 24px;
      font-weight: 700;
      line-height: 31.2px;
      text-align: left;

    }
  }

  .table-td {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .modal-content {
    padding-bottom: 40px;
    button {
      margin-top: 10px;
    }
  }

  &__uploadImg {
    width: fit-content;
    margin: 0 auto;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 12px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #3375D6;
    text-decoration: underline;

    &__imgContainer {
       display: flex;
       align-items: center;
       justify-content: center;
       width: 92px;
       height: 92px;
       border-radius: 50%;
       overflow: hidden;
       left: 197px;
       background: #F9F9FB;
       border: 1px solid #0000001A;
        & img {
         width: auto;
       }
       &_cover{
         object-fit: cover;
         width: 100% !important;
       }
     }
    input {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      z-index: 22;
      cursor: pointer;
    }
  }
}