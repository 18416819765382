.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 55;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  &-bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-color: rgba(0, 0, 0, 0.4);
  }
  &__container {
    max-height: calc(100vh - 80px);
    overflow: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    border-radius: 16px;
    background: #ffffff;
    max-width: 550px;
    width: 100%;
    padding: 24px 32px;

    h5 {
      font-weight: 500;
      font-size: 18px;
      line-height: 140%;
      letter-spacing: 0.2px;
      color: #2a3650;
    }
  }
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #CCDCF5;
    padding-bottom: 20px;
  }
  &__closeIcon {
    cursor: pointer;
  }
  &__form {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding-top: 20px;
    & label {
      display: block;
      margin-bottom: 12px;
      span{
        color: #ec4747;
      }
    }
    input {
      background-color: white;
      padding: 16px;
    }
    textarea {
      width: 100%;
      height:120px;
      padding: 16px;
      border-radius: 8px;
      border: 1px solid #0000001A
    }
    &__uploader {
      display: flex;
      align-items: center;
      flex-direction: column;
      border-radius: 12px;
      border: 1px dashed #0000001A;
      background: #fff;
      position: relative;
      margin-bottom: 8px;
      input {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        z-index: -1;
      }
      label {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        padding-top: 40px;
        padding-bottom: 40px;
        span {
          display: block;
          color: #0052cc;
          text-align: center;
          font-size: 14px;
          font-weight: 500;
          line-height: 150%;
          letter-spacing: 0.2px;
          margin-top: 16px;
          &:last-child {
            color: #8d8d9d;
            margin-top: 0;
          }
        }
      }
    }
    & p{
      margin-bottom: 0;
    }
    &__file {
      display: flex;
      align-items: center;
      gap: 12px;
      border-radius: 12px;
      background: #f9f9fb;
      padding: 12px;
      overflow: hidden;
      &_name{
        max-width: 360px;
        overflow: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;
        display: flex;
        flex-direction: column;
        &::-webkit-scrollbar {
          width: 0;
          height: 0;
        }
        span {
          &:first-child {
            color: #23262f;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 150%;
            letter-spacing: 0.2px;
          }
          &:last-child {
            color: #8d8d9d;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0.2px;
          }
        }
      }
      &__closeBtn {
        width: 24px;
        height: 24px;
        margin-left: auto;
      }
    }
    &__btnDisabled{
      background: #8A909D !important;
    }
  }
}
.charCount{
  position: relative;
  float: right;
  display: block;
  color: #afafb4;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: 0.2px;
  top: -30px;
  right: 15px;
}