.a-input {
    position: relative;
    .input {
        position: relative;
    }
    label {
        position: absolute;
        top: 45%;
        left: 14px;
        // opacity: 0;
        transition: all 0.3s;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        letter-spacing: 0.2px;
        color: #8A909D;
    }
    img {
        position: absolute;
        width: 20px;
        height: 20px;
        top: 50%;
        right: 15px;
        cursor: pointer;
    }
    input {
        padding: 12px;
        background: #F9F9FB;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        width: 100%;
        transition: all 0.3s;
        &::placeholder {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 150%;
            letter-spacing: 0.2px;
            color: #8A909D;
        }
    }

    input:focus {
        border-color: #0052CC;
        background-color: #fff;
    }

    input:focus + label {
        transform: translateY(-22px) translateX(3px);
        font-weight: 400;
        font-size: 12px;
        line-height: 160%;
        letter-spacing: 0.4px;
        color: #0052CC;
        background-color: #fff;
        padding-left: 3px;
        padding-right: 3px;
    }
    &.active {
        label {
            transform: translateY(-22px) translateX(3px);
            font-weight: 400;
            font-size: 12px;
            line-height: 160%;
            letter-spacing: 0.4px;
            color: #0052CC;
            background-color: #fff;
            padding-left: 3px;
            padding-right: 3px;
        }
    }

    &__error {
        font-size: 12px;
        color: #FD6A6A;
        margin-top: 2px;
        padding-left: 5px;
    }
    &.error {

        .input {
            input {
                border-color: #FD6A6A;
            }
            label {
                color: #FD6A6A;
            }
        }
    }
}