

.custom-table {
  display: flex;
  flex-direction: column;
  gap: 6px;
  //min-width: 960px;
  text-align: center;
  font-weight: 400;
  color: #2A3650;
}
.custom-table thead {
  display: flex;
  flex-direction: column;
}
.custom-table  tr {
  display: flex;
  justify-content: space-between;
  border-radius: 8px 0 0 8px;
  border: 1px solid #0000001A;
}
.header {
  &__yearsRow {
    justify-content: space-between;
    margin-bottom: 6px;
    background: #F4F5F6;

    th, td{
      padding: 16px 20.5px 16px 12.5px !important;
      font-size: 12px;
      line-height: 19.2px;
      letter-spacing: 0.5px;
      text-align: left;
      font-weight: 400;
      width: 100%;
      display: block;


      &:nth-child(1) {
        min-width: 145px !important;
        font-weight: 600;
        text-align: left;
        width: auto !important;

      }

    }
  }
  &__monthRow {
    th {
      font-size: 12px;
      line-height: 19.2px;
      letter-spacing: 0.45px;
      text-align: left;
      font-weight: 400;
      padding: 16px 12px 16px 12.5px;
      gap: 10px;

      &:first-child {
        min-width: 145px;
        font-weight: 600;
        text-align: left;

      }
    }
  }
}

.custom-table th, .custom-table td {
  border-right: 1px solid #ccc;
  font-size: 12px;
  line-height: 19.2px;
  letter-spacing: 0.45px;
  text-align: left;
  padding: 16px 12px 16px 12.5px;
  gap: 10px;

  &:first-child {
    min-width: 145px
  }

  &:last-child{
    border-right: none;
  }
}


.custom-table .section-header {
  width: 100%;
  font-weight: bold;
}

.custom-table td {
  position: relative;
}
