.quarterlyReport {
  min-height: 100vh;
  width: 100%;
  background-image: url("../../../public/assets/images/auth__bg.svg");
  display: flex;
  flex-direction: column;
  gap: 24px;

  &__content{
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow-x: scroll;
    scrollbar-color: #f5e8e8 #efebe6;
    scrollbar-width: thin;
    gap: 24px;
    border: 1px solid #0000001A;
    border-radius: 8px;
  }
  &__headerWrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 24px;
    h1 {
      font-size: 24px;
      font-weight: 700;
      line-height: 31.2px;
      text-align: left;
      color: #2A3650;

    }
    & button {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: #0052CC;
      padding: 8px 16px 8px 16px;
      gap: 4px;
      border-radius: 8px;
      font-size: 14px;
      font-weight: 500;
      line-height: 21px;
      letter-spacing: 0.20000000298023224px;
      text-align: center;
      color: #FFFFFF;

    }
  }

  .table {
    border: none;
    border-radius: 0;
    padding-bottom: 60px;
  }

  .table-header :nth-child(1),
  .table-row > :nth-child(1),
  .table-header :nth-child(4),
  .table-row > :nth-child(4) {
    width: 20% !important;
    min-width: 220px !important;
  }

  .table-header :nth-child(2),
  .table-row > :nth-child(2),
  .table-header :nth-child(6),
  .table-row > :nth-child(6),
  .table-header :nth-child(7),
  .table-row > :nth-child(7) {
    width: 8% !important;
    min-width: 150px !important;
  }

  .table-header :nth-child(5),
  .table-row > :nth-child(5) {
    width: 6% !important;
    min-width: 120px !important;
  }

  .table-header :nth-child(3),
  .table-row > :nth-child(3) {
    width: 240px !important;
    min-width: 200px !important;
  }

  .table-header :nth-child(8),
  .table-row > :nth-child(8) {
    width: 13% !important;
    max-width: 180px !important;
  }

  .table-td {
    white-space: normal !important;
    overflow: hidden !important;
    text-overflow: ellipsis;
  }

  &__tableSettings{
    display: flex;
    justify-content: flex-end;
    gap: 10px;

    button {
     display: flex;
     align-items: center;
     justify-content: center;
      position: relative;
     width: 32px;
     height: 32px;
     gap: 10px;
     border-radius: 6px;
     background: #F9F9FB;
     border: 1px solid #EAEAEA;
      transition: all 0.3s;
      &:hover {
        background: #f2f2f8;
      }
      &:active {
        background: #FFFFFF;
      }
    }

    & img {
     width: 20px;
     height: 20px;
     gap: 10px;
     border-radius: 6px;
    }
    &__btnTooltip {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.2px;
      color: #FFFFFF;
      padding: 4px 0;
      position: absolute;
      top: 50px;
      display: none;
      right: -45px;
      background: #767676;
      border-radius: 8px;
      min-width: 123px;
      text-align: center;
      z-index: 22;

      &:before {
        content: "";
        position: absolute;
        top: -20px;
        right: 50px;
        border-left: 11px solid transparent;
        border-right: 11px solid transparent;
        border-bottom: 11px solid #767676;
        border-top: 11px solid transparent;
        border-radius: 2px;
        transform: matrix(-1, 0, 0, 1, 0, 0);
      }
    }
    &_lastElTooltip {
      right: -20px !important;
      &:before {
        right: 25px;
      }
    }
  }
}

.quarterlyReport__tableSettings {
  overflow: visible !important;
  & button:hover {
    .quarterlyReport__tableSettings__btnTooltip {
      display: block;
    }
  }
}
