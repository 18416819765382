.glossary {
  position: relative;
  min-height: 100vh;
  width: 100%;
  background-image: url("../../../public/assets/images/bg.png");
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-bottom: 110px;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &__search {
      max-width: 400px;
      width: 300px;
      border-radius: 8px;

      & .a-input-img img {
        right: auto;
        left: 15px;
        top: 32%;
      }
      & input {
        background: #fff;
        padding-left: 42px !important;
        &::placeholder {
          color: #767676;
        }
      }
    }
  }
  &__list {
    display: flex;
    flex-direction: column;
    grid-gap: 12px;
    margin-top: 28px;
  }
  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    border-radius: 8px;
    padding: 16px 24px;
    transition: all .3s;
    background: #F9F9FB;
    border: 1px solid #0000001A;
    &:hover {
      border: 1px solid #CCDCF5;
      box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
    }
    &-text {
      font-weight: 500;
      font-size: 16px;
      line-height: 140%;
      color: #2A3650;
    }
    &-right {
      display: flex;
      align-items: center;
      grid-gap: 40px;
    }
    &-block {
      display: flex;
      align-items: center;
      grid-gap: 12px;
    }
    &-lang {
      font-weight: 500;
      font-size: 16px;
      line-height: 150%;
      letter-spacing: 0.3px;
      color: #2A3650;
    }
    &-arrow {
      margin-left: 40px;
      svg {
        width: 24px;
        height: 24px;
      }
    }
  }
  &__tabs {
    display: flex;
    gap: 14px;
    padding: 20px;
    border-radius: 8px;
    background: #FFFFFF;
    border: 1px solid #0000001A;
    overflow-x: scroll;

    &::-webkit-scrollbar {
      display: none;
    }
  }
  &__tab {
    width: auto;
    min-width: fit-content;
    display: flex;
    gap: 8px;
    align-items: center;
    color: #8A909D;
    background: #F4F5F6;
    padding: 12px 24px;
    border-radius: 6px;
    cursor: pointer;
    svg {
      width: 20px;
      height: 20px;
    }
    &_active{
      color: #FFFFFF;
      background: #3375D6;
      & svg path {
        stroke: #FFFFFF;
      }
    }
  }
  &__footer {
    position: fixed;
    bottom: 0;
    right: 0;
    background: #FFFFFF;
    left: 64px;
    padding: 20px 32px;
    display: flex;
    gap: 16px;
    justify-content: flex-end;

    &__cancelBtn, &__saveBtn {
      width: 90px;
      padding: 12px 16px 12px 16px;
      border-radius: 8px;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0.24px;
      text-align: center;
      cursor: pointer;
      transition: all 0.3s;
      &:hover {
        transform: translateY(-3px);
      }
      &:active {
        transform: translateY(0px);
      }
    }
    &__cancelBtn {
      border: 1px solid #EFEFEF;
      color: #1A1D1F;
      &:hover {
        background: #F9F9FB;
      }
      &:active {
        background: #efeff1;
      }
    }
    &__saveBtn {
      background: #8A909D;
      color: #FCFCFC;
      &:hover {
        background: #a3a5a9;
      }
      &:active {
        background: #818183;
      }
      &_active {
        background: #0052CC;
        &:hover {
          background: #003E99

        }
        &:active {
          background: #0052CC;
        }
      }
    }
  }
}

.glossaryContent {
  width: 100%;
  display: flex;
  align-items: flex-start;
  gap :24px;

  &__subSectionContainer {
    width: 25%;
    min-width: 280px;
    max-height: 362px;
    padding: 8px 0;
    border-radius: 8px;
    background: #FFFFFF;
    overflow: scroll;
    scrollbar-width: thin;
    scrollbar-color: #d7d8da #FFFFFF;
    border: 1px solid #0000001A;

    & li {
      position: relative;
      color: #8A909D;
      font-size: 14px;
      font-weight: 500;
      line-height: 21px;
      text-align: left;
      letter-spacing: 0.24px;
      padding: 10px 20px;
      margin: 2px 0;
      cursor: pointer;
      & span{
        display: none;
      }
    }
    &__activeSection {
      color: #0052CC !important;
      & span {
        display: block !important;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        color: #0052CC !important;
        background: #0052CC;
        width: 3px;
        height: 32px;
        border-radius: 0 10px 10px 0;
      }
    }
  }
  &__glossaryWrapper {
    width: inherit;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  &__glossaryContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px 20px;
    background: #FFFFFF;
    border-radius: 8px;
    border: 1px solid #0000001A
  }
  &__languageSection {
    display: flex;
    gap: 8px;
    padding-bottom: 20px;
    border-bottom: 1px solid #EEEEEE;
    &__item {
      display: flex;
      padding-right: 8px;
      border-right: 1px solid #EEEEEE;
      & button {
        display: flex;
        padding: 8px 10px 8px 10px;
        gap: 10px;
        border-radius: 6px;
        &:hover {
          background: rgba(95, 142, 217, 0.1);
        }
      }
      & img {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        object-fit: cover;
      }
      &_active {
        & button {
          background: #0052CC1A;
        }
      }
    }
  }
  &__tranlastionSection {
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-height: 500px;
    padding-top: 10px;
    overflow-y: auto;
    &_row {
      justify-content: space-between;
      flex-direction: row;
      gap: 28px;
    }
    &__item {
      display: flex;
      flex-direction: column;
      gap: 8px;
      width: 100%;
      &_width45 {
        min-width: 48% !important;
        flex-direction: row;
        align-items: center;
        gap: 20px;
        & input{
          font-weight: 500 !important;
        }
      }
      &__charCount{
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.24px;
        color: #8A909D;

      }
      & label {
        display: flex;
        gap: 8px;
      }
      &__info{
        position: relative;
        & svg {
          width: 20px;
          height: 20px;
          cursor: pointer;
        }
        &:hover{
          .glossaryContent__tranlastionSection__item__tooltip {
            display: block;
          }
        }
      }
      &__tooltip {
        display: none;
        position: absolute;
        padding: 4px 8px;
        background: #2A3650;
        border-radius: 8px;
        color: white;
        bottom: 0;
        left: 30px;
        height: fit-content;
        width: auto;
        max-width: 450px;
        text-align: center;
        white-space: nowrap;
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        &:before {
          content: "";
          position: absolute;
          border-width: 9px;
          border-style: solid;
          border-color: #2A3650 transparent transparent;
          top: 25%;
          left: -14px;
          transform: rotate(90deg);
        }
      }
    }
    label {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      text-align: left;
      color: #2A3650;
      text-wrap: nowrap;
      margin-bottom: 0;

    }
    & input, & textarea{
      width: 100%;
      padding: 12px 16px 12px 16px;
      gap: 10px;
      border-radius: 5px;
      background: #F9F9FB;
      border: 1px solid #0000001A;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.24px;
      color: #2A3650;
      text-transform: capitalize;
    }
  }
}