.covenants {

}
.financialCovenants{
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;

  .table-container {
    overflow-x: scroll;
  }
  .report__table {
    //width: fit-content;
    width: min-content;
    min-width: 100%;
  }
  .report__table__row{
    min-width: 1000px;
    width: 100%;
  }

  h3 {
    margin-bottom: 0;
  }
  .report__table__header th {
    width: 12%;
    min-width: 130px;
  }
  .report__table__header th:nth-child(5),
  .report__table__header th:nth-child(6) {
    width: 17%;
    min-width: 150px;
  }
  .report__table__header th:nth-child(8),
  .report__table__header th:nth-child(9),
  .report__table__header th:nth-child(10) {
    width: 15%;
    min-width: 200px;
  }

  .report__table__row td {
    width: 12%;
    min-width: 130px;
  }

  .report__table__row td:nth-child(6),
  .report__table__row td:nth-child(5) {
    width: 17%;
    min-width: 150px;
  }
  .report__table__row td:nth-child(8),
  .report__table__row td:nth-child(9),
  .report__table__row td:nth-child(10), {
    width: 15%;
    min-width: 200px;
  }
}

.financialRatio {
  & .report__table__header  th  {
      width: 15%;
       &:nth-child(1),
       &:nth-child(5) ,
       &:nth-child(4) {
        width: 18%
      }

  }
}

.otherCovenants {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .table-container {
    margin-bottom: 10px;

  }
  .report__table__row th {
    width: 8%;

  }
  .report__table__row th:nth-child(3) {
    width: 24%
  }
  .report__table__row th:nth-child(4),
  .report__table__row th:nth-child(5),
  .report__table__row th:nth-child(8) {
    width: 13%
  }


  .report__table__row td {
    width: 8%;
  }
  .report__table__row td:nth-child(3) {
    width: 24%
  }
  .report__table__row td:nth-child(4),
  .report__table__row td:nth-child(5),
  .report__table__row td:nth-child(8) {
    width: 13%
  }

}