.reportForm {
  padding-top: 15px;
  &__fieldContainer{
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 30px;
     & label {
       font-size: 16px;
       font-weight: 400;
       line-height: 24px;
       text-align: left;
       color: #2A3650;
       span {
         color: red;
       }
     }
    .a-input input {
      border: 1px solid #0000001A;
      background: #FFFFFF;
      padding: 16px;
      gap: 12px;
      border-radius: 8px;

    }
    .react-datepicker button{
      position: absolute !important;
      border: none;
    }



  }
  &__buttons{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 28px;
    & button {
      width: 47%;
      padding: 8px;
      border-radius: 8px;
      font-size: 14px;
      font-weight: 500;
      line-height: 21px;
      letter-spacing: 0.24px;
      text-align: center !important;
      justify-content: center !important;
      border: none !important;
      cursor: pointer !important;
     }

    &_cancel{
      background: #EEEEEE;
      color: #767676;

    }
    &_confirm{
      background: #3375D6;
      color: #FFFFFF !important;
    }

  }
  &__calendar {
    position: relative;
    width: 100%;
    input {
      padding: 16px !important;
      gap: 12px;
      font-weight: 400 !important;
      font-size: 16px !important;
      line-height: 150% !important;
      color: #2A3650 !important;
      width: 100% !important;
      background: #FFFFFF !important;
      border: 1px solid rgba(0, 0, 0, 0.1) !important;
      border-radius: 8px !important ;
      &::placeholder {
        color: #8A909D;
      }
    }
    img {
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
    }
    label {
      display: flex;
      align-items: center;
      padding-left: 16px;
      padding-top: 3px;
      position: absolute;
      top: 10px;
      right: 1px;
      width: 60%;
      height: 40px;
      background: #FFFFFF;
      border-radius: 8px;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.2px;
      color: #2A3650;
      cursor: pointer;
    }
  }
}


