.audit {
  min-width: max-content;
  &__header {
    border-bottom: 1px solid #E0E0E2;
    left: 64px;
    color: #2A3650;

    h1 {
      font-size: 24px;
      font-weight: 700;
      line-height: 31.2px;
      margin-bottom: 16px;
   }
  }

  &__content {
    max-height: 100%;
    min-height: calc(100vh - 165px);
    padding: 20px 0;
  }

  &__tableTd {
    min-width: 110px;
    &__tooltip {
      display: none;
      position: absolute;
      padding: 4px 8px;
      background: #2A3650;
      border-radius: 8px;
      color: white;
      bottom: -34px;
      top: 100%;
      height: fit-content;
      width: auto;
      max-width: 350px;
      text-align: center;
      white-space: break-spaces;
      &:before {
        content: "";
        position: absolute;
        border-width: 5px;
        border-style: solid;
        border-color: #2A3650 transparent transparent;
        transform: rotate(180deg);
        top: -10px;
        left: 20%;
      }
    }
  }
}

.audit__tableTd:hover {
  .audit__tableTd__tooltip {
    display: block;
  }
}
.tabs {
  border-radius: 4px 4px 0 0;
  border: 1px solid #0000001A;
  &__tab {
    padding: 14px;
    border-right: 1px solid #0000001A;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.24px;
    text-align: center;
    color: #8A909D;

    &_active {
      color: #0052CC;
      box-shadow: 0px -3px 0px 0px #074EE8 inset;
    }
  }
}

.userAccessActivity {
  display: flex;
  flex-direction: column;
  gap: 24px;
  &__nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    &__wrapper {
      margin-left: auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 20px;
    }
  }
  &__search {
    width:376px;
    border-radius: 8px;
    &_width {
      width: 252px;
    }
    & .a-input-img img {
     right: auto;
      left: 15px;
      top: 32%;
    }
    & input {
      background: #fff;
      padding-left: 42px !important;
      &::placeholder {
        color: #767676;

      }
    }

  }
  &__calendar {
    width: 252px;
    position: relative;
    & .reporting-select input {
      height: 48px;
    }
    input {
      height: 50px !important;
      padding-left: 40px !important;
      width: 252px !important;
      &::placeholder {
        color: #767676 !important;
      }
    }
    &-svg {
      position: absolute;
      top: 14px;
      left: 10px;
      z-index: 50;
    }
  }
  &__select {
    padding: 16px 0;
    border-radius: 8px;
    border: 1px solid #0000001A;

  }
  &__btnExport {
    width: 148px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 13px 16px;
    border-radius: 8px;
    background: #0052CC1A;
    & img {
      height: 24px;
      width: 24px;
    }
  }
}