.editor-container {
  position: relative;
  margin-bottom: 30px;

 .ql-toolbar.ql-snow {
   border-radius: 8px 8px 0 0;
   background: #F8F8F8;

 }
  .ql-container.ql-snow{
    border-radius: 0 0 8px 8px;
  }
  .ql-editor {
    min-height: 150px ;
  }
  .ql-snow .ql-editor h1 {
    font-size: 36px;
    line-height: 100%;
  }
  .ql-snow .ql-editor h2 {
    font-size: 30px;
    line-height: 100%;
  }
  .ql-snow .ql-editor h3 {
    font-size: 24px;
    line-height: 100%;
  }
  .ql-snow .ql-editor a {
    text-decoration: underline;
    text-transform: capitalize;
    color: #06c;
  }
  .ql-editor p, .ql-editor ol, .ql-editor ul, .ql-editor pre, .ql-editor blockquote, .ql-editor h1, .ql-editor h2, .ql-editor h3, .ql-editor h4, .ql-editor h5, .ql-editor h6 {
    margin: 0;
    padding: 0;
    counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;

  }
  .ql-editor ol li:not(.ql-direction-rtl), .ql-editor ul li:not(.ql-direction-rtl) {
    padding-left: 0 ;
  }

  .ql-editor li {
    padding-left: 25px !important;
  }

  .ql-editor li:before {
    text-align: left !important;
    width: auto !important;
  }


&__settingsButtons {
    display: flex;
    position: absolute;
    right: 10px;
    bottom: -26px;
    align-items: center;
    gap: 4px;
    z-index: 2;
    padding-top: 5px;

    &:hover {
      display: flex;
    }

    & button {
      padding: 2px 16px 4px 12px;
      gap: 10px;
      border-radius: 0 0 8px 8px;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.24px;
      border-top: none !important;
    }
  }
}
  .cancel-button {
    background: #F8F8F8;
    border: 1px solid #E5E5E5;
    color: #8A909D ;
  }
  .save-button {
    border:  1px solid #8CD7B4;
    background: #DFF7EC;
    color: #0CAF60;
  }

