.noInternetContainer {
  min-height: 100vh;
  width: 100%;
  background-image: url("../../../public/assets/images/auth__bg.svg");
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;
  color: #2A3650;

  & img {
    width: 150px;
    height: auto;
  }
  & h1 {
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    text-align: center;
  }
  & p {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;

  }
}