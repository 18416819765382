.nSettings {
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  color: #2A3650;

  &__headerWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #FFFFFF;
    padding: 16px 20px;
    border-radius: 8px;
    gap: 16px;
    border: 1px solid #0000001A
  }
  &__projectCollapse {
    display: flex;
    width: 385px;
    align-items: center;
    position: absolute;
    justify-content: space-between;
    &__header{
      width: 100%;
      display: flex;
      gap: 16px;
      align-items: center;
    }
    &__tooltip {
      display: none;
      position: absolute;
      padding: 4px 8px;
      background: #2A3650;
      border-radius: 8px;
      color: white;
      bottom: 115%;
      height: fit-content;
      width: auto;
      max-width: 450px;
      text-align: center;
      white-space: break-spaces;
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      &:before {
        content: "";
        position: absolute;
        border-width: 5px;
        border-style: solid;
        border-color: #2A3650 transparent transparent;
        top: 100%;
        left: 20%;
      }
    }

    & h2 {
      font-size: 20px;
      font-weight: 600;
      line-height: 28px;
      text-align: left;
      text-overflow: ellipsis;
      max-width: 100%;
      overflow: hidden;
      white-space: nowrap;
      box-sizing: border-box;
      text-wrap: nowrap;
    }
    &__selectBtn {
      width: 24px;
      height: 24px;
      border: none;
      background: none;
      transition: width 1s, height 1s, transform 1s;
    }

    &__modal {
      height: 224px;
      width: 100%;
      overflow: hidden;
      position: absolute;
      display: flex;
      gap: 12px;
      flex-direction: column;
      padding: 16px;
      border-radius: 16px;
      left: 0;
      right: 0;
      top: 40px;
      z-index: 1;
      background: #FFFFFF;
      box-shadow: 0 32px 48px -8px #0000001A,  0 0 14px -4px #0000000D, 0 40px 64px -12px #00000014;
    }
    &__projects {
      pointer-events: all;
      overflow: scroll;
      scrollbar-width: none;
      &__item {
        pointer-events: all;
        display: flex;
        justify-content: space-between;
        padding: 12px;
        gap: 12px;
        border-radius: 12px;
        overflow: scroll;
        scrollbar-width: none;
        &:hover {
          background: #dcf3f8;
        }
      }
      &_bg {
        background: #F4F4F4;
      }
    }
  }
  &__copyBtn {
    display: flex;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    padding: 12px 16px 12px 16px;
    border: 1px solid #0000001A;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: #0052CC;
    margin-left: auto;
    background: #F9F9FB;


  }
  &__copySettings {
    padding: 24px 0 15px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    p {
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
    }
    &__search {
      width: 100%;
      border-radius: 8px;

      & .a-input-img img {
        right: auto;
        left: 15px;
        top: 32%;
      }
      & input {
        background: #fff;
        padding-left: 42px !important;
        &::placeholder {
          color: #767676;
        }
      }
    }

    &__projects {
      height: 160px;
      overflow: scroll;
      display: flex;
      flex-direction: column;
      gap: 8px;
      width: 100%;
      padding-bottom: 12px;
      &__project {
        width: 100%;
        display: flex;
        justify-content: space-between;
        gap: 8px;
        padding: 12px 16px;
        border-radius: 4px;
        background: #2A36500F;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.24px;
      }
    }
    &__btnWrapper {
      display: flex;
      justify-content: space-between;
      gap: 28px;
    }
    &__cancelButton {
      color: #767676;
      background: #EEEEEE;

    }
    &__btnDisabled{
      color: #767676;
      background: #EEEEEE;
    }

  }

  &__projects {
    overflow: scroll;
    display: flex;
    flex-direction: column;
    gap: 12px;

    &__project {
      display: flex;
      flex-direction: column;
      gap: 20px;
      position: relative;
      padding: 14px 20px ;
      background: #FFFFFF;
      border-radius: 8px ;
      border: 1px solid #0000001A;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0.24px;
      &_bg {
        background: #F9F9FB !important;
      }
      & h5, pre  {
        margin-right: auto;
      }
      &__header {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 24px;
        position: relative;
        &_active{
          padding-bottom: 14px;
          border-bottom: 1px solid #0000001A;
        }

      }
      &__info {
        h3 {
          font-size: 18px;
          font-weight: 600;
          line-height: 25.2px;
          letter-spacing: 0.24px;
          text-align: left;
          color: #2A3650;
          margin-bottom: 4px;
        }
        span {
          font-size: 18px;
          font-weight: 400;
          line-height: 25.2px;
          letter-spacing: 0.24px;
          text-align: left;
          color: #767676;

        }
      }
      &__timeScheduleWrapper {
        padding: 10px 20px;
        border-radius: 8px;
        background: #FFFFFF;
        border: 1px solid #0000001A;
        display: flex;
        align-items: center;
        position: relative;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
      }
    }
    &__allowToNoifWrapper {
      display: flex;
      align-items: center;
      gap: 16px;
      padding: 8px 12px 8px 12px;
      border-radius: 6px ;
      background: #F9F9FB;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      &__all {
        border: 1px solid #0000001A;
        padding: 12px 16px;
        background: #F9F9FB;

      }
    }
    &__selectDayBtn {
      display: flex;
      align-items: center;
      padding: 6px;
      border-radius: 6px ;
      background: #F9F9FB;
      border: 1px solid #EAEAEA;
      transition: all 0.3s;
      svg {
        transition: width 1s, height 1s, transform 1s;
      }
      &_rotate {
        transform: rotate(180deg)
      }
    }
  }
  &__timeSelect {
    width: 300px;
    position: relative;
    left: auto;
    top: auto;
    bottom: auto;
    padding: 10px 16px;
    gap: 12px;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #8A909D;
    border: 1px solid #E5E5E5;
    background: #FFFFFF;

    &_active {
      border: 1px solid #0052CC;
    }
    & > button {
      float: right;
    }

    &__optionContainer {
      pointer-events: all;
      max-height: 320px;
      position: absolute;
      display: flex;
      flex-direction: column;
      padding: 16px;
      left: 0;
      right: 0;
      top: 126%;
      z-index: 1;
      overflow: hidden;
      border-radius: 16px;
      background: #FFFFFF;
      box-shadow: 0 32px 48px -8px #0000001A,  0 0 14px -4px #0000000D, 0 40px 64px -12px #00000014;
    }
    &__option{
      overflow: scroll;
      &__item {
        display: flex;
        justify-content: space-between;
        padding: 12px;
        gap: 12px;
        border-radius: 12px;
        &:hover{
          background: #F4F4F4;
        }
      }
    }
    &__addBtn{
      display: flex;
      gap: 12px;
      align-items: center;
      padding: 12px;
      color: #3375D6;

      & svg path{
        stroke: #3375D6;
      }
    }
    &__saveBtn{
      padding: 10px 18px;
      float: right;
      width: auto !important;
    }
  }
  &__customDay{
    pointer-events: all;
    padding-top: 28px;;
    &__inputContainer{
      position: relative;
      margin-bottom: 28px;

      &__incBtn{
        position: absolute;
        right: 20px;
        transform: rotate(-180deg);
        top: 6px;
      }
      &__dicBtn{
        position: absolute;
        right: 20px;
        top: 22px;
      }
    }
  }
}

.rotate  {
  transform: rotate(180deg);

}

.nSettings__projectCollapse:hover {
  .nSettings__projectCollapse__tooltip {
    display: block;
  }
}