.project-modal {
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 555;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    &__content {
        max-width: 725px;
        width: 100%;
        height: max-content;
        padding: 24px 32px;
        background: #FFFFFF;
        border-radius: 16px;
        z-index: 6;
    }

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    &__close {
        cursor: pointer;
    }
    &__title {
        font-weight: 500;
        font-size: 18px;
        line-height: 140%;
        letter-spacing: 0.2px;
        color: #2A3650;
    }

    .pos-left {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    &__fields {
        background: #F9F9FB;
        border-radius: 16px;
        padding: 16px 20px;
        margin-top: 28px;
    }

    &__field {
        display: flex;
        flex-direction: column;
        margin-top: 14px;

        &:first-child {
            margin-top: 0px;
        }

        label {
            font-weight: 400;
            font-size: 16px;
            line-height: 150%;
            color: #2A3650;
        }
        input,
        textarea {
            font-weight: 400;
            font-size: 16px;
            line-height: 150%;
            color: #2A3650;
            padding: 12px;
            background: #FFFFFF;
            border: 1px solid rgba(0, 0, 0, 0.1);
            border-radius: 8px;
            margin-top: 5px;
            transition: all 0.3s;
            outline: none;
            resize: none;
            &:focus {
                border-color: #0052CC;
            }
            &::placeholder {
                color: #8A909D;
            }
        }
    }

    &__block {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 14px;
        .project-modal__field {
            margin-top: 0px;
            width: 49%;
        }
    }

    .css-1okebmr-indicatorSeparator {
        display: none;
    }
    .css-nzna8r-Control {
        height: 50px;
        cursor: pointer;
        border-radius: 8px;
    }
    .css-14el2xx-placeholder,
    .css-qc6sy-singleValue {
        margin-top: -10px;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: #2A3650;
    }

    &__btn {
        font-weight: 600;
        font-size: 16px;
        line-height: 150%;
        text-align: center;
        letter-spacing: 0.3px;
        color: #FFFFFF;
        background: #0052CC;
        border-radius: 8px;
        max-width: 227px;
        width: 100%;
        padding-top: 8px;
        padding-bottom: 8px;
        cursor: pointer;
        transition: all 0.3s;
        margin-top: 28px;

        &:hover {
            transform: translateY(-3px);
        }
        &:active {
            transform: translateY(0px);
        }
    }
    &__bg {
        background-color: rgba(0, 0, 0, 0.5);
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        z-index: 5;
    }
}