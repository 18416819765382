.breadcrumbs {
  &__items {
    display: flex;
    align-items: center;
    gap: 6px;
    &__item{
      display: flex;
      align-items: center;
      gap: 6px;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      text-align: left;
      color: #767676;
      svg {
        width: 18px;
        height: 18px;
      }
    }
    &__active {
      color: #2A3650;
    }
  }
}