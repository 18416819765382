.editReport {
  min-height: 100vh;
  padding: 0 0 0 224px;
  background: #FAFAFB;

  a {
    text-transform: capitalize;
  }

  .summary-sub-item {
   &  p, h1, h2, h3, h4, h5, h6, b, strong, {
     overflow-wrap: anywhere;
   }
     h1 {
      font-size: 36px !important;
      line-height: 100% !important
    }
    h2 {
      font-size: 30px !important;
      line-height: 100% !important;
    }
    h3 {
      font-size: 24px !important;
      line-height: 100% !important;
    }
    a {
      text-decoration: underline !important;
      text-transform: capitalize !important;
      color: #06c;
    }
    p, ol, ul, pre,  blockquote,  h1,  h2,  h3,  h4,  h5, h6 {
      margin: 0 !important;
      //padding: 0 !important;  removed for my editor html text styles
      padding: 0 ;
      counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9 !important;
    }

  }

  td {
    overflow-wrap: anywhere;
  }


  & header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__lastUpdateInfo{
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.24px;
    text-align: left;
    color: #767676;
  }

  & main {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 32px;
  }

  & section, header {
    padding: 24px 32px 24px 32px;
    background: #FFFFFF;
    h4 {
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: 0.24px;
      text-align: left;
      color: #2A3650;
      margin-bottom: 20px;
      text-transform: uppercase;

    }
  }

  &__sectionHeader {
    display: flex;
    gap: 14px;
    align-items: center;
    color: #2A3650;

    & h2 {
      font-size: 20px;
      font-weight: 600;
      line-height: 28px;
      text-align: left;
      margin-right: 12px;
      cursor: pointer;

    }

    h3 {
      font-size: 18px;
      font-weight: 600;
      line-height: 25.2px;
      letter-spacing: 0.24px;
      text-align: left;
    }

    & span {
      display: inline-block;
      background: #6697E0;
      width: 20px;
      height: 2px;
      border-radius: 3px;
      position: relative;
      cursor: pointer;

      &:before {
        content: '';
        position: absolute;
        display: inline-block;
        background: #6697E0;
        width: 20px;
        height: 2px;
        border-radius: 3px;
        transform: rotate(90deg);
        opacity: 0;
        transition: all 0.3s;
      }
    }
    &__moreInfo {
      display: flex;
      align-items: center;
      gap: 10px;
      padding: 4px 8px 4px 8px;
      border-radius: 8px;
      background: #FFFFFF;
      color: #767676;
      border: 1px solid #0000001A;
    }
    &__addRowBtn {
      margin-left: auto;
      display: flex;
      align-items: center;
      gap: 8px;
      font-size: 16px;
      line-height: 24px;
      color: #0052CC;

    }
  }

  &__table {
    display: flex;
    gap: 2px;
    flex-direction: column;
    &__header {
      &:first-child {
        color: #8A909D;
        font-size: 12px;
        font-weight: 400;
        line-height: 19.2px;
        letter-spacing: 0.45px;
        text-align: left;
        text-transform: capitalize;
        &:hover{
          transform: scale(1);
          box-shadow:none;
          filter: none;
          z-index: 1;
        }
      }
      &:hover{
        transform: scale(1);
        box-shadow:none;
        filter: none;
        z-index: 1;
      }
    }

    &__row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 16px 20px 16px 20px;
      gap: 12px;
      border-radius: 8px;
      background: #FFFFFF;
      border: 1px solid #0000001A;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.24px;
      text-align: left;
      color: #2A3650;
      position: relative;
      &:hover {
        z-index: 99;
        box-shadow: -6px -3px 70px -30px rgba(0, 0, 0, .54);
        -webkit-box-shadow: -6px -3px 70px -30px rgba(0, 0, 0, .54);
        -moz-box-shadow: -6px -3px 70px -30px rgba(0, 0, 0, .54);
        -webkit-filter: drop-shadow(0 4px 14px rgba(0, 0, 0, .15));
        filter: drop-shadow(0 4px 14px rgba(0, 0, 0, .15));
        -webkit-transform: scale(1.01);
        transform: scale(1.01);
        .editReport__table__settingsButtons {
          display: flex;
          z-index: -1;
        }
      }

      & span {
        width: 23%;
      }

      &__total {
        background: #F4F5F6;
        font-size: 14px;
        font-weight: 500 !important;
        &:hover{
          transform: scale(1) !important;
          box-shadow:none !important;
          filter: none;
          z-index: 1;
        }
      }
    }
    &__editRow {
      position: relative;
      //flex-wrap: wrap;
      box-shadow: 0 4px 14px 0 #00000026;
      z-index: 999;
      gap: 20px !important;
      & span {
        width: auto;
      }
      .editReport__table__settingsButtons{
        display: flex !important;
      }
      &.add {
        width: 100%;
        border-radius: 4px;
        background-color: #f8f8f8;
      }


    }
    &__settingsButtons {
      display: none;
      position: absolute;
      right: 10px;
      bottom: -26px;
      align-items: center;
      gap: 4px;
      z-index: 2;
      padding-top: 5px;
      &:hover {
        display: flex;
      }
      & button {
        padding: 2px 16px 4px 12px;
        gap: 10px;
        border-radius: 0 0 8px 8px;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.24px;
        border-top: none !important;
      }
    }
    &__cancelBtn {
      background: #F8F8F8;
      border: 1px solid #E5E5E5;
      color: #8A909D ;
    }
    &__saveBtn {
      border:  1px solid #8CD7B4;
      background: #DFF7EC;
      color: #0CAF60;
    }
    &__updateBtn {
      border:  1px solid #b5ccef;
      color: #3375d6;
      background: #f1f7ff;
    }
    &__deleteBtn {
      color: #e52107;
      border:  1px solid #eebdbd;
      background: #fff2f2;
    }
  }

}
.child {
  width: 23%;
}
.editFinancing {

  .a-input input {
    font-size: 14px;
    left: 323px;
    padding: 10px 8px 10px 8px;
    gap: 10px;
    border-radius: 6px;
    background: #F8F8F8;
    border: 1px solid #E5E5E5;
    &::placeholder {
      font-size: 14px;
    }
  }
}
.editItemContainer {
  display: flex;
  gap: 10px;
  flex-direction: column;
  background: #F8F8F8;
  border: 1px solid #E5E5E5;
  box-shadow: 0 16px 48px 0 #00000038;
  position: absolute;
  padding: 10px;
  border-radius: 6px;
  z-index: 99;
  bottom: -100px;
  left: -30px;
  right: -30px;
  &:before {
    content: "";
    position: absolute;
    border-width: 10px;
    border-style: solid;
    top: -20px;
    left: 43%;
    border-color: #F8F8F8 transparent transparent;
    transform: rotate(180deg);
  }

  .editReport__table__applyBtn{
    border: 1px solid #DFDFDF;
    background: #EDEDEE;
    padding: 2px 16px 4px 12px;
    border-radius: 4px;
  }
}

.report__table {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-bottom: 0;
  min-width: fit-content;
  &__header {
    width: 100%;
    tr{
      &:hover{
        transform: scale(1);
        box-shadow:none;
        filter: none;
        z-index: 1;
      }
    }
  }
  &__body{
    display: flex;
    flex-direction: column;
    justify-content: start;
  }

  & th, td {
    display: block;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.45px;
    padding: 16px 20px 16px 20px;
    text-align: left;
    color: #8A909D;

  }
  td {
    color: #2A3650;
    font-size: 14px;
  }
  &__row {
    position: relative;
    display: flex;
    align-items: center;
    border-radius: 8px ;
    border: 1px solid #0000001A;
    background: #FFFFFF;
    transition: all 0.3s;
    &:hover {
      z-index: 99;
      box-shadow: -6px -3px 70px -30px rgba(0, 0, 0, .54);
      -webkit-box-shadow: -6px -3px 70px -30px rgba(0, 0, 0, .54);
      -moz-box-shadow: -6px -3px 70px -30px rgba(0, 0, 0, .54);
      -webkit-filter: drop-shadow(0 4px 14px rgba(0, 0, 0, .15));
      filter: drop-shadow(0 4px 14px rgba(0, 0, 0, .15));
      -webkit-transform: scale(1.01);
      transform: scale(1.01);
      .editReport__table__settingsButtons {
        display: flex;
        z-index: -1;
      }
    }

    &:nth-child(2n) {
      background-color: #F8F8F8;
    }
    &:hover {
      z-index: 20;
      background: #E5EEFA;
      filter: drop-shadow(0px 4px 14px rgba(42, 54, 80, 0.14));

    }
    &:hover .table-actions {
      opacity: 1;
      z-index: 33;
      filter: drop-shadow(0px 4px 14px rgba(0, 0, 0, 0.15));
    }
    &  p, h1, h2, h3, h4, h5, h6, b, strong, {
      overflow-wrap: anywhere;
    }
    h1 {
      font-size: 36px !important;
      line-height: 100% !important
    }
    h2 {
      font-size: 30px !important;
      line-height: 100% !important;
    }
    h3 {
      font-size: 24px !important;
      line-height: 100% !important;
    }
    a {
      text-decoration: underline !important;
      text-transform: capitalize !important;
      color: #06c;
    }
    p, ol, ul, pre,  blockquote,  h1,  h2,  h3,  h4,  h5, h6 {
      margin: 0 !important;
      padding: 0 !important;
      counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9 !important;
    }

  }
}

.subSectionTableContainer {
  width: 100%;
  background: #F8F8F8;
  padding: 16px 20px 16px 20px;
  border-radius: 12px;
  overflow: hidden;

  ul {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-bottom: 16px;
    li {
      list-style: disc;
      margin-right: 10px;
      font-size: 14px;
      font-weight: 500;
      line-height: 21px;
      letter-spacing: 0.24px;
      text-align: left;
      text-transform: uppercase;
      &:first-child {
        list-style: none;
      }
    }
  }
  &__tableContainer{
    //overflow: auto;
  }
}

.editReport__section_hidden {
  height: 82px;
  overflow: hidden;
  padding: 24px 32px;
  .editReport__sectionHeader {
    span {
      position: relative;
      &:before {
        opacity: 1;
      }
    }
    .editReport__sectionHeader__addRowBtn {
      opacity: 0;
      display: none;
    }
  }
}

.editReport {
  &__fieldWrapper {
    display: flex;
    flex-wrap: wrap;
    row-gap: 30px;
    gap: 20px;
    &.add {
      flex-wrap: nowrap;
      border-radius: 4px;
      background-color: #f8f8f8;
    }
  }
  &__inputWrapper {
    display: flex;
    flex-direction: column;
    gap: 5px;
    label {
      color: #8A909D;
      font-size: 12px;
      font-weight: 400;
      line-height: 19.2px;
      letter-spacing: 0.45px;

    }

    .react-datepicker__input-container input{
      background: #F8F8F8;
      color: #2A3650;
      border: 1px solid #E5E5E5 !important;
      padding: 10px 8px 10px 8px;
      border-radius: 6px;
    }
  }

}


.sitePhtographsContainer {
  & .project__white-box {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    margin-top: 0 !important;
  }
}


@media (max-width: 960px) {
  .table-container {
    overflow-x: scroll;
  }
  .report__table {
    min-width: 1000px;
    overflow: hidden;
     }
  .report__table__row{
    min-width: 1000px;
  }
}
