.paginate {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;

    &__container {
        // border: 1px solid red;
        display: flex;
        align-items: center;
        justify-content: space-between;
        li {
            list-style: none;
            width: 40px;
            height: 40px;
            cursor: pointer;
            font-weight: 400;
            font-size: 14px;
            line-height: 160%;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #8D8E9C;

            &.selected {
                background: rgba(62, 119, 255, 0.1);
                border-radius: 12px;
                font-weight: 700;
                color: #3E77FF;
            }
            &.previous,
            &.next {
                a {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
            &.next {
                a {
                    img {
                        margin-top: -2px;
                        transform: rotate(180deg);
                    }
                }
            }
        }
    }
}
