.notification{
  padding: 0 0 0 64px;
   color: #2A3650;

  &__header{
    height: 95px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #E0E0E2;
    background: #FFFFFF;
    padding: 0 25px;

    &__leftWrapper, &__rightWrapper{
      display: flex;
      align-items: center;
      gap: 16px;
    }

    & h1{
      font-size: 24px;
      font-weight: 700;
      line-height: 31.2px;
      text-align: left;
    }

    h4{
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0.24px;
      text-align: left;

    }
    &__moreInfo{
      display: flex;
      align-items: center;
      padding: 4px 8px 4px 8px;
      gap: 10px;
      border-radius: 8px;
      border: 1px solid #0000001A;

      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      text-align: left;
      color: #767676;

    }
  }
  &__content{
    height: calc(100vh - 95px);
    background: #F9F9FB;
    padding: 25px;
    display: flex;

    &__disableContent{
      margin: auto;
      width: 32%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      gap: 24px;
      background: #F9F9FB;
      img{
        width: 205px;
        height: auto;
      }
     & p{
       font-size: 18px;
       font-weight: 400;
       line-height: 25.2px;
       letter-spacing: 0.24px;
       text-align: center;
      }
    }
  }
}