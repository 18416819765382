.project__program-right {
  width: 100% !important;
}
.green {
  background: #0CAF60;
}
.yellow {
  background: #ECC949;
}
.blue {
  background: #3375D6;
}
.red {
  background: #E68484;
}
.orange {
  background: #E7984F;
}
