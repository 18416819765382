.project__block-modal {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 6666;

    &-content {
        background: #FFFFFF;
        border-radius: 16px;
        z-index: 2;
        width: 100%;
        max-width: 550px;
        padding: 24px 32px;
    }
    &-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 20px;
        border-bottom: 1px solid #CCDCF5;
        margin-bottom: 20px;
    }
    &-title {
        font-weight: 500;
        font-size: 18px;
        line-height: 140%;
        display: flex;
        align-items: center;
        letter-spacing: 0.2px;
        color: #2A3650;
    }
    &-close {
        cursor: pointer;
    }
    &-text {
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        display: flex;
        align-items: center;
        color: #2A3650;
        margin-bottom: 32px;
    }
    &-btns {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &-cancel,
    &-delete {
        max-width: 230px;
        width: 100%;
        font-weight: 500;
        font-size: 14px;
        line-height: 150%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        letter-spacing: 0.2px;
        color: #767676;
        border-radius: 8px;
        padding-top: 11.5px;
        padding-bottom: 11.5px;
        text-transform: capitalize;
        cursor: pointer;
    }
    &-cancel {
        background: #EEEEEE;
        color:  #767676;
    }
    &-delete {
        background: #FEE6E6;
        color: #FD6A6A;
    }

    &-bg {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0, 0.6);
        z-index: -1;
    }
}