
.plannedProject {
  margin-top: 20px;

  &__title {
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: 0.2px;
    text-transform: uppercase;
    color: #2A3650;
    margin-bottom: 13px;
  }
  &__table {
    display: flex;
    flex-direction: column;
    grid-gap: 2px;
    width: 100%;
    margin-bottom: 13px;
    &-months {
    }
    &-monthsBox {
      height: 52px;
      width: 190px;
      background: #FFFFFF;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 8px 0px 0px 8px;
      display: flex;
      align-items: center;
      padding-left: 16px;
      position: relative;
      .lengthy {
        position: absolute;
        padding: 4px 8px;
        background: #767676;
        border-radius: 8px;
        color: white;
        transform: translateY(-100%);
        width: 100%;
        text-align: center;
        &:before {
          content: "";
          position: absolute;
          top: 99%;
          left: 43%;
          border-width: 5px;
          border-style: solid;
          border-color: #767676 transparent transparent;
        }
      }
      .not-lengthy {
        position: absolute;
        padding: 4px 8px;
        background: #767676;
        border-radius: 8px;
        color: white;
        transform: translateY(-100%);
        width: 100%;
        left: 0;
        text-align: center;
        &:before {
          content: "";
          position: absolute;
          top: 98%;
          left: 43%;
          border-width: 5px;
          border-style: solid;
          border-color: #767676 transparent transparent;
        }
      }
      .project-table__th:hover {
        .project-table__th span {
          display: block;
        }
      }
      &:not(:first-child) {
        margin-top: 2px;
      }
      div {
        width: 14px;
        height: 14px;
        border-radius: 2px;
        margin-right: 8px;
      }
      .blue {
        background: #3375D6;
      }
      .orange {
        background: #D69F33;
      }
      span {
        color: #8A909D;
        font-weight: 400;
        font-size: 12px;
        line-height: 160%;
        letter-spacing: 0.4px;
      }
    }
    .progressChart {
      width: 190px;
      font-size: 14px;
      .red {
        background: #E68484;
      }
      .lightblue {
        background: #99BAEB;
      }
      .blue {
        background: #3375D6;
      }
      .yellow {
        background: #FFD023;
      }
      .red {
        background: #E68484;
      }
      .orange {
        background: #E7984F;
      }
    }
    &-editBtn {
      button {
        background: #EDEDEE;
        border: 1px solid #DFDFDF;
        border-radius: 4px;
        padding-top: 4px;
        padding-bottom: 4px;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.2px;
        color: #2A3650;
      }
    }
    &-row {
      display: flex;
      align-items: center;
      overflow-y: hidden;
      min-height: 210px;
      padding-bottom: 55px;
      //&.active {
      //  overflow-x: scroll;
      //  padding-bottom: 130px;
      //}
    }
    &-block {
      background: #FFFFFF;
      border: 1px solid rgba(0, 0, 0, 0.1);
      font-weight: 400;
      font-size: 12px;
      line-height: 160%;
      letter-spacing: 0.4px;
      color: #8A909D;
      width: 100%;
      padding: 16px;
      display: flex;
      align-items: center;
      position: relative;
      &_disable {
        background: #F8F8F8;
        font-weight: 400;
        font-size: 12px;
        line-height: 160%;
        letter-spacing: 0.4px;
        color: #767676;
      }
      &_normal {
        font-weight: 400;
        font-size: 12px;
        line-height: 160%;
        letter-spacing: 0.4px;
        color: #2A3650;
        cursor: pointer;
      }
      &:first-child {
        border-radius: 8px 0px 0px 8px;
      }
      &_title {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.2px;
        color: #2A3650;
      }
    }
    &-color {
      margin-right: 8px;
      width: 14px;
      height: 14px;
      border-radius: 2px;
      display: inline-block;
    }
  }
  &__total-block {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 4px;
    padding: 12px 16px;
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    position: relative;
    span {
      font-size: 12px;
    }
    &:last-child {
      margin-bottom: 12px;
    }
    .lengthy {
      position: absolute;
      padding: 4px 8px;
      background: #767676;
      border-radius: 8px;
      color: white;
      transform: translateY(-100%);
      width: 20%;
      text-align: center;
      &:before {
        content: "";
        position: absolute;
        top: 99%;
        left: 43%;
        border-width: 5px;
        border-style: solid;
        border-color: #767676 transparent transparent;
      }
    }
    .not-lengthy {
      position: absolute;
      padding: 4px 8px;
      background: #767676;
      border-radius: 8px;
      color: white;
      transform: translateY(-100%);
      width: 20%;
      left: 0;
      text-align: center;
      &:before {
        content: "";
        position: absolute;
        top: 98%;
        left: 43%;
        border-width: 5px;
        border-style: solid;
        border-color: #767676 transparent transparent;
      }
    }
  }
  &__total-text {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: #2A3650;
  }

  &__charts-wrapp{
    display: flex;
    justify-content: space-between;
  }

  &__chart {
    margin-top: 20px;
    position: relative;
    h2 {
      position: absolute;
      font-weight: 500;
      font-size: 17px;
      line-height: 150%;
      letter-spacing: 0.2px;
      color: #2A3650;
      margin-bottom: 20px;
      left: -45px;
      top: 50%;
      transform: rotate(-90deg);
    }
    width: 100%;
    background: #F8F8F8;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    padding: 24px 20px;
    &-title {
      font-weight: 500;
      font-size: 16px;
      line-height: 150%;
      letter-spacing: 0.2px;
      color: #2A3650;
      margin-bottom: 20px;
    }
  }
  &__month {
    display: flex;
    flex-direction: column;
    &.screenshot {
      width: 100px;
    }
    &-box {
      height: 52px;
      width: 127px;
      background: #FFFFFF;
      border: 1px solid rgba(0, 0, 0, 0.1);
      font-weight: 400;
      font-size: 12px;
      line-height: 160%;
      letter-spacing: 0.4px;
      color: #8A909D;
      padding: 16px;
      display: flex;
      align-items: center;
      position: relative;
      &.screenshot {
        width: 100px;
      }
      &.edit {
          -webkit-box-shadow: -6px -3px 70px -30px rgba(0,0,0,0.54);
          -moz-box-shadow: -6px -3px 70px -30px rgba(0,0,0,0.54);
          filter: drop-shadow(0px 4px 14px rgba(0, 0, 0, 0.15));
          z-index: 5;
        .project__block-btns {
          display: flex;
          z-index: 55;
        }
      }
      &_disable {
        background: #F8F8F8;
        font-weight: 400;
        font-size: 12px;
        line-height: 160%;
        letter-spacing: 0.4px;
        color: #767676;
      }
      &_normal {
        font-weight: 400;
        font-size: 12px;
        line-height: 160%;
        letter-spacing: 0.4px;
        color: #2A3650;
      }
      &-edit {
        position: absolute;
        background: #F8F8F8;
        border: 1px solid #E5E5E5;
        border-radius: 6px;
        display: flex;
        flex-direction: column;
        grid-gap: 10px;
        padding: 10px;
        bottom: -111px;
        z-index: 10;
        left: -11px;
        .a-input {
          label {
            display: none;
            top: 25%;
          }
        }
        &:before {
          position: absolute;
          content: '';
          border-radius: 2px;
          border-width: 8px;
          border-style: solid;
          border-color: #E5E5E5 transparent transparent;
          transform: translateX(-50%);
          transform: rotate(180deg);
          top: -16px;
          left: 50%;
          z-index: 20;
        }
      }
      &:not(:first-child) {
        margin-top: 2px;
      }
    }
  }
  &__placeholder {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 0 auto;
    img {
      margin-bottom: 25px;
      cursor: default;
    }
  }
}

.recharts-default-legend {
  position: relative;
  top: -50px;
}
