.contract__management {
  &-title {
    display: flex;
    color: var(--dark-100, #2a3650);
    font-family: Rubik;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 28px */
    margin-bottom: 22px;
  }
  &-tabs {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background: #fff;
    padding: 6px 5px;
  }
  &-tab {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--text-grey, #8c8785);
    font-family: Rubik;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 112.5% */
    width: 50%;
    padding: 12px;
    cursor: pointer;
    transition: all 0.3s;
    &.active {
      border-radius: 6px;
      background: #0052cc;
      box-shadow: 0px 2px 4px 0px rgba(17, 24, 39, 0.04);
      color: #fff;
    }
  }
  &-form,
  &-templates {
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background: #fff;
    padding: 20px;
    margin-top: 16px;
    max-height: 68vh;
    overflow: scroll;
  }
  &-fill {
    position: relative;
    padding: 12px;
    border-radius: 8px;
    background: #f9f9fb;
    margin-bottom: 12px;
    &:last-child {
      margin-bottom: 0px;
    }
    input {
      position: absolute;
      top: 0px;
      left: 0px;
      opacity: 0;
      z-index: -1;
    }
    input:checked + label {
      div {
        background-color: #0052cc;
        border-color: #0052cc;
      }
    }
    label {
      display: flex;
      align-items: center;
      cursor: pointer;
      div {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20px;
        height: 20px;
        border: 1px solid #8a909d;
        margin-right: 10px;
        border-radius: 4.5px;
        transition: all 0.3s;
      }
      span {
        display: block;
        color: var(--dark-100, #2a3650);
        font-family: Rubik;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 24px */
      }
    }
  }
  .df {
    display: flex;
  }
  .end {
    justify-content: flex-end;
  }
  &-button {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 200px;
    width: 100%;
    border-radius: 8px;
    background: var(--primary-100, #0052cc);
    padding: 8px 20px;
    color: var(--others-white, #fff);
    text-align: center;
    font-family: Rubik;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
    letter-spacing: 0.3px;
    margin-top: 36px;
    cursor: pointer;
    transition: all 0.3s;
    &:hover {
      transform: translateY(-3px);
    }
    &:active {
      transform: translateY(0px);
    }
    &:disabled {
      background: var(--primary, #8a909d);
      &:hover {
        transform: translateY(0px);
      }
    }
    img {
      margin-right: 8px;
    }
  }
  &-template {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    border-radius: 8px;
    background: #f9f9fb;
    padding: 16px;

    .left {
      display: flex;
      align-items: center;
      width: 80%;
      .template__image,
      img {
        width: 44px;
        height: 44px;
      }
    }
    .right {
      display: flex;
      align-items: center;
      width: 20%;
      justify-content: flex-end;
      a {
        display: block;
        width: 40px;
        height: 40px;
      }
      img {
        width: 40px;
        height: 40px;
        margin-right: 10px;
        &:last-child {
          margin-right: 0px;
        }
      }
    }

    .template {
      &__image {
        margin-right: 16px;
      }
      &__title {
        color: var(--dark-100, #2a3650);
        font-family: Rubik;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%; /* 24px */
        letter-spacing: 0.2px;
      }
      &__descr {
        color: var(--dark-100, #2a3650);
        font-family: Rubik;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
        letter-spacing: 0.2px;
        width: 90%;
      }
      &__info {
        width: 90%;
      }
      &__create {
        display: flex;
        &-icon {
        }
        &-text {
        }
      }
    }
    &-create {
      display: flex;
      align-items: center;
      border-radius: 8px;
      background: linear-gradient(
          0deg,
          rgba(0, 82, 204, 0.08) 0%,
          rgba(0, 82, 204, 0.08) 100%
        ),
        #fff;
      padding: 16px;
      .template__create-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 44px;
        height: 44px;
        border-radius: 5px;
        background: #fff;
        cursor: pointer;
        margin-right: 16px;
      }
      .template__create-text {
        color: var(--primary-100, #0052cc);
        font-family: Rubik;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%; /* 24px */
        letter-spacing: 0.2px;
      }
    }
  }

  .template-modal {
    display: flex;
    justify-content: center;
    padding-top: 50px;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100vh;
    z-index: 55;
    &__content {
      padding: 24px 32px 32px 32px;
      z-index: 4;
      max-width: 708px;
      width: 100%;
      border-radius: 16px;
      background: #fff;
      overflow-y: scroll;
      max-height: 705px;
    }
    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &__title {
      color: var(--dark-100, #2a3650);
      font-family: Rubik;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%; /* 25.2px */
      letter-spacing: 0.2px;
    }
    &__hr {
      width: 100%;
      background-color: #ccdcf5;
      height: 1px;
      width: 100%;
      opacity: 0.5;
      margin-top: 20px;
    }

    &__field {
      &:nth-child(3) {
        margin-top: 24px;
      }
      &:nth-child(4) {
        margin-top: 20px;
      }
    }
    &__label {
      color: var(--dark-100, #2a3650);
      font-family: Rubik;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 24px */
    }
    &__input,
    &__textarea {
      border-radius: 8px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      background: #fff;
      width: 100%;
      outline: none;
      padding: 14px 16px;
      box-sizing: border-box;
      font-family: Rubik;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 24px */
      color: var(--dark-100, #2a3650);
      margin-top: 10px;
      transition: all 0.3s;
      &:focus {
        border-color: #0052cc;
      }
      &::placeholder {
        color: var(--primary, #8a909d);
      }
    }
    &__textarea {
      height: 124px;
    }
    &__uploader {
      display: flex;
      align-items: center;
      flex-direction: column;
      margin-top: 20px;
      border-radius: 12px;
      border: 1px dashed rgba(0, 0, 0, 0.1);
      background: var(--others-white, #fff);
      position: relative;
      input {
        position: absolute;
        top: 0px;
        left: 0px;
        opacity: 0;
        z-index: -1;
      }
      label {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        padding-top: 48px;
        padding-bottom: 48px;
        span {
          display: block;
          color: var(--primary-100, #0052cc);
          text-align: center;
          font-family: Rubik;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 150%; /* 21px */
          letter-spacing: 0.2px;
          margin-top: 16px;
          &:last-child {
            color: var(--grey-100, #8d8d9d);
            margin-top: 0px;
          }
        }
      }
    }
    &__p {
      color: var(--primary, #8a909d);
      font-family: Rubik;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
      letter-spacing: 0.2px;
      margin-top: 8px;
    }

    &__file {
      border-radius: 8px;
      background: #f9f9fb;
      padding: 12px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 20px;
      div {
        display: flex;
        align-items: center;
        div {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          span {
            &:first-child {
              color: var(--dark-100, #23262f);
              font-family: Rubik;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 150%; /* 21px */
              letter-spacing: 0.2px;
            }
            &:last-child {
              color: var(--grey-100, #8d8d9d);
              font-family: Rubik;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 20px; /* 142.857% */
              letter-spacing: 0.2px;
            }
          }
        }
        span {
          display: flex;
          flex-direction: row;
          margin-right: 16px;
        }
      }
    }
    &__btns {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 24px;
    }

    &__cancel,
    &__save {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      text-align: center;
      font-family: Rubik;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%; /* 21px */
      letter-spacing: 0.2px;
      width: 47%;
      padding: 8px;
      border-radius: 8px;
      cursor: pointer;
      transition: all 0.3s;
      &:hover {
        transform: translateY(-3px);
      }
      &:active {
        transform: translateY(0px);
      }
    }
    &__cancel {
      background: var(--background, #eee);
      color: var(--text-subtle, #767676);
    }
    &__save {
      background: #0052cc;
    }
    &__bg {
      position: fixed;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100vh;
      background-color: rgba(0, 0, 0, 0.6);
      z-index: -1;
    }
  }

  .document-row{
    margin-top: 15px;
  }
}
