.listOfTable {
  &__subSections {
    display: flex;
    flex-direction: column;
    gap: 20px;
    &  h3 {
      margin-bottom: 0;
    }
  }
  &__monitoringContainer{
    h2 {
      font-size: 14px;
      font-weight: 500;
      line-height: 21px;
      letter-spacing: 0.2px;
      text-align: left;
      color: #2A3650;
      text-transform: uppercase;
      margin: 10px 0;
    }
  }
}

.progress-table {
  color: #2A3650;
  overflow: visible;
  .subSectionTableContainer__tableContainer {
    overflow: hidden;
  }

  table {
    overflow: auto;
  }

  table, tbody,thead, tfoot{
    display: flex;
    flex-direction: column;
    gap: 2px;
    width: 100%;
  }
  tfoot tr {
    width: calc(50% - 6px);
  }
  tr {
    display: flex;
    align-content: center;
    width: fit-content;
    border: 1px solid #0000001A;
    border-radius: 8px;
    background: #FFFFFF;

  }
  th, td {
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: center;
    border-right: 1px solid #ddd;
    padding: 16px 20.5px 16px 12.5px;
    font-size: 14px;
    font-weight: 400;
    &:last-child{
      border-right: none;
    }
  }

  th {
    font-size: 12px;
    font-weight: 400;
    line-height: 19.2px;
    letter-spacing: 0.45px;
    text-align: left;
    color: #8A909D;
  }

  .planned-value, .actual-value {
    display: block;
    min-width: 14px;
    width: 14px;
    height: 14px;
    border-radius: 4px;
    margin-right: 4px;

  }
  .planned-value {
    background: #1e88e5;
  }

  .actual-value {
    background: #fbc02d;
  }


  tfoot {
    padding-top: 10px;
    flex-direction: row;
    gap: 12px;
    tr {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: bold;
      border: 1px solid #0000001A;
      &:first-child {
        background: #3375D60F;
      }
      &:nth-child(2) {
        background: #FFD0230F;

      }
    }
    td {
      border-right: none;
    }
  }

  tfoot .total {
    text-align: right;
    float: right;
    padding-right: 16px;
  }

  .planned-total {
    color: #1e88e5;
  }

  .actual-total {
    color: #fbc02d;
  }

}

.riskRegisterOfContracts {
  .table-container {
    margin-bottom: 10px;
    width: 100%;
    max-width: 100%;
    overflow: auto;
  }
  .report__table__row th {
    width: 15%;
    min-width:  140px;

  }
  .report__table__row th:nth-child(1) {
    width: 8%;
    min-width:  50px;
  }
  .report__table__row th:nth-child(3) {
    width: 10%
  }


  .report__table__row td {
    width: 15%;
    min-width:  140px;
  }
  .report__table__row td:nth-child(1) {
    width: 8%;
    min-width:  50px;
  }
  .report__table__row td:nth-child(3) {
    width: 10%
  }

  &__levelBtn {
    width: 100%;
    padding: 8px;
    gap: 10px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0.24px;
    text-align: left;
  }

}

.risk-level-monitoring {
  display: flex;
  flex-direction: column;
  padding: 16px 20px 20px 20px;
  gap: 12px;
  border-radius: 8px;
  background: #FFFFFF;
  border: 1px solid #0000001A;
  color: #2A3650;
}

.risk-level-header {
  margin-bottom: 20px;
   h3 {
     font-size: 14px;
     font-weight: 500;
     line-height: 21px;
     text-transform: uppercase;
   }
}

.risk-level-content {
  display: flex;
  align-items: center;
  gap: 20px;
}

.risk-level-chart {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #0000001A;
  background: #F8F8F8;
  width: 204px;
  height:204px;
  padding: 16px;
  border-radius: 6px;
}

.risk-level-cards {
  width: -webkit-fill-available;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 20px;
}

.risk-level-card {
  width: 47%;
  padding: 16px 24px 16px 24px;
  gap: 8px;
  border-radius: 6px;
  background: #FFFFFF;
  border: 1px solid #0000001A;
  p {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
}

.risk-level-color {
  width: 14px;
  height: 14px;
  border-radius: 4px;
}

.risk-level-info {
  display: flex;
  align-items: center;
  gap: 10px;
}

.risk-level-name {
  font-weight: 400;
}

.risk-level-value {
  font-weight: 500 !important;
}