.quarterly-online {
	width: 100%;
	padding-left: 250px;
	padding-right: 50px;
	padding-top: 20px;
	padding-bottom: 150px;
	margin: 0 auto;
}

.quarterly-online__container {
	position: relative;
}

.quarterly-online__ref {
	border: 1px solid red;
	position: absolute;
	top: -100px;
	left: 50%;
	width: 100%;
	height: calc(100% + 100px);
	transform: translateX(-50%);
	z-index: -1;
}

.quarterly-online-table {
	overflow-x: scroll;
	table {
		min-width: 800px;
	}
	th,td {
		padding: 16px 20.5px 16px 12.5px !important;
		font-size: 12px;
		line-height: 19.2px;
		letter-spacing: 0.5px;
		text-align: left;
		vertical-align: top;
		font-weight: 400;
		border: 1px solid #0000001A;
		min-width: 130px;

		li{
			margin-left: 15px;
		}
	}

	.row_gray {
		background: #F4F5F6;
		th {
			font-weight: bold;
		}
	}

	.cube_blue,
	.cube_yellow{
		display: inline-block;
		border-radius: 2px;
		height: 14px;
		margin-right: 8px;
		width: 14px;
	}
	.cube_blue {
		background: #3375d6;
	}
	.cube_yellow {
		background: #ffd023;
	}


}

.active-scroll-spy {
	background-color: yellowgreen;
	border-radius: 15px;
}

.p-text {
		font-size: 12px;
		line-height: 19.2px;
		letter-spacing: 0.5px;
		text-align: left;
		font-weight: 400;
}


.online__content-projectText span {
}
