.a-checkbox {
    position: relative;
    input {
        position: absolute;
        opacity: 0;
        top: -20px;
        left: -20px;
        z-index: -222;
    }

    label {
        font-weight: 400;
        font-size: 16px;
        line-height: 16px;
        color: #111827;
        padding-left: 32px;
        cursor: pointer;
        position: relative;

        &::after {
            content: '';
            position: absolute;
            top: -2px;
            left: 0px;
            width: 20px;
            height: 20px;
            border: 1px solid rgba(138, 144, 157, 0.6);
            border-radius: 5px;
            background-color: #fff;
            transition: all 0.3s;
        }
        &::before {
            content: '';
            position: absolute;
            top: 3px;
            left: 2px;
            width: 15px;
            height: 10px;
            background-image: url('./checkbox-arrow.svg');
            background-size: cover;
            z-index: 1;
            opacity: 0;
            transition: all 0.3s;
        }
    }

    input:checked + label:after {
        background-color: #0052CC;
        border-color: #0052CC;
    }
    input:checked + label:before {
        opacity: 1;
    }
}