.reportModal {
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 555;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &__content {
    max-width: 550px;
    width: 100%;
    background: #FFFFFF;
    border-radius: 16px;
    z-index: 6;
    padding: 24px 32px;
  }
  &__title {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 140%;
    color: #2A3650;
  }
  &__attention {
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    display: flex;
    align-items: center;
    color: #FD6A6A;
    background: rgba(253, 106, 106, 0.1);
    border-radius: 16px;
    padding: 8px 20px;
    margin: 20px 0;
  }
  &__submitting {
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    margin-bottom: 12px;
    color: #2A3650;
  }
  &__subtitle {
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    margin-bottom: 12px;
    color: #2A3650;
    position: relative;
    margin-left: 15px;
    &:before {
      content: "";
      height: 3px;
      width: 3px;
      position: absolute;
      left: -13px;
      top: 10px;
      border-radius: 50%;
      background: #000000;
    }
  }
  &__list {

  }
  &__btns {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
  }
  &__btn {
    width: 229px;
    height: 44px;
    background: rgba(204, 220, 245, 0.5);
    font-weight: 500;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 150%;
    letter-spacing: 0.2px;
    color: #3375D6;
    border-radius: 8px;
    cursor: pointer;
  }
  .cancel {
    background: #EEEEEE;
    color: #767676;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__bg {
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 5;
  }
}
img  {
  cursor: pointer;
}