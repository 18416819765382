.PCWADP {
  display: flex;
  flex-direction: column;
  gap: 16px;
  .custom-table tr  {
    margin-bottom: 4px;
    & th:first-child, & td:first-child  {
      min-width: 250px !important;
    }
    & th:nth-child(2), & td:nth-child(2)  {
      width: 230px !important;
      min-width: 230px;
      max-width: 250px;
    }
    & th:last-child, & td:last-child  {
      background: #F4F5F6;
      font-size: 12px;
      font-weight: 500;
      line-height: 19px;
      letter-spacing: 0.4px;
      text-align: left;
      color: #2A3650;

    }
  }
}