.create {
    &__box {
        background: #FFFFFF;
        border: 1px solid #E5E5E5;
        border-radius: 8px;
        margin-bottom: 50px;
    }
    &__title {
        font-weight: 600;
        font-size: 20px;
        line-height: 140%;
        color: #2A3650;
        margin-bottom: 20px;
        padding: 20px 24px;

    }
    &__tabs {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 90px;
        background: #F9F9FB;
        padding: 20px 24px;
    }
    &__tab {
        display: flex;
        align-items: center;
        width: 33%;
        height: 50px;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #2A3650;
        padding-left: 30px;
        background: #EEEEF1;
        border-radius: 4px;

        &.active {
            background: #3375D6;
            color: #FFFFFF;
        }

        &.one {
            clip-path: polygon(96% 0, 100% 50%, 96% 100%, 0% 100%, 0% 50%, 0% 0%);

        }
        &.two {
            clip-path: polygon(96% 0, 100% 50%, 96% 100%, 0% 100%, 3% 50%, 0% 0%);
        }
        &.three {
            clip-path: polygon(100% 0, 100% 50%, 100% 100%, 0% 100%, 3% 51%, 0% 0%);
        }
    }

    &__content {
        margin-top: 22px;
        padding-top: 0;
    }
    &__subTitle{
        width: 100%;
        padding-left: 30px;
        color: #8a8a8a;
    }
    &__form {
        width: 100%;
        &-content {
            padding: 0 24px 20px;
        }
        &-subtitle {
            font-weight: 500;
            font-size: 16px;
            line-height: 150%;
            letter-spacing: 0.2px;
            color: #2A3650;
            margin-bottom: 12px;
        }
        &-row {
            display: flex;
            align-items: center;
            width: 100%;
            margin-top: 16px;

            .a-checkbox {
                margin-right: 15px;
                width: 240px;
            }
        }
        &-label {
            font-weight: 400;
            font-size: 16px;
            line-height: 150%;
            color: #2A3650;
            width: 18%;
            margin-right: 10px;
        }
        &-input {
            width: 82%;
            .react-datepicker-wrapper {
                width: 100% !important;
            }
            &.amount {
                display: flex;
                align-items: center;
                div {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 20px;
                    letter-spacing: 0.2px;
                    color: #8A909D;
                    width: 56px;
                    height: 42px;
                    background: #F9F9FB;
                    border: 1px solid #E0E0E2;
                    border-radius: 8px 0 0 8px;
                }
                input {
                    border-radius: 0 8px 8px 0;
                }
            }
        }
        input {
            padding: 8px;
            font-weight: 400;
            font-size: 16px;
            line-height: 150%;
            color: #2A3650;
            background: #F9F9FB;
            border: 1px solid rgba(0, 0, 0, 0.1);
            border-radius: 8px ;
            width: 100%;
            &::placeholder {
                color: #8A909D;
            }
        }
        textarea {
            padding: 8px;
            font-weight: 400;
            font-size: 16px;
            line-height: 150%;
            color: #2A3650;
            background: #F9F9FB;
            border: 1px solid rgba(0, 0, 0, 0.1);
            border-radius: 8px ;
            width: 100%;
            &::placeholder {
                color: #8A909D;
            }
        }
        &-br {
            background-color: #E5E5E5;
            width: 100%;
            height: 1px;
            margin-top: 36px;
            margin-bottom: 24px;
        }
        &-btns {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 24px 24px;
        }
        &-back {
            font-weight: 500;
            font-size: 16px;
            line-height: 150%;
            letter-spacing: 0.2px;
            color: #8A909D;
            padding: 11px 45px;
            cursor: pointer;
            background: #F9F9FB;
            border: 1px solid #E5E5E5;
            border-radius: 8px;
            transition: all 0.3s;

            &:hover {
                transform: translateY(-3px);
            }
            &:active {
                transform: translateY(0px);
            }
        }
        &-next {
            display: flex;
            align-items: center;
            font-weight: 500;
            font-size: 16px;
            line-height: 150%;
            letter-spacing: 0.2px;
            color: #FFFFFF;
            padding: 11px 30px;
            background: #3375D6;
            border-radius: 8px;
            transition: all 0.3s;
            cursor: pointer;

            img {
                margin-top: 2px;
            }
            &:hover {
                transform: translateY(-3px);
            }
            &:active {
                transform: translateY(0px);
            }
        }
        .calendar {
            position: relative;
            input {
                padding: 8px !important;
                font-weight: 400 !important;
                font-size: 16px !important;
                line-height: 150% !important;
                color: #2A3650 !important;
                background: #F9F9FB !important;
                border: 1px solid rgba(0, 0, 0, 0.1) !important;
                border-radius: 8px !important ;
                width: 100% !important;
                &::placeholder {
                    color: #8A909D;
                }
            }
            img {
                position: absolute;
                top: 50%;
                right: 10px;
                transform: translateY(-50%);
            }
            label {
                display: flex;
                align-items: center;
                padding-left: 16px;
                padding-top: 3px;
                position: absolute;
                top: 3px;
                left: 1px;
                width: 90%;
                height: 38px;
                background: #F9F9FB;
                border-radius: 8px;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                letter-spacing: 0.2px;
                color: #2A3650;
                cursor: pointer;
            }
        }
    }
}