.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 55;
  display: flex;
  align-items: center;
  justify-content: center;

  &-content {
    background: #ffffff;
    border-radius: 16px;
    max-width: 550px;
    width: 100%;
    padding: 24px 32px;

    h5 {
      font-weight: 500;
      font-size: 18px;
      line-height: 140%;
      letter-spacing: 0.2px;
      color: #2a3650;
    }
  }
  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #ccdcf5;
    padding-bottom: 20px;
  }
  &-close {
    cursor: pointer;
  }
  &-mainContainer{
    & .a-input{
      margin-bottom: 20px;
    }
  }
  &-text {
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #2a3650;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  &-extraInfoText{
    font-size: 18px;
    color: #2a3650;
    margin-bottom: 28px;
  }

  &-btns {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 32px;
  }
  &-btnDisable{
    background: #8A909D;
  }
  &-cancel,
  &-sure {
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    letter-spacing: 0.2px;
    color: #767676;
    max-width: 229px;
    width: 100%;
    padding-top: 11.5px;
    padding-bottom: 11.5px;
    background: #eeeeee;
    border-radius: 8px;
    cursor: pointer;
  }

  &-sure {
    color: #fd6a6a;
    background: #fee6e6;
  }
  &-resend, &-timer{
    color: #0066FF;
    margin-bottom: 32px;
  }

  &-timer {
    float: right;
  }

  &-bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-color: rgba(0, 0, 0, 0.4);
  }
}

.qrContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  color: #2A3650;
  &__qrImg {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 240px;
    height: 228px;
    background: #F4F5F6;
  }
  &__qrKeyWrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    margin-bottom: 20px;
  }
  &__key {
    font-size: 18px;
    font-weight: 600;
    line-height: 25.2px;
    letter-spacing: 0.2px;

  }
  &__copied{
    position: absolute;
    right: 50px;
    bottom: 40px;
    font-size: 14px;
    font-weight: 400;

  }

  & button {
    width: 20px;
    height: 20px;
    & img{
      width: 100%;
      height: auto;
    }
  }
}
