.online {
  padding-left: 100px;
  padding-top: 20px;
  max-width: 1280px;
  margin: 0 auto;
  padding-bottom: 150px;
  margin-left: 140px;
}
.dpFlex{
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 24px;

}
.online__top {
  display: flex;
  align-items: center;
  gap: 30px;
  justify-content: space-between;
}
.online__title {
  font-size: 24px;
  font-weight: 700;
  line-height: 31px;
  letter-spacing: 0;
  text-align: left;
}
.online__content-title {
  font-size: 25px;
  text-align: center;
  margin: 50px 0;
  font-weight: bold;
}
.online__content-photo {
  margin-bottom: 50px;
  position: relative;
  display: flex;
  gap: 200px;
  justify-content: center;
  color: #8a909d;
  & div {
    width: 45%;
    display: flex;
    justify-content: right;
  }
  img {
    width: 300px;
    height: 300px;
    object-fit: cover;
    cursor: auto;
  }
}
.online__content-photoContainer{
  width: 45%;
  & img {
    float: right;
  }
}
.online__content-photo:before {
  content: "";
  height: 100%;
  width: 4px;
  background: red;
  position: absolute;
  top: 0;
  left: 50%;
}
.online__content-desc {
  width: 45%;
}
.online__content-boldTitle {
  font-size: 18px;
  font-weight: 600;
  text-decoration: underline;
  margin: 20px 0;
}
.online__content-text {
  font-weight: 400;
  text-decoration: none !important;
  font-size: 15px;
  word-break: break-all;
}
.online__content-commonTitle {
  font-size: 16px;
  margin-bottom: 15px;
  margin-top: 30px;
}
table {
  margin-bottom: 25px;
  width: 100%;
}
.dashboard__th {
  text-align: left;
}
.online__content-notFullTable {
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 50px;
  span {
    width: 45%;
    min-width: 1px;
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    padding-left: 10px;
    word-break: break-all;
  }
}
.online__content-projectStatusRow {
  padding-left: 10px;
  & p {
    width:45%
  }
}
.online__content-projectStatusRow:not(:last-child) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.online__content-projectStatusRow {
  display: flex;
  //align-items: center;
  justify-content: space-between;
}
.online__content-projectRow {
  margin: 50px 0;
}
.online__content-projectText {
  border-top: 1px solid black;
  display: flex;
  align-items: center;
  justify-content: space-between;
  span {
    width: 55%;
    word-break: keep-all;
  }
}
.online__content-projectText:last-child {
  border-bottom: 1px solid black;
}
.online__content-table {
  margin: 20px 0;
}
.online__content-sitePhotographs-box {
  margin-bottom: 50px;
  img {
    max-width: 500px;
    width: 100%;
  }
}
.online__download {
  position: fixed;
  max-width: 1280px;
  width: 100%;
  bottom: 0;
  border: 1px solid #e5e5e5;
  background: white;
  z-index: 10;
  padding: 20px 32px;
  display: flex;
  justify-content: space-between;
  &Btn {
    border-radius: 8px;
    background: var(--primary-100, #0052cc);
    padding: 8px 20px;
    display: flex;
    font-size: 16px;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 0.3px;
    align-items: center;
    color: white;
    img {
      margin-right: 8px;
    }
  }
}
.online__close {
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #fff;
  padding: 8px 20px;
  display: flex;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.3px;
  align-items: center;
  color: var(--text-subtle, #767676);
  img {
    margin-right: 8px;
  }
}
.online__larp {
  margin-bottom: 30px;
  table {
    margin-bottom: unset;
  }
  .online__content-projectStatusRow {
    display: flex;
    align-items: center;
    justify-content: unset;
  }
  .online__content-notFullTable {
    margin-bottom: unset;
    width: 100%;
    span {
      width: 25%;
      display: block;
      min-height: 48px;
      &:nth-child(1) {
        width: 250px;
      }
      position: relative;
    }
  }
}
.online__bold {
  width: 25%;
  color: #8a909d;
}
.online__sidebar {
  z-index: 10;
}
.online__more {
  justify-content: unset;
  align-items: center;
}
.online__larpTitle {
  margin-top: unset;
}
.online__calendar {
  min-width: 240px;
  position: relative;
  input {
    padding-left: 40px !important;
    width: 213px;
  }
  &-svg {
    position: absolute;
    top: 14px;
    left: 10px;
    z-index: 50;
  }
  &-arrow {
    position: absolute;
    top: 15px;
    right: 15px;
    z-index: 50;
  }
}
.th__align {
  width: 300px;
}

.s-online__content-grid-table {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  .online__bold {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: #8a909d;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-right: none;
    border-bottom: none;
    padding: 10px 2px;
    text-align: left;
    vertical-align: middle;
    padding-left: 10px;
    display: flex;
    align-items: center;
  }
  span {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-right: none;
    border-bottom: none;
    padding: 10px 2px;
    display: flex; /* Установить flex-контейнер */
    justify-content: flex-start; /* Выравнивание по горизонтали слева */
    align-items: center;
    padding-left: 10px;
  }
}

.x-left-y-center {
  text-align: left;
  vertical-align: middle;
}

.s-program-progress-table {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  margin-bottom: 10px;
}

.s-program-progress-table-header {
  align-items: center;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  color: #8a909d;
  display: flex;
  font-size: 12px;
  font-weight: 400;
  height: 52px;
  letter-spacing: 0.4px;
  line-height: 160%;
  padding: 16px;
  position: relative;
  margin-bottom: 2px;

  &:first-child {
    border-radius: 8px 0px 0px 8px;
  }

  &.none {
    border: none;
  }
}

.s-program-progress-table-planed {
  align-items: center;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  height: 52px;
  padding-left: 16px;
  position: relative;
  font-size: 14px;

  &.border {
    border-radius: 8px 0px 0px 8px;
  }
  &.none {
    border: none !important;
    background-color: transparent !important;
  }

  &.editable {
    background: #f8f8f8;
  }
  &.cumulative {
    div {
      color: #8a909d;
    }
  }

  span {
    &.red {
      background: #e68484;
    }
    &.lightblue {
      background: #99baeb;
    }
    &.blue {
      background: #3375d6;
    }
    &.yellow {
      background: #ffd023;
    }
    border-radius: 2px;
    height: 14px;
    margin-right: 8px;
    width: 14px;
  }

  div {
    color: #2a3650;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.4px;
    line-height: 160%;
  }
}

.pl5 {
  padding-left: 8px !important;
}



@media screen and (max-width: 1520px) {
  .online__download {
    right: 30%;
    transform: translateX(30%);
    max-width: 900px;
  }

}
@media screen and (max-width: 1250px) {
  .online__download {
    right: 30%;
    transform: translateX(30%);
    max-width: 600px;
  }
}
