.settings {
  &__header {
    margin-bottom: 24px;
    color: #2A3650;
    h1 {
      font-size: 24px;
      font-weight: 700;
      line-height: 31.2px;

    }
  }
  &__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    color: #2A3650;

    & button, & button img {
      cursor: pointer;
    }
    &__info {
      display: flex;
      gap: 16px;
      border: 6px;
      border-radius: 8px;
      padding: 16px 20px 16px 20px;
      background:   linear-gradient(0deg, rgba(51, 117, 214, 0.14), rgba(51, 117, 214, 0.14));
      border-left: 6px solid #3375D6;
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;

    }
    &__item {
      width: 100%;
      display: flex;
      padding: 20px;
      gap: 16px;
      border-radius: 8px;
      border: 1px solid #E5E5E5;
      &__img {
        cursor: default;
        width: 64px;
        height: 64px;
      }
      &__wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 8px;
      }
      & h2 {
        font-size: 18px;
        font-weight: 600;
        line-height: 25.2px;
        letter-spacing: 0.2px;
        color: #2A3650;
      }
      p {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: #8A909D;
      }
      &__rightWrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 32px;
        margin-left: auto;
      }
      &__value {
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0.2px;
        color: #2A3650;
      }
      &__deleteBtn, &__editBtn {
        width: 24px;
        height: 24px;
        & img {
          width: 24px;
          height: 24px;
        }
      }
      & .a-button {
        width: 126px;
      }
    }
  }
}