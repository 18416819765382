$table1: 125px;
$table2: 145px;
$table3: 125px;
$table4: 145px;
$table5: 115px;
$table6: 115px;
$table7: 125px;
$table8: 130px;

.project {
  padding-left: 150px;
  &__monthly-report {
    background: #ffffff;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.25),
      0 0 0 1px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    padding: 12px 20px;
    text-align: center;
    font-family: "Inter";
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #767676;
    width: 298px;
    position: fixed;
    bottom: 50px;
    right: 50px;
    z-index: 999;
  }
  &__sidebar {
    position: fixed;
    top: 0;
    left: 62px;
    width: 162px;
    height: 100vh;
    background: #fafafb;
    border: 1px solid #eeeeee;
    filter: drop-shadow(0 1px 0 rgba(0, 0, 0, 0.1));
    padding-top: 20px;
    overflow: scroll;
    z-index: 1;
    &-item {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.2px;
      color: #8a909d;
      padding: 8px 20px;
      box-sizing: border-box;
      cursor: pointer;
      border: 1px solid #eeeeee;
      &:first-child {
        border-top: none;
      }
      &:last-child {
        border-bottom: none;
      }

      &.active {
        font-weight: 500;
        font-size: 14px;
        line-height: 150%;
        letter-spacing: 0.2px;
        color: #0052cc;
      }
    }
  }
  &__nav {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #172b4d;
    & a {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #7a869a;
    }
    span {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #7a869a;
    }
  }
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    &-info {
      display: flex;
      align-items: center;
    }
  }
  &__current-date {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #f4f5f6;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: #767676;
    padding: 9px 16px;
  }
  &__title {
    font-weight: 700;
    font-size: 24px;
    line-height: 130%;
    color: #2a3650;
  }
  &__last-update {
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #8a909d;
  }
  &__data {
    position: relative;
    display: flex;
    align-items: flex-start;
    gap:32px;
    margin-top: 32px;
  }
  &__dropdown {
    .active-box {
      display: flex;
    }
  }
  &__left {
    max-width: 428px;
    width: 100%;
  }
  &__image-main {
    cursor: pointer;
  }
  &__wrapper-imageDropDown {
    background: #f8f8f8;
    border-radius: 4px;
    padding: 8px;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 55px;
    right: 20px;
    z-index: 50;
  }
  &__wrapper-dropDownBtn {
    cursor: pointer;
    padding: 10px 12px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: #2a3650;
    &:nth-child(1) {
      position: relative;
    }
    &:nth-child(2) {
      color: #fd6a6a;
    }
    &:hover {
      background: #d7d6d6;
    }
  }
  .project__gallary-delete {
    input {
      position: absolute;
      z-index: 55;
      opacity: 0;
      width: 100%;
      height: 100%;
      cursor: pointer;
    }
  }
  &__image {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 428px;
    width: 100%;
    height: 281px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    background-color: #fff;
    position: relative;
    &:hover {
      .project__wrapper-menu {
        display: block;
      }
      .project__gallary-deleteSvg {
        display: block;
      }
    }
    img {
      width: 100%;
      height: 100%;
      border-radius: 8px;
      object-fit: unset;
    }
    &-upload {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      max-width: 165px;
      width: 100%;
      position: relative;
      img {
        width: 52px !important;
        height: 52px !important;
      }
      input {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        z-index: 22;
        cursor: pointer;
      }
      p {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.2px;
        color: #8a909d;
        margin-top: 16px;
      }
      span {
        color: #6697e0;
      }
    }

    input {
      position: absolute;
      z-index: 55;
      opacity: 0;
      width: 100%;
      height: 100%;
      cursor: pointer;
    }
  }
  &__gallary {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  .gray__space {
    height: 24px;
    background: #fafafb;
    width: 100%;
  }
  &__description {
    padding-bottom: 32px;
    &-box {
      position: relative;
      &.edit {
        .project__block-btns {
          display: flex;
          margin-top: -6px;
        }
      }
      &:hover {
        .project__block-btns {
          display: flex;
        }
      }
      textarea {
        resize: none;
        background: #f9f9fb;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        padding: 12px 16px;
        width: 100%;
        outline: none;
      }
      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }
    &-title {
      font-weight: 500;
      font-size: 16px;
      line-height: 150%;
      letter-spacing: 0.2px;
      color: #2a3650;
      margin-bottom: 4px;
    }
    &-subtitle {
      background: #f9f9fb;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 5px;
      word-break: break-word;
      padding: 12px 16px;
    }
  }
  &__gallary-image {
    border-radius: 4px;
    width: 140px;
    height: 140px;
    margin-right: 11px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    cursor: pointer;
    position: relative;
    &:last-child {
      margin-right: 0;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__gallary-deleteSvg {
    display: none;
    position: absolute;
    top: -1px;
    right: 40px;
    border-radius: 4px;
    padding: 6px;
    cursor: pointer;
    height: 50px !important;
    width: 50px !important;
    &.main-delete {
      top: 50px;
      right: 11px;
    }
  }
  &__gallary-image:hover {
    .project__gallary-delete {
      display: block;
    }
    .project__gallary-deleteSvg {
      display: block;
    }
  }

  &__gallary-delete {
    display: none;
    position: absolute;
    top: 5px;
    right: 5px;
    background: white;
    border-radius: 4px;
    padding: 6px;
    cursor: pointer;
  }

  &__gallary-create {
    background: white;
    border-radius: 4px;
    padding: 1px;
    cursor: pointer;

  }
  &__right {
  }

  &__scope {
    margin-top: 12px;
    label {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.2px;
      color: #8a909d;
    }
    p {
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
      color: #2a3650;
      margin-top: 4px;
    }
  }
  &__top {
    display: flex;
    background: #ffffff;
    align-items: center;
    padding: 24px 0;
    justify-content: space-between;
    margin-bottom: 24px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
  &__leftInfo {
    width: 55%;
    &-subtitle {
      font-family: "Rubik";
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.2px;
      color: #2a3650;
      margin-left: 16px;
    }
    &-box {
      height: 44px;
      background: #ffffff;
      position: relative;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 8px;
      display: flex;
      align-items: center;
      &:hover {
        .project__block-btns {
          display: flex;
        }
      }
      input {
        padding-left: 10px;
        width: 100%;
      }
      &.edit {
        .project__block-btns {
          display: flex;
        }
      }
      &:not(:last-child) {
        margin-bottom: 8px;
      }
      .project__subtitle {
        margin-left: 16px;
      }
    }
  }
  &__subtitle {
    font-weight: 500;
    font-size: 16px;
    line-height: 130%;
    color: #2a3650;
  }
  &__location {
    display: flex;
    margin-top: 16px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: #767676;
    margin-bottom: 8px;
    img {
      margin-right: 12px;
    }
  }

  &__summary {
    margin-top: 16px;
    label {
      font-weight: 500;
      font-size: 16px;
      line-height: 150%;
      letter-spacing: 0.2px;
      color: #2a3650;
    }
    p {
      margin-top: 4px;
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
      color: #2a3650;
      max-width: 700px;
      word-break: break-word;
    }
  }

  &__upload {
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    width: 140px;
    height: 140px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;
    margin-right: 11px;
    input {
      position: absolute;
      opacity: 0;
      top: 0;
      left: 0;
      background: #f9f9fb;
      width: 100%;
      height: 100%;
      z-index: 22;
      cursor: pointer;
    }
  }

  &__white-box {
    background: #ffffff;
    border-width: 3px 0;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.1);
    padding: 24px 32px;
    margin-right: -25px;
    margin-left: -25px;
    margin-top: 12px;
    &:first-child {
      margin-top: 32px;
    }

    &.contract-details {
    }
    &.close {
      .data__title {
        &::before {
          opacity: 1;
          width: 2px;
          height: 20px;
        }
      }
      .project__site_content {
        opacity: 0;
        height: 0;
        overflow: hidden;
      }
      .project__monitoring {
        opacity: 0;
        height: 0;
        overflow: hidden;
      }
      .plannedProject {
        opacity: 0;
        height: 0;
        overflow: hidden;
      }
      .project__contract-tables {
        opacity: 0;
        height: 0;
        overflow: hidden;
      }
      .project__block-table.project-table {
        opacity: 0;
        height: 0;
        overflow: hidden;
      }
      .contact_details {
        height: 0;
        opacity: 0;
        overflow: hidden;
      }
      .project__block-add-row {
        height: 0;
        opacity: 0;
        overflow: hidden;
      }
      .plannedProject {
        height: 0;
        opacity: 0;
        overflow: hidden;
      }
    }
    .data {
      &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      &__box {
        display: flex;
        flex-direction: column-reverse;
      }
      &__number {
        background: #f9f9fb;
        border: 1px solid #e5e5e5;
        border-radius: 5px;
        padding: 8px 12px;
        font-family: "Rubik";
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #8a909d;
        span {
          font-weight: 600;
          color: #2a3650;
          margin-left: 6px;
        }
      }
      &__title {
        font-weight: 600;
        font-size: 20px;
        line-height: 140%;
        color: #2a3650;
        position: relative;
        padding-left: 32px;
        cursor: pointer;
        &::after {
          position: absolute;
          content: "";
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          width: 20px;
          height: 2px;
          border-radius: 3px;
          background: #6697e0;
        }
        &::before {
          position: absolute;
          content: "";
          top: 49%;
          left: 9px;
          transform: translateY(-50%);
          width: 2px;
          height: 20px;
          border-radius: 3px;
          background: #6697e0;
          transition: all 0.3s;
          opacity: 0;
        }
      }
    }
    border: none;
  }
  &__report-btns {
    display: flex;
    align-items: center;
    margin-top: 12px;
    z-index: 44;
  }
  &__report-preview,
  &__report-submit {
    width: max-content;
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: #767676;
    padding: 12px 32px;
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    cursor: pointer;

    &.edit {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-left: 12px;
      padding-right: 12px;
      width: 250px;
    }
    img {
      margin-right: 4px;
      width: 16px;
      height: 16px;
      margin-top: -1px;
    }
  }
  &__report-preview {
    margin-right: 12px;
  }
  &__contract-tables {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 16px;
    padding-top: 16px;
    transition: all 0.3s;
  }
  &__contract-table {
    width: 49%;
    border-radius: 8px;
    overflow: hidden;
    &-row {
      padding: 12px 20px;
      background: #f9f9fb;
      border: 1px solid #e5e5e5;
      &:nth-child(2n) {
        background: #ffffff;
      }
      label {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        display: flex;
        align-items: center;
        letter-spacing: 0.2px;
        color: #8a909d;
        text-transform: capitalize;
      }
      p {
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        display: flex;
        align-items: center;
        color: #2a3650;
      }
    }
  }
  &__cover {
    &.close {
      height: 100px;
      .project__top {
        height: 0;
        opacity: 0;
        overflow: hidden;
      }

      .project__description {
        height: 0;
        opacity: 0;
        overflow: hidden;
      }
      .data__title {
        &::before {
          opacity: 1;
          width: 2px;
          height: 20px;
        }
      }
    }
    padding-top: 32px;
    .data__title {
      font-weight: 600;
      font-size: 20px;
      line-height: 140%;
      color: #2a3650;
      position: relative;
      padding-left: 42px;
      cursor: pointer;
      &::after {
        position: absolute;
        content: "";
        top: 50%;
        left: 10px;
        transform: translateY(-50%);
        width: 20px;
        height: 2px;
        border-radius: 3px;
        background: #6697e0;
      }
      &::before {
        position: absolute;
        content: "";
        top: 49%;
        left: 19px;
        transform: translateY(-50%);
        width: 2px;
        height: 20px;
        border-radius: 3px;
        background: #6697e0;
        transition: all 0.3s;
        opacity: 0;
      }
    }
  }
  .activitiesDf {
    display: flex;
    align-items: center;
  }

  &__block {
    position: relative;
    width: 100%;
    &-table {
      &.n {
        z-index: 55;
      }
    }
    &.generalclose {
      height: 0;
      opacity: 0;
      overflow: hidden;
    }

    &.close {
      height: 10px;
      .plannedProject {
        height: 0;
        opacity: 0;
        overflow: hidden;
      }
      .project__block-title {
        &::before {
          opacity: 1;
          width: 2px;
          height: 20px;
        }
      }
      .project__block-content {
        height: 0;
        opacity: 0;
        overflow: hidden;
      }
      .project__block-hr {
        height: 0;
        opacity: 0;
        overflow: hidden;
      }
      .project__block-subtitles {
        height: 0;
        opacity: 0;
        overflow: hidden;
      }
      .general-title {
        height: 0;
        opacity: 0;
        overflow: hidden;
        span {
          height: 0;
          opacity: 0;
          overflow: hidden;
        }
      }
      .approved__table {
        height: 0;
        opacity: 0;
        overflow: hidden;
      }
      .contact_details {
        height: 0;
        opacity: 0;
        overflow: hidden;
      }
    }
    &-title {
      display: flex;
      align-items: center;
      font-weight: 600;
      line-height: 140%;
      color: #2a3650;
      font-size: 20px;
      position: relative;
      padding-left: 32px;
      cursor: pointer;
      &::after {
        position: absolute;
        content: "";
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        width: 20px;
        height: 2px;
        border-radius: 3px;
        background: #6697e0;
      }
      &::before {
        position: absolute;
        content: "";
        top: 49%;
        left: 9px;
        transform: translateY(-50%);
        width: 2px;
        height: 20px;
        border-radius: 3px;
        background: #6697e0;
        transition: all 0.3s;
        opacity: 0;
      }
    }
    .general-title {
      font-size: 18px;
      display: flex;
      align-items: center;
      font-weight: 600;
      line-height: 140%;
      color: #2a3650;
      position: relative;
      cursor: pointer;
      margin-top: 20px;
    }
    &-more {
      display: flex;
      align-items: center;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 8px;
      padding: 4px 10px;
      margin-left: 24px;
      cursor: pointer;
      .img {
        margin-right: 6px;
      }
      .text {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #767676;
      }
    }
    &-hr {
      background-color: rgba(0, 0, 0, 0.1);
      width: 100%;
      height: 3px;
      margin-top: 16px;
      margin-bottom: 16px;
    }
    &-content {
      transition: all 0.3s;
      z-index: 4;
      position: relative;
      &.editable {
        z-index: 5;
      }

      &.changes-table {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: 1px solid rgba(30, 30, 30, 0.1);
        border-right: none;
        cursor: pointer;
        position: relative;
        &.edit {
          box-shadow: -6px -3px 70px -30px rgba(0, 0, 0, 0.54);
          -webkit-box-shadow: -6px -3px 70px -30px rgba(0, 0, 0, 0.54);
          -moz-box-shadow: -6px -3px 70px -30px rgba(0, 0, 0, 0.54);
          z-index: 55;
          .project__block-btns {
            display: flex;
            z-index: 55;
          }
        }
        &:hover {
          box-shadow: -6px -3px 70px -30px rgba(0, 0, 0, 0.54);
          -webkit-box-shadow: -6px -3px 70px -30px rgba(0, 0, 0, 0.54);
          -moz-box-shadow: -6px -3px 70px -30px rgba(0, 0, 0, 0.54);
          z-index: 55;
          .project__block-btns {
            display: flex;
            z-index: 55;
          }
        }
      }

      .changes-table {
        &__item {
          width: 33%;
          padding: 24px;
          box-sizing: border-box;
          border-right: 1px solid rgba(30, 30, 30, 0.1);
          .label {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.2px;
            color: #8a909d;
            margin-bottom: 8px;
          }
          .value {
            font-weight: 600;
            font-size: 16px;
            line-height: 150%;
            letter-spacing: 0.2px;
            color: #2a3650;
          }
        }
      }
      &.executive-summary {
        &.edit {
          z-index: 55;
          .content {
            padding: 0 !important;
            box-shadow: -6px -3px 70px -30px rgba(0, 0, 0, 0.54);
            -webkit-box-shadow: -6px -3px 70px -30px rgba(0, 0, 0, 0.54);
            -moz-box-shadow: -6px -3px 70px -30px rgba(0, 0, 0, 0.54);
            filter: drop-shadow(0px 4px 14px rgba(0, 0, 0, 0.15));
            transform: scale(1.01);
            z-index: 5;
          }
          .project__block-btns {
            display: flex;
            z-index: 55;
          }
        }
        .gray-box {
          background: #f8f8f8;
          border-radius: 12px;
          padding: 20px;

          .content {
            background-color: #fff;
            padding: 12px;
            cursor: pointer;
            position: relative;
            border-radius: 6px;

            textarea {
              width: 100%;
              height: 100%;
              padding: 12px;
              outline: none;
              background-color: #fff;
              padding: 12px;
              position: relative;
              border-radius: 6px;
              transition: all 0.3s;
            }

            &:hover {
              box-shadow: -6px -3px 70px -30px rgba(0, 0, 0, 0.54);
              -webkit-box-shadow: -6px -3px 70px -30px rgba(0, 0, 0, 0.54);
              -moz-box-shadow: -6px -3px 70px -30px rgba(0, 0, 0, 0.54);
              filter: drop-shadow(0px 4px 14px rgba(0, 0, 0, 0.15));
              transform: scale(1.01);
              z-index: 5;
              .project__block-btns {
                display: flex;
                z-index: 55;
              }
            }
          }

          .text {
            font-weight: 400;
            font-size: 16px;
            line-height: 150%;
            color: #2a3650;
            word-break: break-word;
          }
        }
      }
    }
    &-btns {
      position: absolute;
      top: 100%;
      right: 10px;
      display: none;
      z-index: 335;
      div,
      button {
        display: flex;
        align-items: center;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.2px;
        font-weight: 400;
        padding: 2px 14px;
        cursor: pointer;
        border-width: 0 1px 1px 1px;
        border-style: solid;
        border-radius: 0 0 8px 8px;
        z-index: 55;
        img {
          margin-right: 4px;
        }
      }
    }
    &-total {
      padding: 8px 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: #ffffff;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 8px;
      margin-top: 8px;
      span {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.2px;
        color: #2a3650;
      }
    }
    &-expand {
      background: #ffffff;
      border-color: #dbdbdb;
      color: #2a3650;
    }
    &-update {
      color: #3375d6;
      border-color: #b5ccef;
      background: #f1f7ff;
    }
    &-save {
      color: #0caf60;
      border-color: #8cd7b4;
      background: #dff7ec;
    }
    &-cancel {
      background-color: #f8f8f8;
      border-color: #e5e5e5;
      color: #8a909d;
      img {
        width: 16px !important;
        height: 16px !important;
        margin-top: -1px !important;
        margin-right: 1px !important;
      }
    }
    &-delete {
      color: #fd6a6a;
      border-color: #eebdbd;
      background: #fff2f2;

      img {
        width: 14px;
        height: 14px;
      }
    }
    &-item {
      &-calendar {
        position: relative;
        z-index: 500;
        input {
          padding: 8px !important;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.2px;
          color: #2a3650;
          width: 82% !important;
          background: #f9f9fb !important;
          border: 1px solid rgba(0, 0, 0, 0.1) !important;
          border-radius: 8px !important ;
          width: 100% !important;
          &::placeholder {
            color: #8a909d;
          }
        }
        img {
          position: absolute;
          top: 50%;
          right: 10px;
          transform: translateY(-50%);
          z-index: 500;
        }
        label {
          display: flex;
          z-index: 500;
          align-items: center;
          padding-left: 16px;
          padding-top: 3px;
          position: absolute;
          top: 3px;
          left: 1px;
          width: 90%;
          height: 38px;
          background: #f9f9fb;
          border-radius: 8px;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.2px;
          color: #2a3650;
          cursor: pointer;
        }
      }
      &-usd {
        display: flex;
        align-items: center;
        span {
          display: flex;
          align-items: center;
          background: #ffffff;
          border: 1px solid #e0e0e2;
          border-radius: 8px 0 0 8px;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.2px;
          color: #8a909d;
          padding: 10px 13.5px;
          position: relative;
          div {
            position: absolute;
            right: -20px;
            z-index: 2;
            font-weight: 400;
            font-size: 16px;
            line-height: 150%;
            color: #2a3650;
          }
        }
        input {
          background: #ffffff;
          border: 1px solid rgba(0, 0, 0, 0.1);
          border-radius: 0 8px 8px 0;
          padding-top: 8px;
          padding-bottom: 8px;
          padding-left: 20px;
          font-weight: 400;
          font-size: 16px;
          line-height: 150%;
          color: #2a3650;
        }
      }
    }
    &-subtitles {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 16px;

    }
    &__buttonsContainer{
      display: flex;
      gap: 32px;
      margin-left: auto;
    }
    &-sectionsTitle {
      font-weight: 600;
      font-size: 18px;
      line-height: 140%;
      letter-spacing: 0.2px;
      color: #2a3650;
      margin-right: 16px;
    }
    &-sectionsInput {
      padding: 6px 6px 6px 10px;
      background: rgba(42, 54, 80, 0.06);
      border-radius: 8px;
      display: flex;
      align-items: center;
      margin-right: 12px;
      .css-b62m3t-container {
        margin-left: 10px;
      }
    }
    &-larpSection {
      margin-top: 16px;
      display: flex;
      align-items: center;
      margin-bottom: 13px;
    }
    &-subtitle {
      font-weight: 500;
      font-size: 16px;
      line-height: 150%;
      letter-spacing: 0.2px;
      color: #2a3650;
    }
    &-checkbox {
      border-radius: 8px;
      margin-left: 20px;
      padding: 4px 12px;
      background: rgba(0, 82, 204, 0.1);
    }
    .checkbox {
      display: flex;
      align-items: center;
      label {
        font-family: "Rubik";
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.2px;
        color: #0052cc;
      }
    }
    &-add-row {
      display: flex;
      align-items: center;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
      color: #0052cc;
      cursor: pointer;

      img {
        margin-right: 8px;
      }
    }
    &-expandedInput {
      position: absolute;
      min-height: 130px;
      -webkit-transform: translateX(0);
      width: 100%;
      top: -24px;
      left: 0;
      border: 1px solid black;
      outline: none;
      z-index: 999;
      text-align: left;
      padding: 0.4em;
      padding-bottom: 50px;
      border-radius: 8px;
    }
    &-expandedTextArea {
      //position: fixed;
      max-height: 250px;
      top: 0;
      outline: none !important;
      z-index: 900;
      text-align: left;
      padding: 0.4em;
      padding-bottom: 50px;
      border-radius: 8px;
    }
    &-expandedOverview {
      position: absolute;
      left: 0;
      min-height: 242px;
      border: 1px solid black;
      width: 100%;
      top: 0;
      outline: none !important;
      z-index: 500;
      text-align: left;
      padding: 10px;
      padding-bottom: 50px;
      border-radius: 8px;
    }
    &-input {
      width: 100%;
      position: relative;
      textarea,
      input {
        width: 100%;
        outline: none;

      background: #ffffff;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 8px;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #2a3650;
      padding-top: 8px;
      padding-bottom: 8px;
      padding-left: 8px;
      outline: none;
      padding-right: 8px;
      }
    }
    &-table {
      z-index: 10;
      .project-table {
        &__header {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 16px;
          background: #ffffff;
          border: 1px solid rgba(0, 0, 0, 0.1);
          border-radius: 8px;

          &.grid-s {
            display: grid;
            grid-template-columns: 2fr 2fr 2fr 2fr;
          }
        }
        &-larp-sections {
          padding: 16px 50px 16px 16px;
        }
        &__achieved {
          width: 150px !important;
        }
        &__th {
          font-weight: 400;
          font-size: 12px;
          line-height: 160%;
          display: flex;
          align-items: center;
          letter-spacing: 0.4px;
          color: #8a909d;
          position: relative;
          margin-right: 5px;
          z-index: 1;
          &.risk_closed {
            width: 70px;
          }
          .lengthy {
            position: absolute;
            padding: 4px 8px;
            background: #767676;
            border-radius: 8px;
            color: white;
            transform: translateY(-100%);
            width: 100%;
            text-align: center;
            &:before {
              content: "";
              position: absolute;
              top: 99%;
              left: 43%;
              border-width: 5px;
              border-style: solid;
              border-color: #767676 transparent transparent;
            }
          }
          .not-lengthy {
            position: absolute;
            padding: 4px 8px;
            background: #767676;
            border-radius: 8px;
            color: white;
            transform: translateY(-100%);
            width: 100%;
            left: 0;
            text-align: center;
            &:before {
              content: "";
              position: absolute;
              top: 98%;
              left: 43%;
              border-width: 5px;
              border-style: solid;
              border-color: #767676 transparent transparent;
            }
          }
          .project-table__th:hover {
            .project-table__th span {
              display: block;
            }
          }
          &:last-child {
            margin-right: 0;
          }
          &:nth-child(1) {
            width: $table1;
          }
          &:nth-child(2) {
            width: $table2;
          }
          &:nth-child(3) {
            width: $table3;
          }
          &:nth-child(4) {
            width: $table4;
          }
          &:nth-child(5) {
            width: $table5;
          }
          &:nth-child(6) {
            width: $table6;
          }
          &:nth-child(7) {
            width: $table7;
          }
          &:nth-child(8) {
            width: $table8;
          }
        }
        &__field {
          position: relative;
          &.percent {
            p {
              position: absolute;
              top: 21%;
              right: 10px;
              color: gray;
            }
          }
          display: none;
          &.select {
            border: none;
            padding: 0;
            overflow: visible;
          }
        }

        &__row {
          display: flex;
          align-items: center;
          justify-content: space-between;
          background: #ffffff;
          border: 1px solid rgba(0, 0, 0, 0.1);
          border-radius: 8px;
          padding: 16px 15px 16px 16px;
          margin-top: 2px;
          transition: all 0.1s;
          cursor: pointer;
          position: relative;


          &.grid-s {
            display: grid;
            grid-template-columns: 2fr 2fr 2fr 2fr;
          }
          &.expandedRow {
          }

          .project__block-item-calendar {
            .react-datepicker-popper {
              z-index: 56;
            }
            input {
              font-weight: 400 !important;
              font-size: 14px !important;
              line-height: 20px !important;
              padding: 8px !important;
              background-color: #fff !important;
              &:disabled {
                background-color: #f9f9fb !important;
              }
            }
          }

          &.edit {
            // filter: drop-shadow(0px 4px 14px rgba(0, 0, 0, 0.15));
            box-shadow: -6px -3px 70px -30px rgba(0, 0, 0, 0.54);
            -webkit-box-shadow: -6px -3px 70px -30px rgba(0, 0, 0, 0.54);
            -moz-box-shadow: -6px -3px 70px -30px rgba(0, 0, 0, 0.54);
            filter: drop-shadow(0px 4px 14px rgba(0, 0, 0, 0.15));
            transform: scale(1.01);
            z-index: 55;
            .project__block-btns {
              display: flex;
            }
            .project-table__td {
              span {
                display: none;
              }
            }
            .project-table__field {
              display: block;
            }
            .project__actualDate-disabled {
              input {
                background: #f8f8f8 !important;
                color: #767676 !important;
              }
            }
          }

          &.n {
            box-shadow: none !important;
            transform: none !important;
            z-index: 1 !important;
            filter: none !important;
            .project__block-btns {
              display: none !important;
              &.s {
                display: flex !important;
              }
            }
          }

          &.submitted {
            background-color: #f8f8f8;
            .switch input:checked + label {
              background: grey;
              border-color: grey;
            }
            &:hover {
              box-shadow: unset;
              -webkit-box-shadow: inherit;
              -moz-box-shadow: inherit;
              filter: unset;
              transform: unset;
              z-index: unset;
              .project__block-btns {
                display: none;
              }
            }
          }

          &:hover {
            // filter: drop-shadow(0px 4px 14px rgba(0, 0, 0, 0.15));
            box-shadow: -6px -3px 70px -30px rgba(0, 0, 0, 0.54);
            -webkit-box-shadow: -6px -3px 70px -30px rgba(0, 0, 0, 0.54);
            -moz-box-shadow: -6px -3px 70px -30px rgba(0, 0, 0, 0.54);
            filter: drop-shadow(0px 4px 14px rgba(0, 0, 0, 0.15));
            transform: scale(1.01);
            z-index: 55;
            .project__block-btns {
              display: flex;
            }
          }
        }
        &-larp-sections {
          padding: 16px 50px 16px 16px;
        }
        &__td {
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.2px;
          color: #2a3650;
          margin-right: 5px;
          &.risk_closed {
            width: 70px;
          }
          &_yellow {
            background: rgba(214, 177, 41, 0.1);
            border-radius: 4px;
            padding: 8px;
            color: #d6b129;
            font-weight: 500;
          }
          &_red {
            background: #ffe9e9;
            border-radius: 4px;
            color: #fd6a6a;
            padding: 8px;
            font-weight: 500;
          }
          &_blue {
            background: #7474ef;
            border-radius: 4px;
            padding: 8px;
            color: blue;
            font-weight: 500;
          }
          &_orange {
            background: #ffe6b4;
            border-radius: 4px;
            padding: 8px;
            color: orange;
            font-weight: 500;
          }
          &_grey {
            background: rgba(16, 22, 34, 0.1);
            border-radius: 4px;
            padding: 8px;
            color: #989898;
            font-weight: 500;
          }
          &_lightgrey {
            background: rgba(106, 106, 106, 0.1);
            border-radius: 4px;
            padding: 8px;
            color: #3d475c;
            font-weight: 500;
          }
          &_green {
            background: #dff7ec;
            border-radius: 4px;
            padding: 8px;
            color: #0caf60;
            font-weight: 500;
          }
          span {
            word-wrap: break-word;
          }
          &:last-child {
            margin-right: 0;
          }
          &:nth-child(1) {
            width: $table1;
          }
          &:nth-child(2) {
            width: $table2;
          }
          &:nth-child(3) {
            width: $table3;
          }
          &:nth-child(4) {
            width: $table4;
          }
          &:nth-child(5) {
            width: $table5;
          }
          &:nth-child(6) {
            width: $table6;
          }
          &:nth-child(7) {
            width: $table7;
          }
          &:nth-child(8) {
            width: $table8;
          }
        }
        &__achieved {
          width: 150px !important;
        }
      }
    }
    &-load {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      display: flex;
      align-items: center;
      letter-spacing: 0.2px;
      color: #2a3650;
      background: #ffffff;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 8px;
      padding: 8px;
      cursor: pointer;
      margin-top: 18px;
      transition: all 0.3s;

      &.n {
        z-index: -55;
        &:hover {
          transform: none !important;
          z-index: -1 !important;
        }
      }
      &:hover {
        transform: translateY(-3px);
      }
      &:active {
        transform: translateY(0px);
      }
    }
  }
  &__program {
    padding-bottom: 32px;
    &-overview {
      padding-top: 35.5px;
      margin-top: 32px;
      border-width: 3px 0;
      border-style: solid;
      border-color: rgba(0, 0, 0, 0.1);
    }
    &-subtitles {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 16px;
    }
    &-desc {
      word-break: break-word;
      max-height: 178px;
      overflow: scroll;
    }
    &-subtitle {
      display: flex;
      align-items: center;
      font-weight: 500;
      font-size: 14px;
      line-height: 150%;
      letter-spacing: 0.2px;
      text-transform: uppercase;
      color: #2a3650;
    }
    &-reports {
      display: flex;
      align-items: center;
      background: #f8f8f8;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 8px;
      padding: 6px 8px;
      span {
        margin-left: 4px;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #767676;
      }
    }
    &-content {
      background: #f8f8f8;
      border-radius: 12px;
      padding: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &-left {
      width: 50%;
      background: #ffffff;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 4px;
      padding: 12px;
      min-height: 204px;
      position: relative;
      margin-right: 24px;
      textarea {
        width: 100%;
        height: 100%;
        outline: none;
      }
      &.expanded-input {
        .project__block-btns {
          top: 120% !important;
        }
      }
      &.edit {
        .project__block-btns {
          display: flex;
        }
      }
      &:hover {
        // filter: drop-shadow(0px 4px 14px rgba(0, 0, 0, 0.15));
        box-shadow: -6px -3px 70px -30px rgba(0, 0, 0, 0.54);
        -webkit-box-shadow: -6px -3px 70px -30px rgba(0, 0, 0, 0.54);
        -moz-box-shadow: -6px -3px 70px -30px rgba(0, 0, 0, 0.54);
        z-index: 55;
        .project__block-btns {
          display: flex;
          z-index: 55;
        }
      }
    }
    &-text {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #767676;
      text-transform: capitalize;
    }
    &-right {
      width: 50%;
      background: #ffffff;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 4px;
      display: flex;
      align-items: center;
      padding: 25px 24px;
    }
    &-circle {
      padding-right: 32px;
      border-right: 1px solid #e5e5e5;
    }
    &-stats {
      margin-left: 32px;
    }
    &-stat {
      display: flex;
      align-items: center;
      p {
        line-height: 150%;
        color: #2a3650;
        margin-left: 6px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        span {
          margin-left: 34px;
          font-family: "Rubik";
          font-weight: 500;
          font-size: 16px;
          line-height: 150%;
          letter-spacing: 0.2px;
        }
      }
    }
    &-color {
      height: 14px;
      border-radius: 4px;
      width: 14px;

    }
    .green {
      background: #0caf60;
    }
    .yellow {
      background: #ecc949;
    }
    .blue {
      background: #3375d6;
    }
    .red {
      background: #E68484;
    }
    .orange {
      background: #E7984F;
    }
    .text {
      text-transform: unset;
    }
  }
  &__dashboard {
    background: #F9F9FB;
    padding-left: 64px;
    &-header {
      padding: 0 32px 24px 32px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    }
    &-left {
      width: 70%;
      padding-right: 32px;
    }
    &-content {
      background: #f9f9fb;
      padding: 0 0 0 32px;
      display: flex;
    }
    &-top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-top: 28px;
      margin-bottom: 32px;
    }
    &-date {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: #ffffff;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 8px;
      min-width: 282px;
      padding: 10px 16px;
    }
    &-title {
      display: flex;
      align-items: center;
      font-family: "Rubik";
      font-weight: 700;
      font-size: 24px;
      line-height: 130%;
      color: #2a3650;
      span {
        font-weight: 500;
        font-size: 14px;
        line-height: 150%;
        color: #0052cc;
        padding: 6px 20px;
        background: #e5eefa;
        margin-left: 12px;
        border-radius: 5px;
      }
    }
    &-progress {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 16px;
    }
    &-subtitle {
      font-weight: 500;
      font-size: 16px;
      line-height: 150%;
      letter-spacing: 0.2px;
      text-transform: uppercase;
      color: #2a3650;
    }
    &-statuses {
      display: flex;
      align-items: center;
    }
    &-status {
      display: flex;
      align-items: center;
      .red {
        background: #e68484;
      }
      .lightblue {
        background: #99baeb;
      }
      .blue {
        background: #3375d6;
      }
      .yellow {
        background: #ecc949;
      }
      div {
        height: 14px;
        width: 14px;
        border-radius: 4px;
        margin-right: 8px;
      }
      &:not(:first-child) {
        margin-left: 24px;
      }
    }
    &-versus {
      padding-top: 32px;
    }
    &-versusChart {
      padding-top: 16px;
    }
    &-right {
      background: white;
      padding: 24px 32px;
      width: 30%;
      height: 100%;
      border-bottom: 1px solid rgba(0, 0, 0, 0.08);
      border-left: 1px solid rgba(0, 0, 0, 0.08);
    }
    &-info {
      border: 1px solid rgba(0, 0, 0, 0.08);
      border-radius: 8px;
      padding: 10px 16px;
      &:not(:last-child) {
        margin-bottom: 16px;
      }
      p {
        font-family: "Rubik";
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: #2a3650;
      }
    }
    &-infoTitle {
      font-family: "Rubik";
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.2px;
      color: #8a909d;
    }
    &-pieBlock {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 12px 0 20px;
      background: #f8f8f8;
      border-radius: 8px;
    }
    &-stats {
      width: 50%;
    }
    &-statBox {
      display: flex;
      align-items: center;
      p {
        margin-left: 5px;
      }
    }
    &-stat {
      display: flex;
      align-items: center;
      justify-content: space-between;
      &:not(:last-child) {
        margin-bottom: 20px;
      }
      span {
        font-family: "Rubik";
        font-weight: 500;
        font-size: 16px;
        line-height: 150%;
        letter-spacing: 0.2px;
        color: #2a3650;
      }
    }
    &-overall {
      display: flex;
      align-items: center;
      p {
        margin-left: 6px;
      }
    }
    .green {
      background: #0caf60;
    }
    .yellow {
      background: #ecc949;
    }
    .blue {
      background: #3375d6;
    }
  }
  &__site {
    &-photo {
      background: #f8f8f8;
      border-radius: 8px;
      padding: 12px 16px 12px 37px;
      display: flex;
      align-items: center;
      margin-bottom: 12px;
    }
    &-subtitle {
      font-weight: 500;
      font-size: 14px;
      line-height: 150%;
      letter-spacing: 0.2px;
      margin-bottom: 12px;
      color: #2a3650;
    }
    &-image {
      margin-right: 32px;
    }
    &-description {
      width: 100%;
      .project__description-subtitle {
        background: white;
        height: 140px;
        overflow: auto;

      }
      .project__description-box textarea {
        height: 140px;
        resize: none;
        background: white;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        padding: 12px 16px;
        width: 100%;
        outline: none;
      }
    }
  }
  &__disputes {
    .project__block-content.executive-summary {
      margin: 12px 0 16px;
    }
  }
  &__goods {
    .project__block-subtitles {
      margin-top: 33px;
    }
  }
  &__subcontractor {
    padding-top: 20px;
  }
  &__status {
    &-content {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 8px;
      justify-content: space-between;
      &:nth-child(1) {
        margin-bottom: 16px;
      }
    }
    &-box {
      padding: 24px 20px;
      width: 25%;
      background: #ffffff;
      &:not(:last-child) {
        border-right: 1px solid rgba(0, 0, 0, 0.1);
      }
      &:hover {
        // filter: drop-shadow(0px 4px 14px rgba(0, 0, 0, 0.15));
        box-shadow: -6px -3px 70px -30px rgba(0, 0, 0, 0.54);
        -webkit-box-shadow: -6px -3px 70px -30px rgba(0, 0, 0, 0.54);
        -moz-box-shadow: -6px -3px 70px -30px rgba(0, 0, 0, 0.54);
        filter: drop-shadow(0px 4px 14px rgba(0, 0, 0, 0.15));
        transform: scale(1.01);
        z-index: 55;
        .project__block-btns {
          display: flex;
        }
      }
    }
    &-subtitle {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.2px;
      max-width: 300px;
      color: #8a909d;
      margin-bottom: 8px;
    }
    .long {
      max-width: 216px;
    }
    .big {
      padding: 10px 20px !important;
    }
    &-text {
      font-weight: 600;
      font-size: 16px;
      line-height: 150%;
      letter-spacing: 0.2px;
      color: #2a3650;
      margin-top: 8px;
    }
    &-text-red {
      font-weight: 600;
      font-size: 16px;
      line-height: 150%;
      letter-spacing: 0.2px;
      color: #fd6a6a;
      margin-top: 8px;
    }
    .dark {
      background: #f8f8f8;
    }
    .variations {
      height: 112px;
      border-top: 1px solid rgba(0, 0, 0, 0.1);
    }
    &-show {
      display: flex;
      justify-content: space-between;
      margin: 16px 0;
      cursor: pointer;
    }
  }
  .varandclaims {
    margin-top: 32px;
    padding-top: 35px;
    border-width: 3px 0;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.1);

    &__content {
      display: flex;
      margin-bottom: 20px;
      align-items: center;
      justify-content: space-between;
    }
    &__box {
      padding: 24px 20px;
      width: 25%;
      background: #ffffff;
      .project__status-subtitle {
        font-size: 13px;
      }
      &:not(:last-child) {
        border-right: 1px solid rgba(0, 0, 0, 0.1);
      }
    }
    &__boxLong {
      padding: 24px 20px;
      width: 33.3333%;
      background: #ffffff;
      &:not(:last-child) {
        border-right: 1px solid rgba(0, 0, 0, 0.1);
      }
    }
  }
  .varandclaims {
    &__block {
      display: flex;
      &:last-child {
        margin-bottom: 20px;
      }

      .varandclaims__th {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 33%;
        font-weight: 500;
        font-size: 14px;
        line-height: 150%;
        letter-spacing: 0.2px;
        text-transform: uppercase;
        color: #2a3650;
        margin-top: 20px;
        margin-bottom: 5px;
      }
      &.grid-2 {
        .varandclaims__box {
          width: 33%;
          border-top: 1px solid rgba(0,0,0, 0.1);
          border-bottom: 1px solid rgba(0,0,0, 0.1);
          &:first-child {
            border-left: 1px solid rgba(0,0,0, 0.1);
          }
          &:last-child {
            border-right: 1px solid rgba(0,0,0, 0.1);
          }
        }
      }
      &.grid-3 {
        .varandclaims__box {
          width: 33%;
          border-top: 1px solid rgba(0,0,0, 0.1);
          border-bottom: 1px solid rgba(0,0,0, 0.1);
          &:first-child {
            border-left: 1px solid rgba(0,0,0, 0.1);
          }
          &:last-child {
            border-right: 1px solid rgba(0,0,0, 0.1);
          }
        }
      }
    }
  }
  &__gender {
    &-content {
      display: flex;
      margin-bottom: 20px;
      align-items: center;
      justify-content: space-between;
    }
    &-box {
      padding: 24px 20px;
      width: 50%;
      background: #ffffff;
      position: relative;
      .project__block-input {
        height: inherit;
        input {
          width: 100%;
        }
      }
      &.edit {
        .project__block-btns {
          display: flex;
          z-index: 55;
        }
      }
      &:hover {
        // filter: drop-shadow(0px 4px 14px rgba(0, 0, 0, 0.15));
        box-shadow: -6px -3px 70px -30px rgba(0, 0, 0, 0.54);
        -webkit-box-shadow: -6px -3px 70px -30px rgba(0, 0, 0, 0.54);
        -moz-box-shadow: -6px -3px 70px -30px rgba(0, 0, 0, 0.54);
        filter: drop-shadow(0px 4px 14px rgba(0, 0, 0, 0.15));
        transform: scale(1.01);
        z-index: 55;
        .project__block-btns {
          display: flex;
        }
      }
      &:not(:last-child) {
        border-right: 1px solid rgba(0, 0, 0, 0.1);
      }
    }
    &-boxes {
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 8px;
      display: flex;
      align-items: center;
    }
    &-subtitle {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.2px;
      color: #8a909d;
      margin-bottom: 8px;
    }
  }
  &__ssemp {
    margin-top: 30px;
  }
  &__health {
    margin-top: 30px;
    .project__block-subtitles {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &-box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: #ffffff;
      border: 1px solid rgba(30, 30, 30, 0.1);
      padding: 0 16px;
      height: 48px;
      margin-bottom: 2px;
      position: relative;
      .project__block-input {
        height: inherit;
        width: 20%;
        input {
          width: 100%;
        }
      }
      &.edit {
        .project__block-btns {
          display: flex;
          z-index: 55;
        }
      }
      &:hover {
        // filter: drop-shadow(0px 4px 14px rgba(0, 0, 0, 0.15));
        box-shadow: -6px -3px 70px -30px rgba(0, 0, 0, 0.54);
        -webkit-box-shadow: -6px -3px 70px -30px rgba(0, 0, 0, 0.54);
        -moz-box-shadow: -6px -3px 70px -30px rgba(0, 0, 0, 0.54);
        filter: drop-shadow(0px 4px 14px rgba(0, 0, 0, 0.15));
        transform: scale(1.01);
        z-index: 55;
        .project__block-btns {
          display: flex;
        }
      }
      &:not(:last-child) {
        border-right: 1px solid rgba(0, 0, 0, 0.1);
      }
    }
    &-subBox {
      width: 195px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-left: 1px solid rgba(30, 30, 30, 0.1);
      height: 100%;
    }
    &-table {
      margin-top: 30px;
      .project__health-box {
        padding: 12px 16px !important;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: #2a3650;
        background: #f8f8f8;
        border: 1px solid rgba(30, 30, 30, 0.1);
        border-radius: 4px;
        height: unset;
      }
    }
  }
  &__complaints {
    margin-top: 30px;
    &-box {
      padding: 24px 20px;
      width: 33.3333%;
      background: #ffffff;
      &:not(:last-child) {
        border-right: 1px solid rgba(0, 0, 0, 0.1);
      }
    }
  }
  &__security {
    margin-top: 30px;
  }
}
.tooltipContainer {
  position: relative;
  &:hover{
    .tooltipContainer__tooltip {
      display: block;
    }
  }
  &__tooltip {
    display: none;
    left: 6px;
    right: 6px;
    position: absolute;
    padding: 8px;
    background: #767676;
    border-radius: 8px;
    color: white;
    top: 100%;
    width: auto;
    text-align: center;
    &__item{
      display: flex;
      justify-content: space-between;
      gap: 10px;
      margin-bottom: 10px;
      &:last-child{
        margin-bottom: 0;
      }
      & h4, p{
        color: white;
        font-size: 14px;
        line-height: 100%;
      }
    }
    &:before {
      transform: rotate(180deg);
      content: "";
      position: absolute;
      bottom: 100%;
      left: 43%;
      border-width: 5px;
      border-style: solid;
      border-color: #767676 transparent transparent;
    }
  }
}
.project__wrapper-loader {
  position: absolute;
  max-width: 428px;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}
.project__wrapper-menu {
  position: absolute;
  display: none;
  top: 20px;
  right: 20px;
  height: 31px !important;
  width: 31px !important;
  z-index: 50;
  cursor: pointer;
}

.project__loader {
  border: 10px solid #f3f3f3;
  border-radius: 50%;
  border-top: 10px solid #0052cc;
  width: 60px;
  height: 60px;
  -webkit-animation: spin 1s linear infinite; /* Safari */
  animation: spin 1s linear infinite;
}
.custom-gallery {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 102;
  cursor: initial;
}
.custom-gallery__bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 101;
  cursor: initial;
}
.project__site-image .custom-gallery__bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 101;
  cursor: initial;
}
.custom-gallery__content {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 500;
}
.custom-gallery__img {
  width: 750px;
  height: max-content;
  object-fit: contain;
  z-index: 500;
  border-radius: 10px;
}
.custom-gallery__closeBtn {
  position: absolute;
  top: 15%;
  right: 17%;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid black;
}
.logout__modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 55;
  display: flex;
  align-items: center;
  justify-content: center;

  &-content {
    background: #ffffff;
    border-radius: 16px;
    max-width: 550px;
    width: 100%;
    padding: 24px 32px;

    h5 {
      font-weight: 500;
      font-size: 18px;
      line-height: 140%;
      letter-spacing: 0.2px;
      color: #2a3650;
    }
  }
  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #ccdcf5;
    padding-bottom: 20px;
  }
  &-close {
    cursor: pointer;
  }
  &-text {
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #2a3650;
    margin-top: 20px;
  }

  &-btns {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 32px;
  }
  &-cancel,
  &-sure {
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    letter-spacing: 0.2px;
    color: #767676;
    max-width: 229px;
    width: 100%;
    padding-top: 11.5px;
    padding-bottom: 11.5px;
    background: #eeeeee;
    border-radius: 8px;
    cursor: pointer;
  }

  &-sure {
    color: #fd6a6a;
    background: #fee6e6;
  }

  &-bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-color: rgba(0, 0, 0, 0.4);
  }
}
.row__disabled {
  background: #f8f8f8 !important  ;
}
.table__th-approved {
  width: unset !important;
}
.approved__table {
  margin-top: 20px;
  padding-top: 20px;
  border-width: 3px 0;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.1);
  .project-table__th:nth-child(1) {
    width: unset !important;
  }
  .project-table__th:nth-child(2) {
    width: unset !important;
  }
  .project-table__th:nth-child(3) {
    width: unset !important;
  }
  .project-table__row {
    background: #f8f8f8 !important;
  }
  .project-table__td {
    color: #767676;
    &:nth-child(1) {
      max-width: 137px;
      width: 100%;
    }
    &:nth-child(2) {
      max-width: 209px;
      width: 100%;
    }
    &:nth-child(3) {
      max-width: 156.5px;
      width: 100%;
    }
  }
}

p {
  white-space: pre-line;
}
textarea {
  white-space: pre-line;
}
//.header_description {
//  &:nth-child(1) {
//    max-width: 300px !important;
//    width: 100% !important;
//  }
//}
.data {
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__box {
    display: flex;
    flex-direction: column-reverse;
  }
  &__number {
    background: #f9f9fb;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    padding: 8px 12px;
    font-family: "Rubik";
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #8a909d;
    margin-top: 15px;
    max-width: 500px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    span {
      font-weight: 600;
      color: #2a3650;
      margin-left: 6px;
    }
  }
  &__title {
    font-weight: 600;
    font-size: 20px;
    line-height: 140%;
    color: #2a3650;
    position: relative;
    padding-left: 32px;
    cursor: pointer;
    &::after {
      position: absolute;
      content: "";
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      width: 20px;
      height: 2px;
      border-radius: 3px;
      background: #6697e0;
    }
    &::before {
      position: absolute;
      content: "";
      top: 49%;
      left: 9px;
      transform: translateY(-50%);
      width: 2px;
      height: 20px;
      border-radius: 3px;
      background: #6697e0;
      transition: all 0.3s;
      opacity: 0;
    }
  }
}
.project-photo {
  background: white;
  border-radius: 6px;
}
.input-planned {
  padding: 12px;
  background: #f9f9fb;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  width: 100%;
  transition: all 0.3s;
  &::placeholder {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.2px;
    color: #8a909d;
  }
}
.risk-1 {
  width: 50px !important;
}
.risk-3 {
  width: 100px !important;
}
.custom-tooltip {
  background: #ffffff;
  border-radius: 8px;
  display: flex;
  padding: 6px 8px;
  flex-direction: column;
}
.recharts-cartesian-axis-tick-value {
  tspan {
    overflow: hidden;
    text-overflow: ellipsis;
    width: 50px;
    max-width: 50px !important;
  }
}
.mobilization-title {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 140%;
  letter-spacing: 0.2px;
  color: #2a3650;
  display: flex;
  align-items: center !important;
  margin-right: 15px;
}
.mobilization-input {
  input {
    padding: 8px 10px;
    width: 400px;
    height: 36px;
    background: #f9f9fb;
    border: 1px solid rgba(30, 30, 30, 0.1);
    border-radius: 8px;
  }
  position: relative;
  &.edit {
    .project__block-btns {
      display: flex;
    }
  }
  &:hover {
    .project__block-btns {
      display: flex;
    }
  }
}
.communications {
  border-width: 3px 0 0 0 !important;
  padding-bottom: 100px;
  .project__block-table project-table {
    padding-bottom: unset;
  }
}
.contact_details {
  border-width: 0 0 3px 0 !important;
  .mobilization-title {
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: 0.2px;
    text-transform: uppercase;
    color: #2a3650;
  }
  &:last-child {
    border-width: unset;
  }
  padding-bottom: unset;
}
.react-datepicker__month--disabled,
.react-datepicker__quarter--disabled {
  color: #ccc !important;
  pointer-events: none !important;
}
.react-datepicker-popper {
  z-index: 9999 !important;
}
.react-datepicker__month-container {
  height: 270px !important;
}
.reporting-select {
  .react-datepicker__month-container {
    height: 330px !important;
  }
}
.expanded-input {
  position: relative !important;
}
.permits {
  .expanded-input {
    position: unset !important;
  }
}
.project__white-box {
  .project__disputes {
    .project__block {
      &.close {
        .plannedProject {
          display: none;
        }
      }
    }
  }
}
.expandedRow__bg {
  margin-top: 20px;
  padding: 16px;
  background: #f4f5f6;
  box-shadow: inset 4px 0 0 #8a909d;
}
.project__expanded-space {
  height: 44px;
  background: #fff;
  box-shadow: 4px 0 0 0 #8a909d inset;
  margin-left: 16px;

  &.s {
    background: #f4f5f6;
  }
}
.project__expanded-tiers {
  background: #f4f5f6;
  box-shadow: 4px 0 0 0 #8a909d inset;
  padding: 12px 16px 16px 16px;
  margin-left: 16px;
  //padding-top: 44px;

  &.s {
    background-color: #fff;
  }
}
.project__expanded-titles {
  color: #2a3650;
  font-size: 14px;
  font-family: Rubik;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: 0.2px;
  text-transform: uppercase;
  margin-bottom: 10px;

  &.d {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    .left {
    }

    .right {
      display: flex;
      align-items: center;
      span {
        display: flex;
        align-items: center;
        color: var(--primary-100, #0052cc) !important;
        /* Body/Regular-16px */
        font-size: 16px !important;
        line-height: 150% !important;
        text-transform: none;
        margin-right: 24px;
        cursor: pointer;
        font-weight: 300;
        img {
          margin-right: 8px;
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}

.mt-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.mr-15 {
  margin-right: 15px;
}
.payment_description {
  span {
    display: block;
    width: 500px;
  }
}
.project__larp-btns {
  position: absolute;
  right: 20px;
  &.end-btns {
    display: flex;
    justify-content: end;
  }
}

.grid-9 {
  display: grid !important;
  grid-template-columns: 2fr 2fr 2fr 2fr 2fr 1fr 2fr 2fr 2fr !important;
  // grid-column-gap: 10px;
}
.grid-8 {
  display: grid !important;
  grid-template-columns: 2fr 2fr 2fr 2fr 2fr 2fr 2fr 2fr!important;
  grid-column-gap: 10px;
}
.mb_32{
  margin-bottom: 32px;
}

@media (max-width: 1435px) {
  .grid-9,
  .grid-8 {
    // display: grid !important;
    // grid-template-columns: 2fr 2fr 2fr 2fr 2fr 1fr 2fr 2fr 2fr !important;
    .project-table__th,
    .project-table__td {
      width: 100% !important;
      // min-width: 100px !important;
      padding-right: 3px !important;
    }
    .project-table__th:nth-child(5) {
      width: 92%;
    }
  }
}
