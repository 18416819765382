.a-button {
    background: #0052CC;
    border-radius: 8px;
    width: 100%;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.3px;
    color: #FFFFFF;
    padding-top: 12px;
    padding-bottom: 12px;
    transition: all 0.3s;

    &:hover {
        transform: translateY(-3px);
    }
    &:active {
        transform: translateY(0px);
    }
}