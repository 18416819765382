.mf-auth {
  color: #2A3650;
  max-width: 540px;

  &__title{
    margin-top: 0;
    margin-bottom: 12px;
  }
  &__description{
    margin-top: 0;
    margin-bottom: 30px;
  }
  &__item{
    display: flex;
    align-items: center;
    padding: 20px;
    border-radius: 8px;
    gap: 16px;
    border: 1px solid #E5E5E5;
    margin-bottom: 20px;
    cursor: pointer;
    &:disabled{
      opacity: 0.6;
    }
    &:last-child{
      margin-bottom: 0;
    }
   & h5 {
     font-size: 16px;
     font-weight: 600;
     line-height: 24px;
     letter-spacing: 0.2px;
     text-align: left;
     margin-bottom: 4px;
   }
    & p{
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0;
      text-align: left;
      color: #8A909D;
    }
    &__img{
      width: 64px;
      height: 64px;
    }
  }
  &__inputFiledContainer{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-bottom: 40px;
    .a-input {
      width: 100%;
      margin-bottom: 10px;
    }
    button{
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0;
      text-align: left;
      color: #0066FF;
    }
  }
  &__submitBtn {
    width:100%;
    padding: 16px;
    border-radius: 8px;
    color: #FFFFFF;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.3px;
    text-align: center;
    margin-bottom: 20px;
    &_disable{
       background: #8A909D;
       cursor: inherit;
    }
    &_allow{
      background: #0052CC;
      cursor: pointer;
    }
  }
  &__info {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0;
    color: #8A909D;
    text-align: center;
    & a{
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0.2px;
      text-decoration: underline;
      margin-left: 4px
    }

  }
  &__checkBoxContainer{
    display: flex;
    gap: 10px;
    margin-bottom: 12px;
  }
}
.enterCode{
  display: flex;
  flex-direction: column;
  max-width: 470px;
  &__wrapper{
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 10px;
    align-items: center;
    padding-top: 10px;
  }
  &__greyBtn{
    color: #767676 !important;
    text-decoration: underline;
  }
  &_center{
    margin: 0 auto 14px;
  }
}
.rememberDevice{
  max-width: 470px;
  & h1, p {
    text-align: left;
    margin-bottom: 20px;
  }
  & button{
    margin-bottom: 0;
  }
}
.moreInfo{
  & .modal-header{
    border-bottom: none !important;
  }
  p {
    margin-bottom: 20px;
  }
  &__infoContainer{
    padding: 16px 0;
    gap: 12px;
    display: flex;
    flex-direction: column;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: #2A3650;
    border-top: 1px solid #EEEEEE;

    & h5{
      font-weight: 500;
      line-height: 24px;
    }
    & ul {
      padding: 0 20px;

      li {
        color: #2A3650;
        list-style-type: disc;
        margin-bottom: 12px;
        & a {
          color: #256ea6 !important;
        }
        & :last-child{
          margin-bottom: 0;
        }
      }
    }
  }
}