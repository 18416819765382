.emptyContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
  & img {
    height: 160px;
    margin-bottom: 20px;
  }
  p {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #2A3650;
    margin-bottom: 20px;
  }
}