.container {
  background: #FAFAFB;
  border: 1px solid #E5E5E5;
  border-radius: 10px;
}

.helpSection {
  display: flex;
  flex-direction: row !important;
  justify-content: space-between;
  align-items: stretch;
  gap: 40px;
  min-height: 340px;
  padding-bottom: 35px !important;
  &__aside {
    width: 35%;
    height: fit-content;
    padding: 32px 32px 0 32px;
    img{
      margin-right: 20px;
      margin-left: auto;
    }
  }
  & h2 {
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: 0;
    color: #2A3650;
    margin-bottom: 12px;
  }
  & p {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0;
    margin-bottom: 40px;
    color: #8A909D;
  }
  & a {
    color: #0052CC;
  }

  & main {
    overflow: scroll;
    max-height: calc(100vh - 70px);
    width: 62%;
    padding: 20px 24px 20px 24px;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
  }
  .mainHeader{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    h2{
      margin-bottom: 0 !important;
    }
    & button {
      display: flex;
      width: auto;
      padding: 8px 16px;
      border-radius: 8px;
      gap: 6px;
      & img {
        width: 24px;
        height: 24px;
        margin-right: 6px;
      }

    }
  }
  .trainingVideoList{
    display: flex;
    flex-direction: column;
    gap: 10px;
    &__item {
      display: flex;
      padding: 20px;
      border-radius: 10px;
      gap: 20px;
      background: #FFFFFF;
      border: 1px solid #E5E5E5;
      &__textContainer{
        max-width: 70%;
      }
      &__playIcon{
        width: 52px;
        height: 52px;
      }
      & h4{
        font-size: 18px;
        font-weight: 500;
        line-height: 25px;
        letter-spacing: 0.20000000298023224px;
        color: #2A3650;
      }
      & p{
        margin-bottom: 0;
      }
      &__buttonsContainer{
        display: flex;
        gap: 10px;
        margin-left: auto;
      }
    }
  }
  &__videoModal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 55;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    &__container{
      position: relative;
      padding: 16px;
      border-radius: 12px;
      background: #FFFFFF;
      &__frame{
        overflow: hidden;
        width: 600px;
        max-height: 600px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      & video {
        height: 500px;
        width: 100%;
        border-radius: 8px;
        object-fit: fill;
      }
    }
    &__closeIcon{
      width: 44px;
      height:44px;
      padding: 10px;
      border-radius: 200px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: white;
      position: absolute;
      top: -50px;
      right: -50px;
    }
    &_bg {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
      background-color: rgba(5, 4, 4, 0.4);
    }
  }
}