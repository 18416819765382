.larp-db {
  background: white;
  padding-left: 64px;
  &__df {
    display: flex;
  }
  &__aj {
    align-items: center;
    justify-content: space-between;
  }
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 32px;
    align-self: stretch;
    background-color: #fff;
  }

  &__nav {
    display: flex;
    align-items: center;

    a {
      color: var(--neutral-mid-100, #7A869A);
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
      display: block;
      margin-right: 3px;
    }
    p {
      color: var(--neutral-dark-800, #172B4D);
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
    }
  }

  &__update {
    display: flex;
    align-items: center;

    p {
      color: var(--text-subtle, #767676);
      font-family: Rubik;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
      letter-spacing: 0.2px;
      margin-right: 20px;
    }
    div {
      display: flex;
      align-items: center;
      display: flex;
      padding: 4px 8px;
      gap: 10px;
      border-radius: 8px;
      border: 1px solid rgba(0, 0, 0, 0.10);
      background: #FFF;
      cursor: pointer;
      span {
        color: var(--text-subtle, #767676);
        font-family: Rubik;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
        letter-spacing: 0.2px;
      }
    }
  }
  
  &__top {
    padding: 20px 32px 24px 32px;
    gap: 20px;
    border: 1px solid #E0E0E2;
    border-left: none;
    background: #FFF;
  }

  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__title {
    display: flex;
    align-items: center;
    h1 {
      color: #2A3650;
      font-family: Rubik;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 130%; /* 31.2px */
      margin-right: 12px;
    }

    p {
      color: #0052CC;
      font-family: Rubik;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%; /* 21px */
      display: flex;
      padding: 6px 20px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 5px;
      background: #E5EEFA;
    }
  }
  &__sidebar {
    display: flex;
    align-items: center;
    border-radius: 4px 4px 0px 0px;
    border: 1px solid rgba(0, 0, 0, 0.10);
    background: var(--Surface, #FFF);
    margin-top: 20px;
    a {
      display: block;
      color: var(--Primary, #8A909D);
      text-align: center;
      font-family: Rubik;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
      letter-spacing: 0.2px;
      padding: 14px 20px;
      border-right: 1px solid rgba(0, 0, 0, 0.10);
      background: var(--Surface, #FFF);
      &[aria-current='page'] {
        border-right: 1px solid rgba(0, 0, 0, 0.10);
        background: var(--Surface, #FFF);
        box-shadow: 0px -3px 0px 0px #074EE8 inset;
        color: var(--primary-100, #0052CC);
      }
    }
  }

  &__container {
    padding: 0px 32px;
  }

  &__tabs {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    margin-top: 24px;
    margin-bottom: 30px;
  }

  &__tab {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    border-radius: 8px;
    border: 1px solid #EAEAEA;
    background: #FFF;
    max-width: 373px;
    width: 100%;
    margin-right: 20px;
    &:last-child {
      margin-right: 0px;
    }

    &-left {
      max-width: 75%;
    }

    &-right {
      display: flex;
      align-items: center;
      justify-content: center;
      // padding: 12px;
      border-radius: 8px;
      background: linear-gradient(0deg, #F8F8F8 0%, #F8F8F8 100%), #FFF;
      margin-left: 12px;
      width: 48px;
      height: 48px;
      img {
        cursor: auto;
      }
    }

    &-value {
      display: flex;
      align-items: center;
      margin-top: 7px;
    }

    &-cash,
    &-percent {
      color: var(--dark-100, #2A3650);
      font-family: Rubik;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%; /* 25.2px */
      letter-spacing: 0.2px;
    }

    &-dot {
      margin-right: 8px;
      margin-left: 8px;
      width: 7px;
      height: 7px;
      border-radius: 50%;
      background-color: #A8A8A8;
    }

    &-title {
      color: var(--Primary, #8A909D);
      font-family: Rubik;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
    }
  }

  &__colors {
    display: flex;
    align-items: center;
  }

  &__color {
    display: flex;
    align-items: center;
    margin-right: 36px;
    div {
      width: 14px;
      height: 14px;
      border-radius: 4px;
      
    }
    p {
      color: var(--dark-100, #2A3650);
      font-family: Rubik;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 24px */
      margin-left: 8px;
    }

    &.paid {
      div {
        background: #3EAA59;
      }
    }
    &.unpaid {
      div {
        background: #E15E5E;
      }
    }

    &.escrowed {
      div {
        background: #D0AD2A;
      }
    }
  }
  &__buttondContainer {
    display: flex;
    gap: 24px

  }
  &__link_blue {
    display: flex;
    align-items: center;
    gap: 8px;
    color: #0052CC;
    text-align: center;
    font-family: Rubik;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.2px;
    border-radius: 8px;
    background: rgba(0, 82, 204, 0.10);
    padding: 4px 12px;
    cursor: pointer;
  }

  &__table {
    display: flex;
    align-items: stretch;
    border-radius: 12px;
    border: 1px solid rgba(0, 0, 0, 0.08);
    background: #FFF;
    margin-top: 22px;
  }

  &__row {
    display: flex;
    flex-direction: column;

    &:first-child {
      .larp-db__th {
        border-right: 1px solid #E0E0E2;
        border-bottom: 1px solid #E0E0E2;
      }
    }
  }

  &__table-scroll {
    display: flex;
    overflow-x: scroll;
  }

  &__th {
    color: #2A3650;
    font-family: Rubik;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 21px */
    letter-spacing: 0.2px;
    display: flex;
    width: 145px;
    height: 238px;
    padding: 0px 20px;
    align-items: center;
    flex-shrink: 0;

    &.end {
      height: 81px;
    }
  }

  &__td {
    display: flex;
    padding: 20px 24px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 145px;
    height: 238px;
    border-right: 1px solid #E0E0E2;
    border-bottom: 1px solid #E0E0E2;
    position: relative;
    &.end {
      height: 81px;
      color: var(--dark-100, #2A3650);
      text-align: center;
      font-family: Rubik;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 24px */
      cursor: pointer;

      .lengthy {
        position: absolute;
        padding: 4px 8px;
        background: #767676;
        border-radius: 8px;
        color: white;
        // transform: translateY(-100%);
        bottom: 101%;
        width: 100%;
        text-align: center;
        &:before {
          content: "";
          position: absolute;
          top: 100%;
          left: 43%;
          border-width: 5px;
          border-style: solid;
          border-color: #767676 transparent transparent;
        }
      }
      .not-lengthy {
        position: absolute;
        padding: 4px 8px;
        background: #767676;
        border-radius: 8px;
        color: white;
        // transform: translateY(-100%);
        bottom: 101%;
        width: 100%;
        left: 0;
        text-align: center;
        &:before {
          content: "";
          position: absolute;
          top: 100%;
          left: 43%;
          border-width: 5px;
          border-style: solid;
          border-color: #767676 transparent transparent;
        }
      }
    }

    &-radius {
      display: flex;
      flex-direction: column;
      border-radius: 6px;
      height: 197px;
      gap: 3px;
      position: relative;
      // overflow: hidden;
    }
  }

  &__chart {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 44px;
    color: #FFF;
    font-family: Rubik;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 18px */
    letter-spacing: 0.2px;
    cursor: pointer;

    &-percent {
      position: absolute;
      bottom: 0px;
      left: 0px;
      color: #2A3650;
      text-align: center;
      font-family: Rubik;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%; /* 18px */
      letter-spacing: 0.4px;
      transform: translateX(58px);
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &.paid {
      background: #3EAA59;
      position: relative;
      .larp-db__top-arrow {
        position: absolute;
        // height: 197px;
        bottom: -0.5px;
        right: 0px;
        // border: 1px solid red;
        transform: translateX(99.5%);
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;
        img:first-child {
          transform: translateX(6px) translateY(1.5px);
        }
        span {
          display: block;
          background-color: #E3E6EC;
          width: 2px;
          height: 20px;
          transform: translateX(-0.5px) translateY(1px);
        }
        img:last-child {
        }
      }

    }
    &.escrowed {
      background: #E7BB79;
      position: relative;
      .larp-db__top-arrow {
        position: absolute;
        // height: 100%;
        right: 0px;
        top: 0.5px;
        // border: 1px solid red;
        transform: translateX(99.5%);
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-end;
        img:first-child {
          transform: translateX(0px) translateY(-1px) rotate(180deg) scale(-1, 1);
        }
        span {
          display: block;
          background-color: #E3E6EC;
          width: 2px;
          height: 20px;
          transform: translateX(-0.5px) translateY(-2px);
        }
        img:last-child {
          transform: translateX(6px) translateY(-2.5px) rotate(180deg) scale(-1, 1);
        }
      }
    }
    &.unpaid {
      background: #E15E5E;
    }
    &.gray {
      background: #EBEBEB;
      color: #BEB9B9;
      text-align: center;
      font-family: Rubik;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%; /* 21px */
      letter-spacing: 0.2px;
      border-radius: 4px;
    }
  }

  &__section {
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 55;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    padding-top: 25px;
    padding-bottom: 25px;
    overflow-y: auto;

    &-content {
      z-index: 5;
      border-radius: 16px;
      background: #FFF;
      max-width: 899px;
      width: 100%;
      padding: 24px 32px 32px 32px;
      height: max-content;

    }

    &-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &-hr {
      opacity: 0.1;
      background: #000;
      width: 100%;
      height: 1px;
      margin-top: 20px;
      margin-bottom: 20px;
    }

    &-hero {
      border-radius: 8px;
      background: #F8F8F8;
      padding: 16px 20px;
      box-sizing: border-box;
      margin-bottom: 24px;
    }

    &-title {
      color: var(--dark-100, #2A3650);
      font-family: Rubik;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%; /* 25.2px */
      letter-spacing: 0.2px;
    }

    &-description {
      border-radius: 8px;
      background: #F8F8F8;
      padding: 16px 20px;
      box-sizing: border-box;
      margin-bottom: 24px;
      color: var(--dark-100, #2A3650);
      font-family: Rubik;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 24px */
      h4 {
        font-weight: bold;
      }
      span {

      }
    }

    &-subtitle {
      color: var(--dark-100, #2A3650);
      font-family: Rubik;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 24px */
    }

    &-date {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 8px;
      background: #F8F8F8;
      padding: 16px 20px;
      margin-bottom: 24px;

      div {
        &:first-child {
          color: var(--dark-100, #2A3650);
          font-family: Rubik;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%; /* 24px */
        }
        &:last-child {
          color: var(--dark-100, #2A3650);
          font-family: Rubik;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 150%; /* 24px */
          letter-spacing: 0.2px;
        }
      }
    }

    &-tabs {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &-heading {
      color: var(--dark-100, #2A3650);
      text-overflow: ellipsis;
      font-family: Rubik;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px; /* 137.5% */
      letter-spacing: 0.2px;
      text-transform: uppercase;
      margin-top: 24px;
      margin-bottom: 12px;
    }

    &-table {
      display: grid;
      margin: 0 auto;
      align-items: center;
      background: rgba(250, 250, 250, 0.9);
      border-radius: 0.5rem;

      &.section {
        margin-top: 20px;

        .larp-db__section-row {
          grid-template-columns: 1fr 1fr 1fr 1fr;
        }
      }
    }

    &-row {
      display: grid;
      align-items: center;
      grid-template-columns: 2fr 1fr 1fr 1fr;
      border-radius: 8px;
      border: 1px solid rgba(0, 0, 0, 0.10);
      background: #FFF;
      padding: 10px 16px;
      margin-top: 2px;
      &:first-child {
        padding-top: 16px;
        padding-bottom: 16px;
        margin-top: 0px;
      }
    }

    &-cell {
      display: flex;
      align-items: center;

      &.th {
        color: var(--Primary, #8A909D);
        font-family: Rubik;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; /* 19.2px */
        letter-spacing: 0.4px;
      }

      &.td {
        color: var(--dark-100, #2A3650);
        font-family: Rubik;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
        letter-spacing: 0.2px;
        height: max-content;
        
        &:first-child {
          overflow: hidden;
          color: var(--dark-100, #2A3650);
          text-overflow: ellipsis;
          white-space: nowrap;
          font-family: Rubik;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 150%; /* 21px */
          letter-spacing: 0.2px;
        }
      }

      &.status {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-family: Rubik;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%; /* 21px */
        letter-spacing: 0.2px;
        border-radius: 4px;
        padding: 8px;
        max-width: 106px;
        width: 100%;

        &.paid {
          color: #3EAA59;
          background: rgba(62, 170, 89, 0.10);
        }
        &.escrow {
          color: #E7BB79;
          background-color: #e7bb792d;
        }
        &.unpaid {
          color: #E15E5E;;
          background-color: #e15e5e28;;
        }
      }
    }

    &-b {
      position: fixed;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      z-index: -1;
      background-color: rgba(0,0,0,0.4);
    }
  }

  &__section-bar {
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.10);
    background: #FFF;
    padding: 21px 16px;
    padding-bottom: 63px;
    min-height: 300px;

    .bar-content {
      position: relative;
      width: 100%;
      height: calc(300px - 21px - 21px);
    }
    
    .bar-background {
      position: relative;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .bar-line {
      position: relative;
      color: var(--Primary, #8A909D);
      font-family: Inter;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      &:after {
        content: "";
        position: absolute;
        top: 50%;
        right: 0px;
        transform: translateY(50%);
        width: 95%;
        height: 1px;
        background: #E0E0E2;
      }
    }

    .bar-titles {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      place-items: center;
      margin-top: 20px;
    }
    .bar-title {
      color: var(--dark-100, #2A3650);
      text-align: center;
      font-family: Rubik;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%; /* 21px */
      letter-spacing: 0.2px;
    }
    .bar-bars {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      place-items: center;
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
    }

    .bar-bar {
      height: 94.5%;
      width: 47px;
      // border-radius: 6px;
      border-top-right-radius: 6px;
      border-top-left-radius: 6px;
      border-bottom-left-radius: 3px;
      border-bottom-right-radius: 3px;
      overflow: hidden;
    }

    .bar-bar__content {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #FFF;
      font-family: Rubik;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%; /* 21px */
      letter-spacing: 0.2px;
      margin-top: 2px;
      &.paid {
        background: #3EAA59;
      }
      &.escrow {
        background: #E7BB79;
      }
      &.unpaid {
        background: #E15E5E;
      }
      &.gray {
        background: #EBEBEB;
        color: #BEB9B9;
        text-align: center;
        font-family: Rubik;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%; /* 21px */
        letter-spacing: 0.2px;
      }
    }
  }
}