.erLoan {
  display: flex;
  gap: 24px;
  flex-direction: column;

  .table-container {
    min-width: auto;
  }
  .table-row {
    min-width: auto;
  }
  .custom-table tr {
    justify-content: flex-start;
  }
  .header__yearsRow th:nth-child(1), .header__yearsRow td:nth-child(1) {
    width: 145px !important;
  }
  .table-td {
    display: flex;
    flex-direction: column;
    gap: 4px;
    span {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.24px;
      text-align: left;
      color: #8A909D;
    }
    h6 {
      text-wrap: balance;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      text-align: left;
      color: #2A3650;
    }
  }
  &__divider {
    left: 48%;
    position: absolute;
    top: 0;
    bottom: 0;
    height: 100%;
    border-right: 1px solid #E5E5E5
  }

}

.erGrantKey {
  display: flex;
  flex-direction: column;
  h3 {
    color: #2A3650;
    font-size: 18px;
    font-weight: 500;
    line-height: 25.2px;
    letter-spacing: 0.24px;
    text-align: left;
    margin-bottom: 14px;
  }
  &__tableHeaderTitle{
    color: #8A909D;
    font-size: 12px;
    font-weight: 400;
    line-height: 19.2px;
    letter-spacing: 0.4px;
    text-align: left;

  }
  .editReport__table__row {
    &:first-child {
      &:hover{
        transform: scale(1);
        box-shadow:none;
        filter: none;
        z-index: 1;
      }
    }

    span {
      width: 50% !important;
    }
  }

}

.flexBox {
  display: flex;
  position: relative;
  width: 100%;
  &__divider {
    left: 48%;
    position: absolute;
    top: -14.5px;
    bottom: -14px;
    border-right: 1px solid #E5E5E5
  }
}