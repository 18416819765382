section {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.summary-section h2 {
  font-size: 18px;
  font-weight: 600;
  line-height: 25.2px;
  letter-spacing: 0.24px;
  color: #2A3650;

  margin-bottom: 10px;
  padding-bottom: 5px;
}

.summary-item {
  margin-bottom: 20px;
  background: #F8F8F8;
  padding: 16px 20px 20px 20px;
  gap: 12px;
  border-radius: 12px;
  & h3 {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0.20000000298023224px;
    text-align: left;
    color: #2A3650;
    text-transform: uppercase;
    margin-bottom: 10px;
  }
}

.summary-sub-item {
  position: relative;
  display: flex;
  gap: 8px;
  flex-direction: column;
  margin-bottom: 10px;
  background: #FFFFFF;
  border: 1px solid #E5E5E5;
  padding: 16px 20px 16px 20px;
  border-radius: 8px;
  &__fieldTitle {
    color: #8A909D;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.24px;
    text-align: left;
  }
  &__settingContainer{
    width: auto;
    display: none;
    position: absolute;
    bottom: -30px;
    right: 10px;
    padding-top: 5px;
    z-index: 99;
  }

  .html-text-styled {
    ul, ol {
      margin: 0;
      list-style: none;
      counter-reset: item;
      padding: 0 1.5em !important;
    }
    ol li {
      //display: grid;
      //grid-template-columns: max-content 1fr;
      gap: 3px;
      counter-increment: item;
    }
    ol li::before {
      content: counter(item) ".";
      text-align: left;
      margin-left: -1.5em;
      margin-right: 0.3em;
    }

  }
  .html-text-styled {
    ul li {
      //display: grid;
      //grid-template-columns: max-content 1fr;
      gap: 3px;
    }
    ul li::before {
      content: "•";
      text-align: left;
      margin-left: -1.5em;
      margin-right: 0.3em;
    }
  }

  &__updateBtn {
    padding: 4px 16px 4px 16px;
    gap: 10px;
    background: #f1f7ff;
    border-radius: 0 0 8px 8px;
    border:  1px solid #b5ccef;
    box-shadow: 0 4px 14px 0 #00000026;
    border-top: none;
    color: #3375d6;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.24px;

  }
}

.summary-sub-item_bold {
  display: block;
  color: #8A909D;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.24px;
}

.summary-sub-item div {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #2A3650;

}

.summary-sub-item  {
  &:hover {
    .summary-sub-item__settingContainer {
      display: block;
    }
  }

}
