.contact__modal {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100vh;
    z-index: 55;
    display: flex;
    justify-content: center;
    padding-top: 30px;
    &-content {
        overflow: scroll;
        background: #FFFFFF;
        border-radius: 16px;
        padding: 32px;
        max-width: 888px;
        width: 100%;
        height: max-content;
    }
    .users {
        height: 95%;
    }
    &-assign {
        margin-top: 15px;
    }
    &-projects {

        position: relative;
        background: #FFFFFF;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        overflow: scroll;
        padding: 0 20px 20px 20px;
        max-height: 368px;
    }
    &-checkbox {
        border-radius: 8px;
        padding: 4px 12px;
    }
    &-projectName {
        font-weight: 400;
        font-family: 'Rubik';
        font-size: 16px;
        line-height: 20px;
        color: #2A3650;
    }
    &-search {
        width: 100%;
        position: sticky;
        top: 0;
        z-index: 10;

        input{
            height: 50px;
            width: 100%;
            padding: 20px;
            border-bottom: 1px solid #e5e5e5;
        }

        .a-input {
            .input {
                .a-input-img {
                    position: absolute;
                    img {
                        position: relative;
                        top: 16px !important;
                        left: 3px;
                        z-index: 10000;
                    }
                }
                input {
                    width: 100%;
                    position: relative;
                }
            }
        }
        .a-input label {
            top: 30%;
            left: 15px;
        }
    }
    &-projectBox {
        background: #F9F9FB;
        border-radius: 8px;
        padding: 12px;
        display: flex;
        align-items: center;
        position: relative;
        .not-show {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            width: 286px;
            line-height: 20px;
            letter-spacing: 0.2px;
            color: #FFFFFF;
            background: #767676;
            border-radius: 8px;
            padding: 4px 8px;
            display: none;
            position: absolute;
            top: 40px;
            z-index: 12;
            &:before {
                content: '';
                background: #767676;
                border-radius: 2px;
                width: 48px;
                height: 8px;
                position: absolute;
                clip-path: polygon(50% 0, 100% 100%, 0 100%);
                left: 40%;
                top: -5px;
            }
        }
        &.disabled {
            background: #EEEEF2;
            border-radius: 8px;
            &:hover {
                .not-show {
                    display: block;
                }
            }
            label {
                color: #8A909D;
                margin-top: 3px;
                padding-left: 13px;
                &:before {
                    display: none;
                }
                &:after {
                    display: none;
                }
            }
            .a-checkbox {
                display: flex;
                align-content: center;
            }
        }
        &:not(:last-child) {
            margin-top: 12px;
        }
    }

    &-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    &-title {
        font-weight: 500;
        font-size: 18px;
        line-height: 140%;
        display: flex;
        align-items: center;
        letter-spacing: 0.2px;
        color: #2A3650;
    }
    &-close {
        cursor: pointer;
    }
    &-form {
        margin-top: 28px;
    }
    &-gray {
        background: #F9F9FB;
        border-radius: 16px;
        padding: 16px 20px;
    }
    &-row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 24px;
        &:first-child {
            margin-top: 0px;
        }
        .contact__modal-field {
            width: 48%;
            margin-top: 0px;
        }
    }
    &-field {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-top: 24px;
    }
    &-label {
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: #2A3650;
        margin-bottom: 6px;
    }
    &-input {
        background: #FFFFFF;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        color: #2A3650;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        padding: 12px;
        &::placeholder {
            color: #8A909D;
        }
        &.disabled {
            background: lightgrey;
        }
    }
    &-btns {
        display: flex;
        justify-content: flex-end;
        margin-top: 28px;
    }
    &-btn {
        font-weight: 600;
        font-size: 16px;
        line-height: 150%;
        text-align: center;
        letter-spacing: 0.3px;
        color: #FFFFFF;
        width: 200px;
        padding-top: 14px;
        padding-bottom: 14px;
        border-radius: 8px;
        cursor: pointer;
        transition: all 0.3s;
        background-color: #0052CC;
        &:hover {
            transform: translateY(-3px);
        }
        &:active {
            transform: translateY(0px);
        }
    }
    &-bg {
        z-index: -1;
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100vh;
        background: rgba(0, 0, 0, 0.3);
    }
}