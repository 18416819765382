.switch {
    // position: relative;
    padding-left: 5px;
    label {
        display: block;
        width: 40px;
        height: 22px;
        border: 1px solid #EDF1F7;
        border-radius: 100px;
        // position: relative;
        cursor: pointer;
        transition: all 0.3s;
        z-index: 1;
        div {
            // position: absolute;
            // content: "";
            margin-top: 1px;
            margin-left: 2px;
            width: 18px;
            height: 18px;
            background: #AAAAAA;
            border-radius: 50%;
            transition: all 0.3s;
        }
    }
    input:checked + label {
        background: #0052CC;
        border-color: #0052CC;
        div {
            background: #FFFFFF !important;
            transform: translateX(17px);
        }
    }
    input:disabled + label {
        // border: 1px solid #EDF1F7 !important;
        // background: #FFFFFF !important;
        // div {
        //     background: #E4E9F2 !important;
        // }
    }
    input {
        position: absolute;
        top: -10px;
        left: -10px;
        opacity: 0;
        z-index: -5;
    }
}

// .switch {
//     label {
//         display: block;
//         width: 40px;
//         height: 22px;
//         border: 1px solid #EDF1F7;
//         border-radius: 100px;
//         position: relative;
//     }
//     input {
//         position: absolute;
//         top: -10px;
//         left: -10px;
//         opacity: 0;
//         z-index: -5;
//     }
// }